import {Button, Stack, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import SearchIcon from 'src/images/whiteSearch.svg?react'
import FiltersIcon from 'src/images/filter.svg?react'
import EmptyEmailIcon from 'src/images/illustration_empty_mail.svg?react'

export interface NoResultsProps {
  resetFilter: () => void
}

const NoResults = observer(({resetFilter}: NoResultsProps) => {
  const navigate = useNavigate()

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <EmptyEmailIcon />
      <Typography color="GrayText" variant="body2" mt={1}>
        No results found
      </Typography>
      <Stack direction="row" spacing={1} mt={5} justifyContent="center">
        <Button
          variant="contained"
          onClick={() => {
            navigate('/search')
          }}
          startIcon={<SearchIcon />}
        >
          Search everything
        </Button>
        <Button
          variant="outlined"
          onClick={resetFilter}
          startIcon={<FiltersIcon />}
        >
          Remove Filters
        </Button>
      </Stack>
    </Stack>
  )
})

export default NoResults

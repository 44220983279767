import {makeAutoObservable} from 'mobx'

export default class BoardReport {
  fieldsFilters?: number[]

  periodFilters: number[]

  constructor(report: BoardReport) {
    this.fieldsFilters = report.fieldsFilters
    this.periodFilters = report.periodFilters
    makeAutoObservable(this)
  }
}

export class PeriodFilter {
  id: number

  value: number

  name: string

  functionName: string

  constructor(filter: PeriodFilter) {
    this.id = filter.id
    this.value = filter.value
    this.name = filter.name
    this.functionName = filter.functionName
    makeAutoObservable(this)
  }
}

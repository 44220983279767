import {
  Children,
  HTMLAttributes,
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef
} from 'react'
import {ListChildComponentProps, VariableSizeList} from 'react-window'

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const {data, index, style} = props

  return cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING
    }
  })
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

OuterElementType.displayName = 'OuterElementType'

function useResetCache(data: number) {
  const ref = useRef<VariableSizeList>(null)
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const VirtualizeListboxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>((props, ref) => {
  const {children, ...other} = props
  const itemData = Children.toArray(children)

  const itemCount = itemData.length
  const defaultItemSize = 30

  const getChildSize = () => {
    return defaultItemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * defaultItemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

VirtualizeListboxComponent.displayName = 'VirtualizeListboxComponent'

export default VirtualizeListboxComponent

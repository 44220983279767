import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'

const Logout = observer(function Logout() {
  const {loginStore} = useMainStore()

  loginStore.logout()

  return null
})

export default Logout

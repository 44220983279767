// @mui
import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
// config
import {useMainStore} from 'src/context/Main'
import {ViewType} from 'src/entities/View'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator
} from '@mui/lab'
import Label from 'src/components/label'

const MenuStatusTimeline = observer(() => {
  const {currentBoard} = useMainStore().boardStore
  const {currentItem} = useMainStore().itemStore
  const itemMenuTimeline = currentBoard?.itemMenuViews.find(
    v => v.viewType === ViewType.FileData
  )

  return (
    <Stack sx={{pl: 3, color: theme => theme.palette.grey[400]}}>
      <Timeline
        sx={{
          pl: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          }
        }}
      >
        {itemMenuTimeline?.columns?.map((c, i) => {
          const value = currentItem?.getValue(c.dbTableId, c.dbColumn, c.roleId)
          const data = JSON.parse(
            c.data?.find(d => d.columnValue === value?.toString())?.data ?? '{}'
          )

          return (
            <TimelineItem sx={{minHeight: 'auto', py: 0}} key={c.id}>
              <TimelineSeparator>
                <TimelineDot color={c.color} />
                {(!!data.text ||
                  i < (itemMenuTimeline?.columns?.length ?? 0) - 1) && (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="150px"
              >
                <Typography sx={{fontSize: '0.8rem'}}> {c.name}</Typography>
                {!!data.text && (
                  <Label variant="filled" color={data.color}>
                    {data.text}
                  </Label>
                )}
              </TimelineContent>
            </TimelineItem>
          )
        })}
      </Timeline>
    </Stack>
  )
})

export default MenuStatusTimeline

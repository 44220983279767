import {
  Checkbox,
  IconButton,
  styled,
  FormControlLabel,
  FormGroup
} from '@mui/material'
import {useMainStore} from 'src/context/Main'

import DataCheckIcon from 'src/images/data_check.svg?react'
import {useState} from 'react'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import Column from 'src/entities/Column'
import {observer} from 'mobx-react-lite'

export interface ChecklistPopupProps {
  column: Column
}
const StyledIconButton = styled(IconButton)(() => ({width: 56}))

const ChecklistPopup = observer(({column}: ChecklistPopupProps) => {
  const {currentBoard, updateItem} = useMainStore().boardStore
  const {currentItem} = useMainStore().itemStore
  const [displayChecklist, setDisplayChecklist] = useState(false)
  const [checked, setChecked] = useState(
    currentItem?.checklist?.split(',').map(x => +x) ?? [0]
  )

  const onToggle = (checklistId: number) => {
    const currentIndex = checked.indexOf(checklistId)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(checklistId)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const onSubmit = () => {
    try {
      updateItem(
        currentItem!.id,
        undefined,
        checked.toString(),
        currentItem!.checklist,
        column.dbColumn
      )
    } finally {
      setDisplayChecklist(false)
    }
  }

  return (
    <>
      <StyledIconButton onClick={() => setDisplayChecklist(true)}>
        <DataCheckIcon />
      </StyledIconButton>
      <CuiAlertDialog
        title={'Checklist'}
        isFullWidth={true}
        showMessage={false}
        showCancel={true}
        okButtonText="Done"
        open={displayChecklist}
        body={
          <FormGroup>
            {currentBoard?.checklist?.map(c => {
              return (
                <FormControlLabel
                  key={c.id}
                  label={c.name}
                  disabled={!currentItem?.isChecklistEnabled}
                  control={<Checkbox />}
                  checked={checked.indexOf(c.id) !== -1}
                  onChange={() => onToggle(c.id)}
                />
              )
            })}
          </FormGroup>
        }
        close={() => {
          setDisplayChecklist(false)
          setChecked(currentItem?.checklist?.split(',').map(x => +x) ?? [0])
        }}
        okClick={onSubmit}
        disableOkButton={!currentItem?.isChecklistEnabled}
      />
    </>
  )
})

export default ChecklistPopup

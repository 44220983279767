import {
  AlertColor,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import {useState} from 'react'
import {useMainStore} from 'src/context/Main'
import {SelectAccessorResponse} from './types'
import SendIcon from '@mui/icons-material/Send'
import CuiSnackbarAlert from 'src/components/custom/CuiSnackbarAlert'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'

const getAlertInfo = (res: SelectAccessorResponse | undefined) => {
  switch (res) {
    case SelectAccessorResponse.Success:
      return {
        severity: 'success' as AlertColor,
        message: 'Your note was saved successfully.'
      }
    case SelectAccessorResponse.LockedOrder:
      return {
        severity: 'warning' as AlertColor,
        message:
          'Cant save your note because the order is locked, it will be saved automatically later.'
      }
    case SelectAccessorResponse.Fail:
    case undefined:
      return {
        severity: 'error' as AlertColor,
        message: 'Ooops somehing went wrong, cant save your note'
      }
  }
}

const GeneralNotes = () => {
  const {
    itemStore: {currentItem},
    selectStore: {createNote}
  } = useMainStore()
  const [note, setNote] = useState('')
  const [noteLoading, setNoteLoading] = useState(false)
  const [alertInfo, setAlertInfo] = useState<{
    message: string
    severity: AlertColor
  }>()
  const [openConfirm, setOpenConfirm] = useState(false)

  const createNoteInSelect = async () => {
    setNoteLoading(true)
    setOpenConfirm(false)
    try {
      const res = await createNote(currentItem!, note)
      setAlertInfo(getAlertInfo(res))
    } finally {
      setNoteLoading(false)
      setOpenConfirm(false)
      setNote('')
    }
  }
  return (
    <>
      <Typography variant="subtitle1">General Notes</Typography>
      <TextField
        fullWidth
        value={note}
        placeholder="Add note to select"
        multiline
        maxRows={4}
        onChange={e => setNote(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={() => setOpenConfirm(true)}>
              {noteLoading ? (
                <CircularProgress />
              ) : (
                <IconButton disabled={note.length === 0}>
                  <SendIcon />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
      {openConfirm && (
        <CuiAlertDialog
          isFullWidth={true}
          showMessage={false}
          showCancel={true}
          okButtonText="Save"
          open={openConfirm}
          body={"You're going to save a note to select, Are you sure?"}
          okClick={createNoteInSelect}
          close={() => {
            setOpenConfirm(false)
          }}
        />
      )}
      {alertInfo && (
        <CuiSnackbarAlert
          severity={alertInfo.severity}
          message={alertInfo.message}
          onClose={() => {
            setAlertInfo(undefined)
          }}
        />
      )}
    </>
  )
}

export default GeneralNotes

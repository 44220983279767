import {Stack, Tab, TabProps, Tabs, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useState} from 'react'
import DocsTab from 'src/components/infoPopup/DocsTab'
import InfoTab from 'src/components/infoPopup/InfoTab'
import AssignTab from 'src/components/infoPopup/AssignTab'
import MailboxTab from 'src/components/infoPopup/MailBoxTab'
import QuestionTab from 'src/components/infoPopup/QuestionTab'
import Label, {LabelColor} from 'src/components/label'
import {useMainStore} from 'src/context/Main'
import Item from 'src/entities/Item'
import View, {ViewType} from 'src/entities/View'

interface TabViewProps extends TabProps {
  value: number
  text: string
  labelColor?: LabelColor
  count?: number
}

export const TabView = ({
  value,
  text,
  labelColor,
  count,
  ...props
}: TabViewProps) => {
  return (
    <Tab
      value={value}
      label={
        <Stack direction="row" spacing={1}>
          {count && <Label color={labelColor}>{count}</Label>}
          <Typography>{text}</Typography>
        </Stack>
      }
      {...props}
    />
  )
}

const getSelectedTab = (view: View | undefined, item: Item) => {
  if (!view) return

  switch (view.viewType) {
    case ViewType.Info:
      return <InfoTab item={item} />
    case ViewType.MailBox:
      return <MailboxTab />
    case ViewType.Documents:
      return <DocsTab item={item} />
    case ViewType.Question:
      return <QuestionTab viewId={view.id} item={item} />
    case ViewType.Assign:
      return <AssignTab item={item} />
  }
}

interface InfoPopupTabsProps {
  item: Item
}

const InfoPopupTabs = observer(({item}: InfoPopupTabsProps) => {
  const {currentBoard} = useMainStore().boardStore
  const [selectedTab, setSelectedTab] = useState(
    currentBoard?.infoTabUsingViews[0].id
  )

  return (
    <>
      <Tabs
        value={selectedTab || false}
        sx={{px: 3, backgroundColor: 'grey.200'}}
        onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
          setSelectedTab(newValue)
        }}
      >
        {currentBoard?.infoTabUsingViews.map(v => (
          <TabView
            value={v.id}
            key={v.id}
            text={v.name}
            labelColor={v.color as LabelColor}
          />
        ))}
      </Tabs>
      <Stack py={5} px={3} flexGrow={1} overflow="auto">
        {selectedTab &&
          getSelectedTab(
            currentBoard?.infoTabUsingViews.find(v => v.id === selectedTab),
            item
          )}
      </Stack>
    </>
  )
})
export default InfoPopupTabs

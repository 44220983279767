import {Autocomplete, Stack, TextField} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useState} from 'react'
import SelectFieldsList from 'src/components/select-app/favoriteFields/SelectFieldsList'
import {useMainStore} from 'src/context/Main'
import Column from 'src/entities/Column'
import {UserSettingKey} from 'src/entities/UserSettings'
import {UsingType, ViewType} from 'src/entities/View'

const FavoriteSelectField = () => {
  const mainStore = useMainStore()
  const {getUserSettingsByKey, updateUserSettings} = mainStore.userSettingsStore
  const {getViewByType} = mainStore.boardStore
  const [isSaving, setIsSaving] = useState(false)
  const view = getViewByType(ViewType.SelectFavorite, UsingType.Item)
  const favoriteSelectFieldSetting = getUserSettingsByKey(
    UserSettingKey.FavoriteSelectField,
    view?.id
  )
  const favoriteSelectFields = JSON.parse(
    favoriteSelectFieldSetting?.value ?? '[]'
  ) as Number[]

  const onSelectColumn = async (value: Column | null) => {
    if (value) {
      setIsSaving(!favoriteSelectFieldSetting?.id)
      await updateUserSettings(
        [...favoriteSelectFields, value.id],
        UserSettingKey.FavoriteSelectField,
        view?.id
      )
      setIsSaving(false)
    }
  }

  const onRemoveColumn = (columnId: number) => {
    updateUserSettings(
      favoriteSelectFields.filter(d => d !== columnId),
      UserSettingKey.FavoriteSelectField,
      view?.id
    )
  }

  const options = view?.columns?.filter(f =>
    favoriteSelectFields.every(d => d !== f.id)
  )
  const columns = favoriteSelectFields
    .map(d => view?.columns?.find(c => c.id === d) ?? null)
    .filter(c => c) as Column[]

  return (
    <Stack p={1} spacing={3}>
      <Autocomplete
        value={{} as Column}
        isOptionEqualToValue={() => false}
        options={options || []}
        getOptionLabel={option => option.name ?? ''}
        onChange={(_, newValue) => onSelectColumn(newValue)}
        disableClearable
        disabled={isSaving}
        noOptionsText="No Result"
        renderInput={props => (
          <TextField {...props} placeholder="Search Field" sx={{width: 300}} />
        )}
      />
      <SelectFieldsList columns={columns} onRemoveColumn={onRemoveColumn} />
    </Stack>
  )
}

export default observer(FavoriteSelectField)

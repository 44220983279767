import {
  DocumentItemChangeEvent,
  DocumentPreviewDetails,
  DocumentPreviewEvent
} from '@madisoncres/title-general-package'
import {observer} from 'mobx-react-lite'
import {Suspense, useEffect} from 'react'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import Item from 'src/entities/Item'
import loadable from '@loadable/component'

const DocumentView = loadable(() => import('cbDocs/DocumentShow'))

interface DocsTabProps {
  item: Item
}

const DocsTab = observer(({item}: DocsTabProps) => {
  useEffect(() => {
    DocumentItemChangeEvent.dispatch({
      fileNumber: item?.order?.fileNumber,
      itemId: item?.id || 0,
      isInfo: true
    })
    const callback = (e: CustomEvent<DocumentPreviewDetails>) => {
      const details = e.detail
      if (details.isPreviewOnly === true && details.blobUrl) {
        const file = encodeURIComponent(details.blobUrl)
        window.open(`/preview?file=${file}`, '_blank')
      }
    }
    DocumentPreviewEvent.subscribe(callback)

    return () => DocumentPreviewEvent.unsubscribe(callback)
  }, [item?.id, item?.order?.fileNumber])

  return (
    <>
      <CuiErrorBoundary>
        <Suspense fallback={<></>}>
          <DocumentView />
        </Suspense>
      </CuiErrorBoundary>
    </>
  )
})
export default DocsTab

import React, {useMemo, useState} from 'react'
import * as ReactDOM from 'react-dom'
import {
  Button,
  Chip,
  IconButton,
  Stack,
  ToggleButton,
  Switch,
  FormControlLabel,
  Typography
} from '@mui/material'
import {AccountBox as AccountBoxIcon} from '@mui/icons-material'
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import EyeOffIcon from 'src/images/eyeOff.svg?react'
import PersonIcon from 'src/images/person.svg?react'
import User from 'src/entities/User'
import {useMainStore} from 'src/context/Main'
import CuiAssigneeAutocomplete from 'src/components/custom/CuiAssigneeAutocomplete'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import {Control} from 'src/entities/PermissionData'
import {PermissionType} from '@madisoncres/title-general-package'

interface CustomToolbarProps {
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >
  onOpenColumnVisibilityPanel: () => void
  assigneeFilter?: User
  setAssigneeFilter: React.Dispatch<React.SetStateAction<User | undefined>>
}

const Toolbar = observer(
  ({
    setFilterButtonEl,
    onOpenColumnVisibilityPanel,
    assigneeFilter,
    setAssigneeFilter
  }: CustomToolbarProps) => {
    const mainStore = useMainStore()
    const {currentBoard, showAllMyAssignFiles, setShowAllMyAssignFiles} =
      mainStore.boardStore
    const {
      showAssignNewFileButton,
      currentView,
      collapseGroups,
      setAllCollapseGroup
    } = mainStore.viewStore
    const {getPermissionType} = mainStore.permissionStore

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()

    const showAllMyFilesPermissionType = useMemo(() => {
      if (!currentBoard?.views.length) return false

      const showAllMyFilesPermissionType = getPermissionType(
        currentView?.id,
        undefined,
        undefined,
        Control.ShowAllMyFiles
      )
      if (showAllMyFilesPermissionType === PermissionType.Invisible) {
        setShowAllMyAssignFiles(true)
      }
      return showAllMyFilesPermissionType
    }, [
      currentBoard?.views.length,
      currentView?.id,
      getPermissionType,
      setShowAllMyAssignFiles
    ])

    return (
      <React.Fragment>
        {ReactDOM.createPortal(
          <GridToolbarContainer
            sx={{
              backgroundColor: 'white',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Stack direction="row" alignItems="center">
              <GridToolbarQuickFilter />
              <GridToolbarFilterButton ref={setFilterButtonEl} />
              {assigneeFilter ? (
                <Chip
                  size="small"
                  avatar={
                    <CuiAvatar
                      name={assigneeFilter.fullName}
                      value={assigneeFilter.initials}
                      size={20}
                      isHere={assigneeFilter.isHere}
                    />
                  }
                  label={assigneeFilter.fullName}
                  variant="filled"
                  onDelete={() => {
                    setAssigneeFilter(undefined)
                  }}
                />
              ) : (
                <>
                  <Button
                    onClick={e => {
                      setAnchorEl(e.currentTarget)
                    }}
                    sx={{px: '5px', py: '4px'}}
                    startIcon={<PersonIcon />}
                  >
                    Person
                  </Button>
                  {anchorEl && (
                    <CuiAssigneeAutocomplete
                      anchorEl={anchorEl}
                      options={currentBoard?.members.map(m => m.user) || []}
                      value={assigneeFilter}
                      onChangeAssignee={(value: User | undefined) => {
                        setAnchorEl(undefined)
                        setAssigneeFilter(value)
                      }}
                      onClose={() => setAnchorEl(undefined)}
                    />
                  )}
                </>
              )}
              <Button
                onClick={onOpenColumnVisibilityPanel}
                sx={{px: '5px', py: '4px'}}
                startIcon={<EyeOffIcon />}
              >
                Hide
              </Button>
              {currentView?.isAssignedOnly &&
                showAllMyFilesPermissionType !== PermissionType.Invisible && (
                  <FormControlLabel
                    checked={showAllMyAssignFiles}
                    onChange={(_, c) => {
                      setShowAllMyAssignFiles(c)
                    }}
                    control={<Switch />}
                    label={
                      <Typography
                        sx={{fontWeight: 'bold', fontSize: 'smaller'}}
                      >
                        View all my files
                      </Typography>
                    }
                  />
                )}
            </Stack>
            <Stack direction="row">
              {showAssignNewFileButton && (
                <ToggleButton
                  value={!!currentView?.isInboundVisible}
                  selected={!!currentView?.isInboundVisible}
                  color={
                    !!currentView?.isInboundVisible ? 'primary' : undefined
                  }
                  onClick={() => currentView?.setInboundGroup()}
                  sx={{height: '35px', backgroundColor: 'transparent'}}
                >
                  <AccountBoxIcon />
                  &nbsp;Assign File
                </ToggleButton>
              )}
              <IconButton onClick={() => setAllCollapseGroup()}>
                {Object.values(collapseGroups).filter(v => v).length > 0 ? (
                  <UnfoldMoreIcon />
                ) : (
                  <UnfoldLessIcon />
                )}
              </IconButton>
            </Stack>
          </GridToolbarContainer>,
          document.getElementById('toolbar-box')!
        )}
      </React.Fragment>
    )
  }
)

export default Toolbar

export const validateMail = (email: string) => {
  if (!email) return 'Email is required'
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  if (!expression.test(email)) return 'Email must be a valid email address'
  return ''
}

export const validatePassword = (email: string) => {
  if (!email) return 'Password is required'
  return ''
}
export const validateRequired = (text: string | undefined, name: string) => {
  if (!text) return `${name} is requied`
  return ''
}

export const validateNumber = (text: string | undefined, name: string) => {
  if (!text) return `${name} is requied`
  const expression = /^\d+$/
  if (!expression.test(text)) return 'Only numbers are allowed'
  return ''
}

export const validateEnglishLetters = (
  text: string | undefined,
  name: string
) => {
  if (!text) return `${name} is requied`
  const expression = /^[A-Za-z ']+$/i
  if (!expression.test(text)) return 'Only English letters are allowed'
  return ''
}

export const hasValue = (value: any) => {
  return value !== null && value !== undefined
}

import {useEffect, useState} from 'react'
import {useMainStore} from 'src/context/Main'
import {CircularProgress, Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import MergeFilesDialog from 'src/components/itemView/MergeFileDialog'

const ItemViewMailboxHeader = observer(() => {
  const mainStore = useMainStore()
  const {currentItem} = mainStore.itemStore
  const {fixFileNumber, currentBoard} = mainStore.boardStore
  const [fn, setFn] = useState('')
  const [mergeItemId, setMergeItemId] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)
  const [isCurrentDeleted, setIsCurrentDeleted] = useState(false)

  useEffect(() => {
    if (currentItem) setFn(currentItem.getFileNumberText)
  }, [currentItem, currentItem?.order, setFn])

  const handleFixFnRes = (data: any) => {
    if (data.srcItem !== null) {
      setIsCurrentDeleted(data.isCurrentDeleted)
      setMergeItemId(data.srcItem.id)
    }
  }

  const onFixFn = async (event: React.FocusEvent<HTMLDivElement>) => {
    if (event.target.textContent && event.target.textContent !== fn) {
      setFn(event.target.textContent)
      try {
        setIsLoading(true)
        setFn(event.target.textContent)
        const data = await fixFileNumber(
          currentItem!.id,
          currentBoard?.id!,
          event.target.textContent
        )
        if (!data) setFn(currentItem!.getFileNumberText())
        else handleFixFnRes(data)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onFixFn(event as unknown as React.FocusEvent<HTMLDivElement>)
    }
  }

  return (
    <>
      <Stack direction="row">
        {fn && (
          <Stack direction="row">
            <Typography variant="h5">
              {currentItem?.order?.prefix && currentItem?.order?.prefix !== null
                ? currentItem?.order?.prefix + ' - '
                : ''}
            </Typography>
            <Typography
              onBlur={onFixFn}
              suppressContentEditableWarning={true}
              contentEditable={
                currentItem?.order !== null || currentItem?.name === null
              }
              variant="h5"
              onKeyDown={handleKeyDown}
            >
              {currentItem?.name && currentItem?.order === null
                ? currentItem.name
                : fn}
            </Typography>
          </Stack>
        )}
        {isLoading && (
          <CircularProgress sx={{m: 1}} size={20} color="inherit" />
        )}
      </Stack>
      {mergeItemId && (
        <MergeFilesDialog
          srcItemId={mergeItemId}
          isCurrentDeleted={isCurrentDeleted}
        />
      )}
    </>
  )
})

export default ItemViewMailboxHeader

import {
  UnauthorizeEvent,
  AuthorizeEvent
} from '@madisoncres/title-general-package'
import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {Logger, UserAgentApplication} from 'msal'
import config from 'src/config'
import {RoleId} from 'src/entities/PermissionData'
import User from 'src/entities/User'
import {MainStore} from 'src/store/MainStore'
import popupWindow from 'src/utils/popupWindow'
import {getBaseUrl} from 'src/utils/redirectWebsite'
import {useDebouncedCallback} from 'use-debounce'

const httpVersionNotSupported = 505
const baseUrl: string = getBaseUrl(window.location.href)

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export class LoginStore {
  readonly msal: UserAgentApplication

  isMsalAuth: boolean = false

  isAuth: boolean = false

  isFirstLoggedin?: boolean

  isBeginLoggedin: boolean = false

  roles?: RoleId[]

  isLoggingIn: boolean = false

  isFetchingUser: boolean = false

  user: User | null = null

  loginError: string = ''

  tokenError: string = ''

  shouldLogout: boolean = false

  loginMessage: string = ''

  isHereError?: boolean

  fetchQueue: (() => Promise<void>)[] = []

  get isAccessDenied() {
    return !this.isAuth && this.loginMessage !== ''
  }

  setIsHereError = (isError: boolean) => {
    this.isHereError = isError
  }

  setShouldLogout = (shouldLogout: boolean) => {
    this.shouldLogout = shouldLogout
  }

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    this.msal = new UserAgentApplication({
      auth: {
        redirectUri: config.AADRedirectUrl,
        postLogoutRedirectUri: window.location.href,
        ...config.AADConfig
      },
      cache: {cacheLocation: 'localStorage', storeAuthStateInCookie: true},
      system: {
        logger: new Logger((level: any, message: any) => {
          if (!config.isProduction) console.log('[msal]', level, message)
        })
      }
    })

    reaction(
      () => this.isMsalAuth,
      async isMsalAuth => {
        if (isMsalAuth) {
          if (!this.user) this.getCurrentUser()
          if (!this.roles) this.getCurrentUserRoles()
          AuthorizeEvent.dispatch({})
        }
      }
    )

    reaction(
      () => this.isMsalAuth,
      async isMsalAuth => {
        if (isMsalAuth)
          runInAction(
            () =>
              (this.isFirstLoggedin =
                this.isFirstLoggedin === undefined ? true : false)
          )
      }
    )

    UnauthorizeEvent.subscribe(event => this.onUnauthorize(event.detail.err))
  }

  setMsalAuthUser = () => {
    this.isMsalAuth = this.msal && !!this.msal.getAccount()
    this.isFetchingUser = this.msal && !!this.msal.getAccount()
  }

  init() {
    runInAction(async () => {
      this.loginMessage = ''
      if ((await this.getAccessToken(this.scope.graph)) !== '') {
        this.setMsalAuthUser()
      }
      this.isBeginLoggedin = true
    })
  }

  setCurrentUser = (value: User | null) => {
    this.user = value
  }

  setRoles = (roles: RoleId[]) => {
    this.roles = roles
  }

  get isManager() {
    return (
      this.roles?.some(r => r === RoleId.Manager || r === RoleId.Admin) ?? false
    )
  }

  async login() {
    this.clearCache()
    runInAction(() => {
      this.isLoggingIn = true
      this.loginError = ''
      this.loginMessage = ''
    })
    try {
      // Login via popup
      await this.msal
        .loginPopup({
          scopes: this.scope.graph,
          prompt: 'select_account',
          state: this.requestState
        })
        .then(() => {
          console.log('Login successful')
          runInAction(() => {
            this.isAuth = !!(this.user && this.roles)
            this.isFirstLoggedin = true
            this.isLoggingIn = false
            this.setMsalAuthUser()

            this.processFetchQueue()
            AuthorizeEvent.dispatch({})
          })
        })
        .catch(error => {
          console.error('Login error:', error)
          this.isLoggingIn = false
          throw error
        })
    } catch (err: any) {
      runInAction(() => {
        this.isLoggingIn = false
        this.isAuth = false
        this.isMsalAuth = false
        this.loginError = err.errorMessage
      })
    }
  }

  logout = () => {
    if (window.opener && window.opener !== window) return this.msal.logout()

    const logoutWin = popupWindow('/logout', '', 500, 600)
    if (logoutWin)
      logoutWin.onbeforeunload = () =>
        runInAction(() => {
          this.isAuth = false
          this.roles = []
          this.isMsalAuth = false
          this.user = null
          this.loginMessage = ''
        })
  }

  clearCache = () => {
    localStorage.removeItem(baseUrl)
  }

  enqueueFetchTask = (fetchTask: () => Promise<void>) => {
    this.fetchQueue.push(fetchTask)
  }

  async performAuthenticatedFetch(
    endpoint: RequestInfo,
    options: RequestInit = {},
    scopes: string[] = this.scope.api
  ): Promise<Response> {
    let accessToken

    try {
      accessToken = await this.getAccessToken(scopes)
    } catch (error) {
      console.error('Error acquiring access token:', error)
      throw new Error('Unable to acquire access token.')
    }

    if (!accessToken || accessToken === '') {
      throw new Error('ACCESS_TOKEN_EMPTY')
    }

    const headers = new Headers(options.headers)

    headers.set('Authorization', `Bearer ${accessToken}`)
    headers.set('ConnectionId', this.owner.signalrStore?.connectionId || '')

    // Add Version header only if it doesn't already exist
    if (!headers.has('Version')) {
      headers.set('Version', this.owner.sharedStore?.currentVersion || '-1')
    }

    const mergedOpts = {...options, headers}

    return fetch(endpoint, mergedOpts).then(response => {
      if (!response.ok) {
        if (response.status === httpVersionNotSupported) {
          this.owner.sharedStore.setIsIncorrectVersion(true)
        }
        return Promise.reject(response)
      }
      return response
    })
  }

  async fetchWithUser(
    endpoint: RequestInfo,
    options: RequestInit = {},
    scopes: string[] = this.scope.api
  ): Promise<Response> {
    try {
      return await this.performAuthenticatedFetch(endpoint, options, scopes)
    } catch (error: any) {
      if (error.message === 'ACCESS_TOKEN_EMPTY') {
        return new Promise<Response>((resolve, reject) => {
          const fetchTask = async () => {
            try {
              const response = await this.fetchWithUser(
                endpoint,
                options,
                scopes
              )
              resolve(response)
            } catch (fetchError) {
              reject(fetchError)
            }
          }

          this.enqueueFetchTask(fetchTask)
        })
      } else {
        throw error
      }
    }
  }

  getCurrentUser = () => {
    runInAction(() => (this.isFetchingUser = true))
    this.fetchWithUser(config.apiUrl + '/user/current')
      .then(response => response !== null && response.json())
      .then((data: User) => {
        runInAction(() => {
          this.user = new User(data)
          this.isAuth = !!(this.user && this.roles)
          this.isLoggingIn = false
          this.shouldLogout = false
          this.isFetchingUser = false
          this.loginMessage = ''
        })
      })
      .catch(() => {
        runInAction(() => {
          this.shouldLogout = true
          this.isAuth = false
          this.roles = []
          this.isMsalAuth = false
          this.isLoggingIn = false
          this.isFetchingUser = false
          this.loginMessage =
            'User does not exist in the system, please contact your administrator'
        })
      })
  }

  updateUser = async (user: User) => {
    if (this.user) user.id = this.user?.id
    try {
      const response = await this.fetchWithUser(`${config.apiUrl}/user`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          departmentId: user.department?.id,
          phone: user.phone,
          emailAddress: user.email
        })
      })
      const data = await response.json()
      if (!data) {
        throw new Error('failed to update  user')
      }
      runInAction(() => {
        this.setCurrentUser(user)
      })

      return true
    } catch (error) {
      console.log('error:', error)

      return false
    }
  }

  getCurrentUserRoles = () => {
    runInAction(() => (this.isFetchingUser = true))
    this.fetchWithUser(config.apiUrl + '/user/current/roles')
      .then(response => response !== null && response.json())
      .then((data: RoleId[]) => {
        this.setRoles(data)
      })
      .catch()
      .finally(() =>
        runInAction(() => (this.isAuth = !!(this.user && this.roles)))
      )
  }

  scope = {
    graph: ['user.read'],
    api: ['api://' + config.AADConfig.clientId + '/user_impersonation']
  }

  requestState = JSON.stringify({
    redirectUrl:
      typeof window !== 'undefined' ? window.location.origin + '/msal.html' : ''
  })

  isInteractionRequired = (error: Error): boolean => {
    if (!error.message || error.message.length <= 0) {
      return false
    }

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1
    )
  }

  async getAccessToken(
    scopes: string[] = this.scope.api,
    retryCount = 3,
    delayMs = 1000 // delay in milliseconds
  ): Promise<string> {
    const tokenRequest = {
      scopes: scopes,
      state: this.requestState
    }

    let attempts = 0

    while (attempts < retryCount) {
      attempts++
      try {
        // Attempt to acquire the token silently
        const response = await this.msal.acquireTokenSilent(tokenRequest)
        return response.accessToken
      } catch (error: any) {
        console.error(
          `Silent token acquisition failed on attempt ${attempts}:`,
          error
        )

        // Check if the error is retryable or needs a new login
        if (this.isRetryableError(error)) {
          console.log(
            `Retrying acquireTokenSilent... (${attempts}/${retryCount})`
          )
          await delay(delayMs) // Add delay before retrying
        } else if (
          error.name === 'InteractionRequiredAuthError' ||
          this.isInteractionRequired(error)
        ) {
          console.log('Interaction required to obtain new token interactively')
          try {
            // Attempt to acquire the token interactively
            const response = await this.msal.acquireTokenPopup(tokenRequest)
            console.log('Interactive token acquisition successful:', response)
            return response.accessToken
          } catch (popupError: any) {
            console.error('Failed to acquire token interactively:', popupError)

            if (popupError.errorCode === 'user_cancelled') {
              console.error('User cancelled the authentication')
            } else if (popupError.errorCode === 'popup_window_error') {
              console.error('Popup was blocked or closed prematurely')
            } else {
              console.error(
                'An unknown error occurred during interactive authentication'
              )
            }
            this.onTokenError(popupError)
            return ''
          }
        } else {
          console.error('Unretryable error encountered:', error)
          this.onTokenError(error)
          return ''
        }

        if (attempts >= retryCount) {
          console.error('Maximum retry attempts reached')
          this.onTokenError(error)
          return ''
        }
      }
    }

    return ''
  }

  // Helper method to determine if an error is retryable
  private isRetryableError(error: any): boolean {
    const retryableErrors = ['NetworkError']

    return retryableErrors.includes(error.name)
  }

  onUnauthorize = (err: any) => {
    console.log(`onUnauthorize ${err.errorCode} err: ${err}`)

    runInAction(() => {
      if (this.isFirstLoggedin !== undefined) {
        this.shouldLogout = true
      }
      this.isAuth = false
      this.isMsalAuth = false
      this.tokenError = err
    })
  }

  private onTokenError = (err: any) => {
    this.onUnauthorize(err)

    UnauthorizeEvent.dispatch({err: err})
  }

  private async processFetchQueue() {
    while (this.fetchQueue.length > 0) {
      const fetchTask = this.fetchQueue.shift()
      if (fetchTask) {
        await fetchTask()
      }
    }
  }

  async getAuthorizationHeader() {
    const accessToken = await this.getAccessToken(this.scope.api)
    return {Authorization: `Bearer ${accessToken}`}
  }

  updateStatusDB = useDebouncedCallback(async (isHere: boolean) => {
    try {
      await this.fetchWithUser(`${config.apiUrl}/user/isHere/${isHere}`, {
        method: 'patch',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    } catch (err: any) {
      this.user?.setIsHere(!isHere)
      this.owner.boardStore.updateIsHere(this.user?.id!, !isHere)
      this.setIsHereError(true)
    }
  }, 5000)

  updateStatus = () => {
    let isHere = this.user?.isHere
    this.user?.setIsHere(!isHere)
    this.owner.boardStore.updateIsHere(this.user?.id!, !isHere)
    this.updateStatusDB(!isHere)
  }
}

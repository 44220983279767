import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {Suspense} from 'react'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import loadable from '@loadable/component'

const DocumentView = loadable(() => import('cbDocs/DocumentShow'))

interface DocumentsProps {}

const Documents = observer(({}: DocumentsProps) => {
  return (
    <Stack>
      <CuiErrorBoundary>
        <Suspense fallback={<></>}>
          <DocumentView />
        </Suspense>
      </CuiErrorBoundary>
    </Stack>
  )
})

export default Documents

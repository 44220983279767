import {
  Stack /*, Typography*/,
  Backdrop,
  Card,
  CardContent,
  Typography,
  Button
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import ContainerIcon from 'src/images/container.svg?react'

const RefreshVersionPopup = observer(() => {
  const onRefreshClick = () => {
    window.location.reload()
  }
  return (
    <Backdrop
      open
      sx={{position: 'absolute', zIndex: theme => theme.zIndex.snackbar + 1}}
    >
      <Card>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Stack alignItems="center" spacing={3}>
            <ContainerIcon height="25vh" width="25vh" />
            <Typography component="h1" variant="h4">
              System Updates
            </Typography>

            <Typography>
              {' '}
              To ensure an optimal experience, please refresh.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              onClick={onRefreshClick}
            >
              {' '}
              Refresh{' '}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Backdrop>
  )
})
export default RefreshVersionPopup

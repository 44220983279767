import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'

export default class Document implements Base {
  id: number

  name: string

  url: string

  createdAt: Date

  itemId: number

  documentType: DocumentType

  constructor(document: Document) {
    this.id = document.id
    this.name = document.name
    this.url = document.url
    this.createdAt = document.createdAt
    this.itemId = document.itemId
    this.documentType = document.documentType
    makeAutoObservable(this)
  }
}
export enum DocumentType {
  Search = 1,
  Invoice
}

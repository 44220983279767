import {observer} from 'mobx-react-lite'
import {Badge, Divider, IconButton, Popover} from '@mui/material'
import NotificationsIcon from 'src/images/notifications.svg?react'
import {useState} from 'react'
import {useMainStore} from 'src/context/Main'
import NotificationsHeader from 'src/components/notification/NotificationsHeader'
import NotificationsBody from 'src/components/notification/NotificationsBody'

const NotificationsPopover = () => {
  const {unreadNotificationLength, setSearchQuery} =
    useMainStore().notificationStore
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement>()

  const onClosePopover = () => {
    setAnchorEl(undefined)
    setSearchQuery('')
  }

  const onIconClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <IconButton onClick={onIconClick}>
        <Badge badgeContent={unreadNotificationLength} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {anchorEl && (
        <Popover
          onClose={onClosePopover}
          open
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          slotProps={{
            paper: {
              sx: {
                p: 1,
                width: 500,
                minHeight: 540,
                overflow: 'hidden',
                ml: 0.75,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                  '& svg': {mr: 2, width: 20, height: 20, flexShrink: 0}
                }
              }
            }
          }}
        >
          <NotificationsHeader />
          <Divider sx={{borderStyle: 'dashed'}} />
          <NotificationsBody />
        </Popover>
      )}
    </>
  )
}

export default observer(NotificationsPopover)

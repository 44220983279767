import {observer} from 'mobx-react-lite'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from '@mui/material'
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses
} from '@mui/lab'
import {useMainStore} from 'src/context/Main'
import {generatePath, useNavigate} from 'react-router-dom'
import {PATH_WORKFLOW} from 'src/routes/paths'

const Departments = observer(() => {
  const {
    departmentStore: {
      currentMainDepartment,
      collapseDepartments,
      setCollapseDepartments
    },
    boardStore: {currentBoard, setCurrentBoard, isUserByBoard}
  } = useMainStore()
  const navigate = useNavigate()

  return (
    <div style={{width: '250px'}}>
      {currentMainDepartment?.departments?.map(d => (
        <Accordion
          key={d.id}
          defaultExpanded={
            currentBoard ? d.boards.some(b => b.id === currentBoard?.id) : true
          }
          expanded={!collapseDepartments[d.id]}
          onChange={(_: React.SyntheticEvent, isExpanded: boolean) => {
            setCollapseDepartments(d.id, !isExpanded)
          }}
        >
          <AccordionSummary
            sx={{
              backgroundColor: 'grey.800',
              color: 'white',
              flexDirection: 'row-reverse'
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              {d.name}
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{backgroundColor: 'grey.800', color: 'white', p: 0}}
          >
            <Box pb={4}>
              {d.boards.map(b => (
                <Stack
                  py={1}
                  pl={2}
                  key={b.id}
                  sx={{
                    backgroundColor:
                      currentBoard?.id === b.id
                        ? 'rgba(0, 171, 85, 0.07)'
                        : 'grey.800'
                  }}
                >
                  <Timeline
                    onClick={() => {
                      setCurrentBoard(b)
                      navigate(
                        generatePath(PATH_WORKFLOW.boards, {boardId: b.id})
                      )
                    }}
                    sx={{
                      p: 1,
                      height: '60px',
                      cursor: 'pointer',
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0
                      }
                    }}
                  >
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="primary" />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Stack>
                          <Typography>{b.name}</Typography>
                          {!isUserByBoard(b.id) && (
                            <Typography color="GrayText">
                              {b.itemsCountFormat}
                            </Typography>
                          )}
                        </Stack>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </Stack>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
})

export default Departments

import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material'

const HistorySkeleton = () => {
  return (
    <Stack>
      <Stack
        pt={3}
        px={2}
        pb={2}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Skeleton variant="text" width={200} height={10} />

        <Skeleton variant="text" width={200} height={50} />
      </Stack>

      <Table>
        <TableBody>
          {[...Array(7)].map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton variant="circular" height={20} width={20} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={50} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={50} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={50} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={50} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={50} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  )
}

export default HistorySkeleton

import {ReactNode, useEffect, useState} from 'react'
import Button from '@mui/material/Button'
import Dialog, {DialogProps} from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {ColorSchema} from 'src/theme/palette'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import {Checkbox, Stack, Typography} from '@mui/material'

export interface CuiAlertDialogProps extends DialogProps {
  open: boolean
  close: () => void
  title?: string
  body?: ReactNode
  okButtonText: string
  disableBackdropClick?: boolean
  okClick: () => void
  color?: ColorSchema
  showCancel?: boolean
  showMessage?: boolean
  onClickMessage?: () => void
  isFullWidth?: boolean
  disableOkButton?: boolean
}

export default function CuiAlertDialog({
  open,
  close,
  title,
  body,
  okButtonText,
  disableBackdropClick,
  okClick,
  color,
  showCancel,
  showMessage,
  onClickMessage,
  isFullWidth,
  disableOkButton,
  ...props
}: CuiAlertDialogProps) {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) setLoading(false)
  }, [open])

  const onClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' && disableBackdropClick)) {
      close()
    }
  }

  const onOkClick = () => {
    setLoading(true)
    okClick()
  }

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions sx={{justifyContent: 'space-between'}}>
        {showMessage && (
          <Stack direction="row" alignItems="center">
            <Checkbox onChange={onClickMessage} />
            <Typography>Dont show again</Typography>
          </Stack>
        )}
        {showCancel && (
          <Button
            color="inherit"
            variant="outlined"
            onClick={close}
            fullWidth
            disabled={loading}
          >
            Cancel
          </Button>
        )}

        <CuiProgressButton
          disabled={disableOkButton}
          isFullWidth={isFullWidth}
          variant="contained"
          onClick={onOkClick}
          loading={loading}
          color={color ? color : 'primary'}
        >
          {okButtonText}
        </CuiProgressButton>
      </DialogActions>
    </Dialog>
  )
}

import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import InfoView from '../view/itemView/InfoView'
import Item from 'src/entities/Item'
import {Stack} from '@mui/material'

interface InfoTabProps {
  item: Item
}
const InfoTab = observer(({item}: InfoTabProps) => {
  const {
    boardStore: {infoFileDataViews}
  } = useMainStore()

  return (
    <>
      <Stack spacing={6}>
        {infoFileDataViews?.map(v => (
          <InfoView key={v.id} view={v} currentItem={item} />
        ))}
      </Stack>
    </>
  )
})

export default InfoTab

import React, {useState} from 'react'
import {
  IconButton,
  Stack,
  Button,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Typography,
  Tooltip,
  ListSubheader,
  TextField,
  Box,
  Autocomplete
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import DownIcon from 'src/images/down.svg?react'

import StatusAssignee, {Operation} from 'src/entities/StatusAssignee'
import Close from '@mui/icons-material/Close'
import MenuItem from '@mui/material/MenuItem'
import {useMainStore} from 'src/context/Main'
import RoleSettings from 'src/entities/RoleSettings'
import Item from 'src/entities/Item'
import Condition from 'src/entities/Condition'
import {ListItemAssignee} from 'src/components/custom/CuiAssigneeAutocomplete'

export interface DialogProps {
  statusAssigneesList: StatusAssignee[]
  onClose: () => void
  parentRole?: number
  initialUserId: number | null
  initialRoleId: number | null
  item: Item
  condition: Condition
  boardCondition: Condition
}
const getStatusAssigneeKey = (userId: number, roleId: number) => {
  return `userId:${userId}_roleId:${roleId}`
}

const AssigneeDialog = observer(
  ({
    statusAssigneesList,
    onClose,
    parentRole,
    initialUserId,
    initialRoleId,
    item,
    condition,
    boardCondition
  }: DialogProps) => {
    const [userId, setUserId] = useState<number | null>(initialUserId || null)
    const [roleId, setRoleId] = useState<number | null>(initialRoleId || null)
    const [loading, setLoading] = useState(false)
    const [statusAssignees, setStatusAssignees] =
      useState<StatusAssignee[]>(statusAssigneesList)

    const [commentStatusAssignees, setCommentStatusAssignees] = useState<
      Record<string, string>
    >({})

    const {currentBoard, saveStatusAssignees, getDefaultRoleStatus} =
      useMainStore().boardStore
    const {currentView} = useMainStore().viewStore

    const defaultRole = currentBoard?.defaultRole
    const existDefault = !!statusAssignees
      .filter(i => i.operation !== Operation.Delete)
      .find(sa => sa.roleId === defaultRole?.id)

    const getDefaultStatus = (roleId: number) => {
      return getDefaultRoleStatus(item, roleId)
    }

    const addStatusAssignee = () => {
      if (!(userId && roleId)) return
      const newAssignee = new StatusAssignee({
        itemId: item.id,
        userId,
        user: currentBoard?.getUserMember(userId),
        roleId,
        operation: Operation.Add,
        statusId: getDefaultStatus(roleId)
      } as StatusAssignee)

      setUserId(null)
      setRoleId(initialRoleId)
      setStatusAssignees([...statusAssignees, newAssignee])
      setCommentStatusAssignees(prev => ({
        ...prev,
        [getStatusAssigneeKey(newAssignee.userId, newAssignee.roleId)]: ''
      }))
    }

    const updateCommentStatusAssignees = (key: string, comment: string) => {
      setCommentStatusAssignees(prev => ({
        ...prev,
        [key]: comment
      }))
    }

    const deleteCommentStatusAssignees = (key: string) => {
      setCommentStatusAssignees(prev => {
        const newState = {...prev}
        delete newState[key]
        return newState
      })
    }

    const isMatch = (item: StatusAssignee, statusAssignee: StatusAssignee) => {
      return (
        item.roleId === statusAssignee.roleId &&
        item.user?.id === statusAssignee.user?.id
      )
    }

    const deleteStatusAssignee = (statusAssignee: StatusAssignee) => {
      setStatusAssignees(prevData =>
        prevData.map(item =>
          isMatch(item, statusAssignee)
            ? new StatusAssignee({
                ...item,
                operation: Operation.Delete
              } as StatusAssignee)
            : item
        )
      )
      deleteCommentStatusAssignees(
        getStatusAssigneeKey(statusAssignee.userId, statusAssignee.roleId)
      )
    }

    const relevantStatusAssignee =
      statusAssignees.filter(s => s.operation !== Operation.Delete) || []

    const setStatusMemberRole = (
      statusAssignee: StatusAssignee,
      newRoleId: number
    ) => {
      setStatusAssignees(prevData =>
        prevData.map(item =>
          item.roleId === statusAssignee.roleId &&
          item.user?.id === statusAssignee.user?.id
            ? new StatusAssignee({
                ...item,
                roleId: newRoleId,
                statusId: getDefaultStatus(newRoleId),
                operation:
                  item.operation === Operation.Add
                    ? Operation.Add
                    : Operation.Edit
              } as StatusAssignee)
            : item
        )
      )
      setCommentStatusAssignees(prev => ({
        ...prev,
        [getStatusAssigneeKey(statusAssignee.userId, newRoleId)]: ''
      }))
    }

    const getRolesList = () => {
      if (currentView?.isAssignedOnly) {
        return currentBoard?.isEditableRoles.filter(r =>
          condition.rolesToApply?.includes(r.id)
        )
      }
      return currentBoard?.isEditableRoles
    }

    const getMemberRoles = (
      userId?: number | null,
      statusAssignee?: StatusAssignee | null
    ) => {
      const currentRole = currentBoard?.roles.find(
        r => r.id === statusAssignee?.roleId
      )

      if (initialRoleId)
        return currentBoard?.roles.filter(r => r.id === initialRoleId)

      if (!existDefault && defaultRole && !parentRole) {
        if (!statusAssignee || !currentRole) return [defaultRole]
        return [currentRole, defaultRole]
      }

      const assignedUserRoles = statusAssignees
        .filter(i => i.user?.id === userId && i.operation !== Operation.Delete)
        .map(i => i.roleId)

      const rolesWithoutDefault = getRolesList()?.filter(r => !r.isDefault)

      const relevantUserRoles = rolesWithoutDefault?.filter(
        r => !assignedUserRoles.includes(r.id)
      )

      if (statusAssignee && currentRole) relevantUserRoles?.unshift(currentRole)

      return relevantUserRoles || rolesWithoutDefault
    }

    async function saveStatusAssignee() {
      try {
        setLoading(true)

        await saveStatusAssignees(
          item.id,
          statusAssignees,
          commentStatusAssignees
        )
      } finally {
        setLoading(false)
        closeDialog()
      }
    }

    const closeDialog = () => {
      onClose()
      setUserId(null)
      setRoleId(initialRoleId)
    }

    const roleName = (role: RoleSettings) => {
      if (role.parentRoleName) {
        return (
          <>
            <div>
              <b>{role.parentRoleName}</b>
              <br />
              {role.name}
            </div>
          </>
        )
      }
      return <b>{role.name}</b>
    }

    const options = [
      {title: 'Available', options: currentBoard?.availableMembers || []},
      {title: 'Not Available', options: currentBoard?.notAvailableMembers || []}
    ].flatMap(group =>
      group.options.map(member => ({group: group.title, member}))
    )

    return (
      <Dialog
        fullWidth
        open
        onClose={(_, reason) => {
          if (reason && reason === 'backdropClick') return
          closeDialog()
        }}
        sx={{minHeight: '62%', maxHeight: '62%', m: 'auto'}}
      >
        <DialogTitle>
          Assignee
          <Stack direction="row" spacing={2} sx={{paddingTop: 2.5}}>
            <Autocomplete
              value={
                userId
                  ? options.find(option => option.member.userId === userId)
                  : null
              }
              onChange={(_, value) =>
                setUserId(value ? Number(value?.member.userId) : null)
              }
              fullWidth
              popupIcon={<DownIcon />}
              isOptionEqualToValue={(option, v) =>
                option.member.userId
                  ? option.member.userId === v.member.userId
                  : option === v
              }
              disabled={
                loading ||
                (!!initialRoleId && relevantStatusAssignee.length > 0)
              }
              options={options}
              groupBy={option => option.group}
              getOptionLabel={option => option.member.user.fullName}
              renderInput={params => {
                const [firstWord, lastWord] = (() => {
                  const words = (params.inputProps.value as string)
                    .trim()
                    .split(/\s+/)
                  return [words[0], words[words.length - 1]]
                })()
                const isValid = options.find(
                  o => o.member.user.fullName === params.inputProps.value
                )
                return (
                  <TextField
                    {...params}
                    label="Assign user"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: userId &&
                        isValid &&
                        params.inputProps.value && (
                          <CuiAvatar
                            name={`${firstWord} ${lastWord}`}
                            value={`${firstWord[0]}${lastWord[0]}`}
                            size={24}
                            isHere={
                              currentBoard?.getUserMemberByName(
                                params.inputProps.value as string
                              )?.isHere
                            }
                          />
                        )
                    }}
                  />
                )
              }}
              renderGroup={params => (
                <React.Fragment key={params.key}>
                  <ListSubheader sx={{zIndex: 4}}>{params.group}</ListSubheader>
                  {params.children}
                </React.Fragment>
              )}
              renderOption={(props, option: any) => (
                <ListItemAssignee
                  {...props}
                  key={option.member.userId}
                  user={option.member.user}
                  size={20}
                />
              )}
            />
            <FormControl fullWidth>
              <InputLabel>Choose a role</InputLabel>
              <Select
                onChange={e => setRoleId(Number(e.target.value))}
                disabled={loading || !userId || !!initialRoleId}
                label="Choose a role"
                value={roleId ?? ''}
                sx={{height: 55}}
              >
                {(boardCondition.boardIds?.includes(currentBoard?.id!)
                  ? currentBoard?.roles || []
                  : getMemberRoles(userId) || []
                ).map(r => (
                  <MenuItem key={r.id} value={r.id}>
                    {roleName(r)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              sx={{width: '120px'}}
              onClick={addStatusAssignee}
              disabled={loading || !(userId && roleId)}
            >
              Assign
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack mt={2}>
            <List>
              {statusAssignees
                .filter(i => i.operation !== Operation.Delete)
                .map(sa => {
                  const {user} = sa
                  const enableEdit =
                    (!currentView?.isAssignedOnly ||
                      initialRoleId ||
                      condition.rolesToApply?.includes(sa.roleId)) &&
                    currentBoard?.isEditableRole(sa.roleId)
                  return (
                    <Box key={`${sa.roleId} ${user?.id}`}>
                      <ListItem dense disablePadding>
                        <ListItemIcon>
                          <IconButton
                            disabled={!enableEdit || loading}
                            onClick={() => deleteStatusAssignee(sa)}
                          >
                            <Close />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemAvatar>
                          <CuiAvatar
                            name={user?.fullName}
                            value={user?.initials}
                            isHere={user?.isHere}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography variant="subtitle2">
                            {user?.fullName}
                          </Typography>
                        </ListItemText>
                        <Select
                          disabled={!enableEdit || loading}
                          variant="standard"
                          disableUnderline
                          sx={{
                            textAlign: 'right',
                            width: 180,
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': {border: 0}
                          }}
                          onChange={e =>
                            setStatusMemberRole(sa, Number(e.target.value))
                          }
                          value={sa.roleId ?? ''}
                        >
                          {getMemberRoles(Number(sa.user?.id), sa)?.map(r => (
                            <MenuItem key={r.id} value={r.id}>
                              {roleName(r)}
                            </MenuItem>
                          ))}
                        </Select>
                        <ListItemIcon />
                      </ListItem>
                      {commentStatusAssignees[
                        getStatusAssigneeKey(sa.userId, sa.roleId)
                      ] !== undefined && (
                        <Stack mt={1} mb={1}>
                          <TextField
                            id={'outlined-textarea' + sa.roleId + user?.id}
                            multiline
                            fullWidth
                            value={
                              commentStatusAssignees[
                                getStatusAssigneeKey(sa.userId, sa.roleId)
                              ]
                            }
                            disabled={!enableEdit || loading}
                            minRows={1}
                            maxRows={2}
                            placeholder="Write a comment…"
                            onChange={e => {
                              updateCommentStatusAssignees(
                                getStatusAssigneeKey(sa.userId, sa.roleId),
                                e.target.value
                              )
                            }}
                            onKeyDown={e => {
                              // Prevent focus shift to menu items when typing
                              e.stopPropagation()
                            }}
                          />
                        </Stack>
                      )}
                    </Box>
                  )
                })}
            </List>
          </Stack>
        </DialogContent>
        <DialogActions>
          <CuiProgressButton
            disabled={loading}
            variant="outlined"
            onClick={() => closeDialog()}
          >
            Cancel
          </CuiProgressButton>
          <Tooltip
            title={
              !existDefault && !initialRoleId
                ? `${defaultRole?.name} assignee is missing`
                : ''
            }
            arrow
          >
            <div>
              <CuiProgressButton
                variant="contained"
                onClick={() => saveStatusAssignee()}
                loading={loading}
                disabled={
                  initialRoleId && relevantStatusAssignee?.length
                    ? false
                    : (defaultRole && !existDefault) || loading
                }
              >
                save
              </CuiProgressButton>
            </div>
          </Tooltip>
        </DialogActions>
      </Dialog>
    )
  }
)
export default AssigneeDialog

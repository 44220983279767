import {ReactElement} from 'react'
import {LabelColor} from 'src/components/label'
import Label from 'src/components/label/Label'

export interface LabelProps {
  value: number
  text: string
  color: LabelColor
}

export interface LabelColProps {
  text: string
  color: LabelColor
  pointer?: boolean
  autoWidth?: boolean
  endIcon?: ReactElement
  disableTextTransform?: boolean
}

export default function LabelCol({
  color,
  text,
  pointer,
  endIcon,
  disableTextTransform
}: LabelColProps) {
  return (
    <Label
      color={color}
      sx={{
        cursor: pointer ? 'pointer' : undefined,
        textTransform: disableTextTransform ? 'none' : undefined
      }}
      width="100%"
      endIcon={endIcon}
    >
      {text}
    </Label>
  )
}

import {makeAutoObservable} from 'mobx'
import Role from 'src/entities/Role'

export default class RoleSettings implements Role {
  id: number

  name: string

  isDefault: boolean

  parentRoleName?: string

  parentRole?: Role

  defaultStatusId?: number

  isMultiRole: boolean

  isEditable: boolean

  selfAssign: boolean

  statusesWithComments?: number

  constructor(role: RoleSettings) {
    this.id = role.id
    this.name = role.name
    this.isDefault = role.isDefault
    this.parentRoleName = role.parentRoleName
    this.parentRole = role.parentRole
    this.defaultStatusId = role.defaultStatusId
    this.isMultiRole = role.isMultiRole
    this.isEditable = role.isEditable
    this.selfAssign = role.selfAssign
    this.statusesWithComments = role.statusesWithComments

    makeAutoObservable(this)
  }
}

import {makeAutoObservable} from 'mobx'
import User from 'src/entities/User'
import {ColumnType, Direction} from './Column'

export default class ItemData {
  itemId: number

  columnId: number

  data: string

  assigneeUsers?: User[]

  constructor(itemData: ItemData) {
    this.itemId = itemData.itemId
    this.columnId = itemData.columnId
    this.data = itemData.data
    this.assigneeUsers = itemData.assigneeUsers
    makeAutoObservable(this)
  }

  setData(newData: string): void {
    this.data = newData
  }
}

export class SelectItemData extends ItemData {
  isFavorite: boolean

  columnName: string

  columnType: ColumnType

  direction: Direction

  constructor(
    itemData: ItemData,
    isFavorite: boolean,
    columnName: string,
    columnType: ColumnType,
    direction: Direction
  ) {
    super(itemData)
    this.isFavorite = isFavorite
    this.columnName = columnName
    this.columnType = columnType
    this.direction = direction
    makeAutoObservable(this)
  }
}

import {makeAutoObservable} from 'mobx'
import Item from 'src/entities/Item'
import View, {ViewType} from 'src/entities/View'
import {
  DocumentItemChangeEvent,
  MailboxEntityChangeEvent,
  MailboxControl,
  PermissionType,
  OrderProperty
} from '@madisoncres/title-general-package'
import {MainStore} from 'src/store/MainStore'
import config from 'src/config'
import {Control, RoleId} from 'src/entities/PermissionData'

export class ItemStore {
  currentItem?: Item

  currentOverItem?: Item

  currentView?: View

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
  }

  get myRolesId() {
    return this.currentItem?.statusAssignees
      .filter(a => a.userId === this.owner.loginStore.user?.id)
      ?.map(r => r.roleId)
  }

  get assignedItems() {
    return this.owner.boardStore.items.filter(i =>
      i.isUserAssignee({userId: this.owner.loginStore.user?.id})
    )
  }

  getMailboxPermissions = (viewId?: number) => {
    const newPermission = {} as Record<MailboxControl, PermissionType>
    newPermission[MailboxControl.CROrRDMenuItem] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.CROrRDMenuItem
      )
    newPermission[MailboxControl.ExternalQuestion] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.ExternalQuestion
      )
    newPermission[MailboxControl.EmailTemplates] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.EmailTemplates
      )
    newPermission[MailboxControl.Hide] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        undefined,
        undefined,
        Control.Hide
      )
    return newPermission
  }

  setCurrentItem = (item?: Item, viewType?: ViewType) => {
    // this.owner.orderStore.validateOrderInHomePgae(item?.order?.fileNumber)
    if (this.currentItem?.id !== item?.id) {
      this.currentItem = item
      DocumentItemChangeEvent.dispatch({
        fileNumber: item?.order?.fileNumber,
        itemId: item?.id || 0
      })
      const permissions = this.getMailboxPermissions(
        this.owner.boardStore.currentBoard?.itemMenuViews.find(
          v => v.viewType === ViewType.MailBox
        )?.id
      )
      let orderProperty: OrderProperty | undefined
      if (
        permissions[MailboxControl.EmailTemplates] !==
          PermissionType.Invisible &&
        item?.order?.id
      )
        orderProperty =
          this.owner.boardStore.currentBoard?.properties?.[item.order.id]?.[0]
      MailboxEntityChangeEvent.dispatch({
        viewId: ViewType[ViewType.MailBox],
        entityId: item?.id || 0,
        entityTypeId: config.itemEntityTypeId,
        isOrderExistInHomePage: true,
        fileNumber: item?.order?.fileNumber ?? '',
        emailAccountId: this.owner.boardStore.currentBoard?.emailAccountId || 0,
        isDisplayPublicFolder:
          this.owner.boardStore.currentBoard?.isDisplayPublicFolder ?? false,
        departmentSignature: this.owner.departmentStore.departments.find(d =>
          d.boards.some(b => b.id === this.owner.boardStore.currentBoard?.id)
        )?.signature,
        fullFileNumber: item?.order?.fullFileNumber ?? '',
        permissions: permissions,
        orderProperty: orderProperty
      })
      if (item === undefined) {
        this.setCurrentView(undefined)
      }
    }
    if (this.currentItem) {
      const currentViewType = !this.currentItem?.order
        ? ViewType.MailBox
        : viewType
      this.setCurrentView(
        this.owner.boardStore.currentBoard?.itemMenuViews.find(
          v => v.viewType === currentViewType
        )
      )
    }
  }

  setCurrentView = (view?: View) => {
    this.currentView = view
  }

  setCurrentHoverItem = (item?: Item) => {
    this.currentOverItem = item
  }
}

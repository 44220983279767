import {
  FilePreviewDetails,
  FilePreviewEvent
} from '@madisoncres/title-general-package'
import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useEffect, useState} from 'react'

const MailBoxPreview = observer(() => {
  const [blobUrl, setBlobUrl] = useState<string>('')
  useEffect(() => {
    const callback = (e: CustomEvent<FilePreviewDetails>) => {
      const details = e.detail
      if (details.blobUrl) {
        setBlobUrl(details.blobUrl)
      }
    }
    FilePreviewEvent.subscribe(callback)

    return () => FilePreviewEvent.unsubscribe(callback)
  }, [])

  return (
    <>
      {blobUrl && (
        <Stack height={'100%'} width={'100%'}>
          <iframe
            src={blobUrl}
            width="100%"
            height="100%"
            style={{border: 'none'}}
            title="File Preview"
          />
        </Stack>
      )}
    </>
  )
})
export default MailBoxPreview

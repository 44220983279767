import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import ViewGroup from 'src/entities/ViewGroup'
import Item from 'src/entities/Item'
import View from 'src/entities/View'
import {MainStore} from 'src/store/MainStore'

const changeFailedMessage = 'The change you made was not saved in the system'

export class ViewStore {
  currentView?: View

  prevView?: View

  collapseGroups: Record<number, boolean> = {}

  errorMessage: string = ''

  currentItemView?: Item

  itemViews: Item[] = []

  prevItemViews: Item[] = []

  get views() {
    return this.owner.boardStore.boards.reduce((accumulator: View[], board) => {
      return accumulator.concat(board.views)
    }, [])
  }

  get groups() {
    return this.views.reduce((accumulator: ViewGroup[], view) => {
      return accumulator.concat(view.viewGroups)
    }, [])
  }

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )
  }

  setCurrentView = (view: View | undefined) => {
    this.collapseGroups = {}
    this.currentView = view
  }

  setCurrentItemView = (item: Item | undefined) => {
    this.currentItemView = item
  }

  setPrevView = (view: View | undefined) => {
    this.prevView = view
  }

  setItemViews = (items: Item[]) => {
    this.itemViews = items
  }

  setPrevItemViews = (items: Item[]) => {
    this.prevItemViews = items
  }

  setGroupName = async (group: ViewGroup, newName: string) => {
    const originalName = group.name
    group.setName(newName)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/ViewGroups/${group.id}/name`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newName)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update group name.')
      }
    } catch (error) {
      group.setName(originalName)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  setGroupColor = async (group: ViewGroup, newColor: string) => {
    const originalColor = group.color
    group.setColor(newColor)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/ViewGroups/${group.id}/color`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newColor)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update group color.')
      }
    } catch (error) {
      group.setColor(originalColor)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  setCollapseGroup = (id: number, isCollapse: boolean) => {
    this.collapseGroups[id] = isCollapse
  }

  setAllCollapseGroup = () => {
    this.collapseGroups =
      Object.values(this.collapseGroups).filter(v => v).length > 0
        ? {}
        : this.currentView?.viewGroups.reduce((acc: any, g) => {
            acc[g.id] = true
            return acc
          }, {})
  }

  onOpenItem = (item: Item) => {
    if (!this.itemViews.find(iv => iv.id === item.id)) {
      this.itemViews.push(item)
    }
    this.setCurrentItemView(item)
    this.setPrevView(this.currentView)
    this.setCurrentView(undefined)
  }

  onTabClick = (newValue: string) => {
    const value = newValue.split('-')
    if (value[1] === 'item') {
      if (this.currentItemView) {
        this.prevItemViews.push(this.currentItemView)
      } else {
        this.setPrevView(this.currentView)
      }
      this.setCurrentView(undefined)
      this.setCurrentItemView(
        this.itemViews.find(iv => iv.id === Number(value[0]))
      )
    } else {
      this.setCurrentItemView(undefined)
      this.setCurrentView(
        this.owner.boardStore.currentBoard?.views.find(
          v => v.id === Number(value[0])
        )
      )
      this.setPrevItemViews([])
      this.setPrevView(undefined)
    }
  }

  onCloseItem = (item: Item) => {
    let index = this.itemViews.findIndex(i => i.id === item.id)
    this.itemViews.splice(index, 1)
    this.setPrevItemViews(this.prevItemViews.filter(p => p.id !== item.id))
    if (item.id === this.currentItemView?.id) {
      if (this.prevItemViews.length) {
        this.setCurrentItemView(this.prevItemViews.shift())
      } else {
        this.setCurrentItemView(undefined)
        this.setCurrentView(this.prevView)
        this.setPrevView(undefined)
      }
    }
  }

  get showAssignNewFileButton() {
    return this?.currentView?.isAssignedOnly
  }

  listener = () => {
    this.owner.signalrStore.on(
      'UpdateGroupName',
      (groupId: number, newName: string) => {
        this.groups.find(g => g.id === groupId)?.setName(newName)
      }
    )

    this.owner.signalrStore.on(
      'UpdateGroupColor',
      (groupId: number, newColor: string) => {
        this.groups.find(g => g.id === groupId)?.setColor(newColor)
      }
    )
  }
}

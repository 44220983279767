import './App.css'
import Router from './routes/root'
// theme
import ThemeProvider from './theme'
import MainStoreProvider from 'src/context/Main'
import {LicenseInfo} from '@mui/x-data-grid-pro'
import config from 'src/config'
import {ReportersContextProvider} from 'src/context/Reporters'
import ListenersApp from 'src/components/listeners/ListenersApp'
import DocumentStoreProvider from 'cbDocs/MainStoreProvider'
import MailboxStoreProvider from 'cbMailbox/MainStoreProvider'

LicenseInfo.setLicenseKey(config.MUIDataGridLicenseKey)

function App() {
  return (
    <MainStoreProvider>
      <ReportersContextProvider>
        <DocumentStoreProvider>
          <MailboxStoreProvider>
            <ThemeProvider>
              <Router />
              <ListenersApp />
            </ThemeProvider>
          </MailboxStoreProvider>
        </DocumentStoreProvider>
      </ReportersContextProvider>
    </MainStoreProvider>
  )
}

export default App

import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useState} from 'react'
import Documents from 'src/components/itemView/Documents'
import ItemViewModalHeader from 'src/components/itemView/ItemViewModalHeader'
import Proofing from 'src/components/itemView/Proofing'
import Question from 'src/components/itemView/Question'
import View, {ViewType} from 'src/entities/View'
import Mailbox from 'src/components/itemView/MailBox'
import History from 'src/components/itemView/HistoryTab'

const getViewComponent = (
  view: View,
  isHistoryView: boolean,
  setCount: (count: number) => void,
  setUnreadCount: (unreadCount: number) => void
) => {
  switch (view.viewType) {
    case ViewType.Question:
      return (
        <Question
          viewId={view.id}
          onCountChange={setCount}
          onUnreadCountChange={setUnreadCount}
        />
      )
    case ViewType.Proofing:
      return <Proofing viewId={view.id} />
    case ViewType.Documents:
      return <Documents />
    case ViewType.MailBox:
      return <Mailbox />
    case ViewType.History:
      return <History isGetData={isHistoryView} />
  }
}

interface ViewContainerProps {
  view: View
  hidden: boolean
  onClose: () => void
}

const ViewContainer = observer(
  ({view, hidden, onClose}: ViewContainerProps) => {
    const [count, setCount] = useState<number>()
    const [unreadCount, setUnreadCount] = useState<number>()

    return (
      <div hidden={hidden}>
        <ItemViewModalHeader
          view={view}
          count={count}
          unreadCount={unreadCount}
          onClose={onClose}
        />
        <Stack p={2}>
          {getViewComponent(
            view,
            view.viewType === ViewType.History && !hidden,
            setCount,
            setUnreadCount
          )}
        </Stack>
      </div>
    )
  }
)
export default ViewContainer

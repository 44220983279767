import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import PermissionData, {
  ComponentType,
  Control,
  PermissionType,
  RoleId
} from 'src/entities/PermissionData'
import View from 'src/entities/View'
import {MainStore} from 'src/store/MainStore'

export class PermissionStore {
  permissionsData: PermissionData[] = []

  setPermissionData = (value: PermissionData[]) => {
    this.permissionsData = value
  }

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      isFirstLoggedin => {
        if (isFirstLoggedin) this.getPermissions()
      }
    )
  }

  getPermissions = async () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Permission`)
      .then(res => {
        return res.json()
      })
      .then((data: PermissionData[]) => {
        if (data) {
          this.setPermissionData(data.map(p => new PermissionData(p)))
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getViewPermissionType = (roleId?: number, viewId?: number) => {
    const permission = this.permissionsData.find(p => {
      return (
        p.componentTypeId === ComponentType.View &&
        p.viewId === viewId &&
        p.roleId ===
          (roleId ? roleId : this.owner.boardStore.currentMember?.roleId)
      )
    })
    if (!permission) {
      return PermissionType.Write
    }

    return permission.permissionTypeId
  }

  getPermissionType = (
    viewId?: number,
    roleId?: number,
    viewColumnId?: number,
    controlId?: Control
  ) => {
    const viewPermissionTypeId = this.getViewPermissionType(roleId, viewId)
    if (
      viewPermissionTypeId === PermissionType.Invisible ||
      (viewColumnId === undefined && controlId === undefined)
    ) {
      return viewPermissionTypeId
    }
    roleId =
      roleId ?? (this.owner.boardStore.currentMember?.roleId || RoleId.User)
    const permission = this.permissionsData.find(p => {
      if (controlId === undefined)
        return (
          p.viewId === viewId &&
          p.viewColumnId === viewColumnId &&
          p.roleId === roleId
        )

      return (
        p.controlId === controlId && p.viewId === viewId && p.roleId === roleId
      )
    })

    const permissionTypeId =
      permission?.permissionTypeId ?? PermissionType.Write
    return permissionTypeId < viewPermissionTypeId
      ? permissionTypeId
      : viewPermissionTypeId
  }

  getPermissionTypeByRoles = (
    viewId: number,
    rolesId?: number[],
    viewColumnId?: number,
    controlId?: Control
  ) => {
    rolesId = this.getRoles(rolesId)

    const maxPermission: PermissionType = rolesId.reduce((max, roleId) => {
      const permission = this.getPermissionType(
        viewId,
        roleId,
        viewColumnId,
        controlId
      ) as number
      return permission > max ? permission : max
    }, 1)
    return maxPermission
  }

  getPermissionTypesByView = (roleId?: number[], viewId?: number) => {
    roleId = this.getRoles(roleId)

    if (roleId.length === 1) {
      return this.permissionsData.filter(p => {
        return p.viewId === viewId && roleId![0] === p.roleId
      })
    }

    const newPermission = new Map<string, PermissionData>()
    this.permissionsData.forEach(p => {
      if (
        p.viewId === viewId &&
        roleId?.includes(p.roleId) &&
        !newPermission.has(`${p.viewColumnId}-${p.controlId}`)
      ) {
        const permissionTypeId = this.getPermissionTypeByRoles(
          viewId,
          roleId,
          p.viewColumnId,
          p.controlId
        )
        newPermission.set(`${p.viewColumnId}-${p.controlId}`, {
          ...p,
          permissionTypeId,
          roleId: 0
        })
      }
    })

    return Array.from(newPermission.values())
  }

  getColumnsPermissionType = (
    view?: View,
    rolesId?: number[]
  ): Map<number, PermissionType> => {
    const columnPermissions = new Map<number, PermissionType>()
    if (view) {
      rolesId = this.getRoles(rolesId)
      view.columns?.forEach(c => {
        columnPermissions.set(
          c.id,
          this.getPermissionTypeByRoles(view.id, rolesId, c.viewColumnId)
        )
      })
    }
    return columnPermissions
  }

  private getRoles = (roleId?: number[]) => {
    const currentMemberRoleId =
      this.owner.boardStore.currentMember?.role.id || RoleId.User
    if (!roleId?.length) {
      return [currentMemberRoleId]
    }
    if (
      currentMemberRoleId === RoleId.Manager &&
      !roleId.find(r => r === RoleId.Manager)
    ) {
      roleId?.push(currentMemberRoleId)
    }
    return roleId
  }
}

export function moveObjectToStart<T>(array: T[], element: T | undefined): T[] {
  const index = array.findIndex(item => item === element)
  if (index !== -1) {
    const [item] = array.splice(index, 1)
    array.unshift(item)
  }
  return array
}

export function getUniqueDuplicates<T>(list: T[]): T[] {
  const occurrences = new Map<T, number>()
  const duplicates = new Set<T>()

  list.forEach(item => {
    const count = occurrences.get(item) || 0
    occurrences.set(item, count + 1)

    if (count === 1) {
      duplicates.add(item) // If it appears more than once, it's a duplicate
    }
  })

  return Array.from(duplicates)
}

import {makeAutoObservable} from 'mobx'
import User from 'src/entities/User'
import {ViewType} from 'src/entities/View'
import {toLocalDate} from 'src/utils/date'

export default class Notification {
  id: number

  itemId?: number

  fileNumber?: string

  boardId?: number

  notificationTypeId: NotificationTypeId

  notificationOnId?: number

  userId?: number

  roleId?: number

  subject: string

  subTitle: string

  body: string

  isRead: boolean

  createdBy: User

  createdAt: Date

  flag: Flag | undefined

  constructor(n: Notification) {
    this.id = n.id
    this.itemId = n.itemId
    this.fileNumber = n.fileNumber
    this.boardId = n.boardId
    this.notificationTypeId = n.notificationTypeId
    this.notificationOnId = n.notificationOnId
    this.userId = n.userId
    this.roleId = n.roleId
    this.subject = n.subject
    this.subTitle = n.subTitle
    this.body = n.body
    this.isRead = n.isRead
    this.createdBy = new User(n.createdBy)
    this.createdAt = toLocalDate(n.createdAt)
    this.flag = n.flag
    makeAutoObservable(this)
  }

  setFileNumber = (fileNumber: string, subject: string) => {
    this.fileNumber = fileNumber
    this.subject = subject
  }

  setIsRead = (isRead: boolean) => {
    this.isRead = isRead
  }

  setFlag = (flag: Flag | undefined) => {
    this.flag = flag
  }

  get notificationTypeView() {
    return NotificationTypeViews[this.notificationTypeId] || undefined
  }
}

enum NotificationTypeId {
  Info = 1,
  NewEmail,
  AssignedTo,
  Question,
  Proofing
}

export enum Flag {
  Warning = 1,
  Success,
  Error
}

export const NotificationTypeColors: Record<NotificationTypeId, string> = {
  [NotificationTypeId.Info]: '#FFC10714',
  [NotificationTypeId.NewEmail]: '#1890FF14',
  [NotificationTypeId.AssignedTo]: '#00AB5514',
  [NotificationTypeId.Question]: '#FF484214',
  [NotificationTypeId.Proofing]: 'white'
}

export const NotificationTypeViews: Record<
  NotificationTypeId,
  string | undefined
> = {
  [NotificationTypeId.Info]: undefined,
  [NotificationTypeId.NewEmail]: ViewType[ViewType.MailBox],
  [NotificationTypeId.AssignedTo]: undefined,
  [NotificationTypeId.Question]: ViewType[ViewType.Question],
  [NotificationTypeId.Proofing]: ViewType[ViewType.Proofing]
}

import {Alert, Box, IconButton, Snackbar, Stack} from '@mui/material'
import {Outlet} from 'react-router-dom'
import NavMini from 'src/components/layout/NavMini'
import {useMainStore} from 'src/context/Main'
import {CloseIcon} from 'src/theme/overrides/CustomIcons'
import {observer} from 'mobx-react-lite'

const Main = observer(() => {
  const {errorMessage, setErrorMessage} = useMainStore()

  const resetError = () => {
    setErrorMessage('')
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Box flexGrow={1} sx={{display: 'inline-flex'}}>
        <NavMini />
        <Stack component="main" flexGrow={1} direction="row">
          {errorMessage && (
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              open
              autoHideDuration={6000}
              onClose={resetError}
            >
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={resetError}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMessage}
              </Alert>
            </Snackbar>
          )}
          <Outlet />
        </Stack>
      </Box>
    </Box>
  )
})

export default Main

import {Stack, useTheme} from '@mui/material'
import {observer} from 'mobx-react-lite'
import CuiResizable from 'src/components/custom/CuiResizable'
import {NAV} from 'src/config'
import {useMainStore} from 'src/context/Main'
import ViewContainer from 'src/components/itemView/ViewContainer'
import ItemViewModalHeader from 'src/components/itemView/ItemViewModalHeader'
import Mailbox from 'src/components/itemView/MailBox'
import {CSSProperties, Suspense, useCallback, useEffect} from 'react'
import {ViewType} from 'src/entities/View'
import {useSearchParams} from 'react-router-dom'

interface ItemViewModalProps {
  openMenu: boolean
}

const ItemViewModal = observer(({openMenu}: ItemViewModalProps) => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {currentView, setCurrentView} = mainStore.itemStore
  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()

  const onCloseModal = useCallback(() => {
    setCurrentView(undefined)
    setSearchParams(prev => {
      prev.delete('view')
      prev.delete('viewEntityId')
      return prev
    })
  }, [setCurrentView, setSearchParams])

  const mailboxView = currentBoard?.itemMenuViews.find(
    v => v.viewType === ViewType.MailBox
  )
  const isMailBoxCurrentView =
    currentView && currentView?.id === mailboxView?.id
  const view = searchParams.get('view')

  useEffect(() => {
    if (isMailBoxCurrentView && !view) {
      setSearchParams(prev => {
        prev.set('view', ViewType[ViewType.MailBox])
        return prev
      })
    }
  }, [isMailBoxCurrentView, view, setSearchParams])

  const floatingStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: (openMenu ? NAV.W_ITEM : NAV.W_ITEM_CLOSED) + NAV.W_DASHBOARD_MINI,
    bottom: 0,
    zIndex: theme.zIndex.modal
  }

  return (
    <>
      <Stack
        display={isMailBoxCurrentView ? 'flex' : 'none'}
        bgcolor="white"
        minHeight="100%"
        sx={{...floatingStyle, right: 0}}
      >
        <Suspense fallback={<></>}>
          <div hidden={!isMailBoxCurrentView} style={{height: '100vh'}}>
            {mailboxView && (
              <ItemViewModalHeader view={mailboxView} onClose={onCloseModal} />
            )}
            <Mailbox />
          </div>
        </Suspense>
      </Stack>
      <CuiResizable
        minWidth="20%"
        maxWidth="80%"
        maxHeight="100%"
        minHeight="100%"
        defaultSize={{height: '100%', width: '35%'}}
        enable={{right: true}}
        style={{
          height: '100%',
          display: !currentView || isMailBoxCurrentView ? 'none' : 'flex',
          ...floatingStyle
        }}
      >
        <Stack
          boxShadow={10}
          bgcolor="white"
          mr={1}
          height="100%"
          width="100%"
          overflow="auto"
        >
          <Suspense fallback={<></>}>
            {currentBoard?.itemMenuViews
              .filter(v => v.viewType !== ViewType.MailBox)
              .map(view => (
                <ViewContainer
                  key={view.id}
                  hidden={!(currentView && view.id === currentView.id)}
                  view={view}
                  onClose={onCloseModal}
                />
              ))}
          </Suspense>
        </Stack>
      </CuiResizable>
    </>
  )
})
export default ItemViewModal

import {Navigate, useLocation} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import CuiLoading from 'src/components/custom/CuiLoading'
import {PATH_AUTH} from 'src/routes/paths'
import LoginPopup from 'src/components/auth/LoginPopup'
import RefreshVersionPopup from 'src/components/refreshPopup/RefreshVersionPopup'
import {RefreshPopup} from '../refreshPopup/RefreshRequiredPopup'

const ProtectedRoute = observer(function ProtectedRoute({children}: any) {
  const {sharedStore, loginStore} = useMainStore()
  const location = useLocation()

  if (sharedStore.isIncorrectVersion)
    return (
      <>
        {children}
        <RefreshVersionPopup />{' '}
      </>
    )
  if (sharedStore.isNeedToRefresh)
    return (
      <>
        {children}
        <RefreshPopup />{' '}
      </>
    )
  if (loginStore.isAuth) return children

  if (
    !loginStore.isBeginLoggedin ||
    (loginStore.isMsalAuth && !loginStore.shouldLogout)
  ) {
    return <CuiLoading />
  }

  if (loginStore.isAccessDenied) return <Navigate to={PATH_AUTH.accessDenied} />

  if (loginStore.shouldLogout)
    return (
      <>
        {children}
        <LoginPopup />{' '}
      </>
    )

  return (
    <Navigate
      to={PATH_AUTH.login}
      state={{from: location.pathname + location.search}}
    />
  )
})

export default ProtectedRoute

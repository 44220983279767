import {Stack} from '@mui/material'
import {
  GridFilterModel,
  GridLogicOperator,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import CuiSelectWithOptions from 'src/components/custom/CuiSelectWithOptions'
import {useMainStore} from 'src/context/Main'

interface HistoryToolbarProps {
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>
}

const HistoryToolbar = observer(
  ({setFilterButtonEl, setFilterModel}: HistoryToolbarProps) => {
    const mainStore = useMainStore()
    const {currentHistoryBoardId, historyBoards, setCurrentHistoryBoardId} =
      mainStore.historyStore

    return (
      <GridToolbarContainer
        sx={{
          backgroundColor: 'white',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Stack direction="row" gap={1} justifyContent="flex-start">
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton ref={setFilterButtonEl} />
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <CuiSelectWithOptions
            options={historyBoards?.map(sd => ({
              label: sd.name,
              value: sd.id
            }))}
            size="small"
            displayEmpty
            value={currentHistoryBoardId || ''}
            sx={{}}
            onChange={e => {
              setCurrentHistoryBoardId(Number(e.target.value))

              setFilterModel({
                items: [],
                logicOperator: GridLogicOperator.And
              })
            }}
          />
        </Stack>
      </GridToolbarContainer>
    )
  }
)
export default HistoryToolbar

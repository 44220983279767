import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import {Checklist, ChecklistStatus} from 'src/entities/Checklist'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ErrorIcon from '@mui/icons-material/Error'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'

// ----------------------------------------------------------------------
interface ChecklistRowProps {
  checklist: Checklist
}

const getStatusIcon = (status: ChecklistStatus) => {
  switch (status) {
    case ChecklistStatus.Completed:
      return <CheckCircleIcon sx={{color: 'green'}} />
    case ChecklistStatus.Required:
      return <ErrorIcon color="error" />
    case ChecklistStatus.InProgress:
      return <RemoveCircleIcon color="disabled" />
    default:
      return null
  }
}

const ChecklistTableRow = observer(({checklist}: ChecklistRowProps) => {
  const {
    selectStore: {setCurrentChecklist, currentChecklist}
  } = useMainStore()

  return (
    <>
      <TableRow
        selected={currentChecklist?.description === checklist.description}
        onClick={() => setCurrentChecklist(checklist)}
        hover
      >
        <TableCell>{getStatusIcon(checklist.status)}</TableCell>
        <TableCell sx={{textOverflow: 'ellipsis'}}>
          {checklist.description}
        </TableCell>
        <TableCell>{checklist.assignedTo}</TableCell>
        <TableCell>{checklist.dueDate}</TableCell>
        <TableCell>{checklist.completedBy}</TableCell>
        <TableCell>{checklist.completedOn}</TableCell>
      </TableRow>
    </>
  )
})

export default ChecklistTableRow

import {makeAutoObservable} from 'mobx'

export default class PermissionData {
  componentTypeId: ComponentType

  viewId: number

  viewColumnId?: number

  controlId?: number

  roleId: number

  permissionTypeId: PermissionType

  constructor(permissionData: PermissionData) {
    this.componentTypeId = permissionData.componentTypeId
    this.viewId = permissionData.viewId
    this.viewColumnId = permissionData.viewColumnId
    this.controlId = permissionData.controlId
    this.roleId = permissionData.roleId
    this.permissionTypeId = permissionData.permissionTypeId

    makeAutoObservable(this)
  }
}

export enum ComponentType {
  View = 1,
  Column,
  SpecificControl
}

export enum RoleId {
  Admin = 1,
  Manager,
  User,
  Reader,
  Proofer,
  Question,
  Sub_Reader
}

export enum PermissionType {
  Invisible = 1,
  Readonly,
  Write
}

export enum Control {
  WfUser = 1,
  EditGroupName,
  EditGroupColor,
  SeeAll,
  Assign,
  Create,
  Reply,
  Status,
  Filter,
  AssignButton,
  ArchiveItem,
  Export,
  Checklist,
  GeneralNotes,
  CROrRDMenuItem,
  ShowAllMyFiles,
  ExternalQuestion,
  EmailTemplates,
  MoveToArchive,
  MoveToGroup,
  Hide
}

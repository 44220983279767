import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'

export default class Role implements Base {
  id: number

  name: string

  constructor(role: Role) {
    this.id = role.id
    this.name = role.name
    makeAutoObservable(this)
  }
}

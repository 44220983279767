import {
  Button,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  Stack,
  Typography
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import Notification, {
  Flag,
  NotificationTypeColors,
  NotificationTypeViews
} from 'src/entities/Notification'
import {fDateTime} from 'src/utils/formatTime'
import ScheduleIcon from '@mui/icons-material/Schedule'
import Trash from 'src/images/ic_trash.svg?react'
import Read from 'src/images/mark_email_read.svg?react'
import Unread from 'src/images/mark_email_unread.svg?react'
import FlagWarning from 'src/images/flagWarning.svg?react'
import FlagSuccess from 'src/images/flagSuccess.svg?react'
import FlagError from 'src/images/flagError.svg?react'
import FlagWarningFill from 'src/images/flagWarningFill.svg?react'
import FlagSuccessFill from 'src/images/flagSuccessFill.svg?react'
import FlagErrorFill from 'src/images/flagErrorFill.svg?react'
import DOMPurify from 'dompurify'
import React, {useEffect, useRef, useState} from 'react'
import {useMainStore} from 'src/context/Main'
import {generatePath, useNavigate} from 'react-router-dom'
import {PATH_WORKFLOW} from 'src/routes/paths'
import {adjust} from 'src/utils/color'
import DeleteNotificationDialog from 'src/components/notification/DeleteNotification'
import {getEnumOption} from 'src/utils/enum'

const flags: Record<Flag, [JSX.Element, JSX.Element]> = {
  [Flag.Warning]: [
    <FlagWarning key="warning" />,
    <FlagWarningFill key="warning-fill" />
  ],
  [Flag.Success]: [
    <FlagSuccess key="success" />,
    <FlagSuccessFill key="success-fill" />
  ],
  [Flag.Error]: [<FlagError key="error" />, <FlagErrorFill key="error-fill" />]
}

const prepareSearch = (notification: Notification) => {
  let search = ''
  if (NotificationTypeViews[notification.notificationTypeId]) {
    search += `view=${NotificationTypeViews[notification.notificationTypeId]}`
    if (notification.notificationOnId) {
      search += `&viewEntityId=${notification.notificationOnId}`
    }
  }
  return search
}

const color = '#1890FF'
const CircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <circle cx="4" cy="4" r="4" fill={color} />
  </svg>
)

const NotificationItem = ({notification}: {notification: Notification}) => {
  const mainStore = useMainStore()
  const {updateIsRead, updateFlag} = mainStore.notificationStore
  const {getBoardIdByItemId} = mainStore.boardStore
  const [showReadMore, setShowReadMore] = useState(false)
  const [isExpand, setIsExpanded] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const bodyRef = useRef<HTMLSpanElement | null>(null)
  const navigate = useNavigate()

  const onNotificationClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (!notification.isRead) updateIsRead(notification, true)
    if (notification.itemId) {
      const search = prepareSearch(notification)
      const path = generatePath(PATH_WORKFLOW.items, {
        boardId:
          notification.boardId ?? getBoardIdByItemId(notification.itemId),
        itemId: notification.itemId
      })
      if (e.ctrlKey)
        window.open(`${path}${search ? `?${search}` : ''}`, '_blank')
      else
        navigate({
          pathname: path,
          search
        })
    }
  }

  const onReadMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsExpanded(prev => !prev)
    if (!notification.isRead) updateIsRead(notification, true)
  }

  useEffect(() => {
    if (
      bodyRef.current &&
      (bodyRef.current.offsetHeight < bodyRef.current.scrollHeight ||
        notification.body.includes('<img'))
    ) {
      setShowReadMore(true)
    }
  }, [notification.body])

  let bodyText = notification.body.replaceAll(
    '<a ',
    '<a target="_blank" rel="noopener noreferrer" '
  )
  if (!isExpand) {
    bodyText = bodyText
      .replace(/<(br\/?|\/p)\s*>/gi, ' ')
      .replace(/<(?!\/?a\s*?)[^>]+>|<a[^>]+href\s*=\s*"#[^>]*"[^>]+>/gm, '')
  } else {
    bodyText = bodyText.replace(/^<p.*?>/, '').replace(/<\/p>$/, '')
  }

  return (
    <>
      <ListItemButton
        onClick={e => {
          onNotificationClick(e)
        }}
        disableTouchRipple={notification.isRead && !notification.itemId}
        sx={{
          py: 1.5,
          px: 2.5,
          mt: 1,
          alignItems: 'start',
          bgcolor: NotificationTypeColors[notification.notificationTypeId],
          '&:hover': {
            bgcolor: adjust(
              NotificationTypeColors[notification.notificationTypeId],
              10
            )
          }
        }}
      >
        <ListItemAvatar>
          <Stack direction="row" alignItems="center" spacing={1}>
            {!notification.isRead && <CircleIcon />}
            <CuiAvatar
              name={notification.createdBy.fullName}
              value={notification.createdBy.initials}
              isHere={notification.createdBy.isHere}
              size={30}
            />
          </Stack>
        </ListItemAvatar>
        <Stack spacing={1} width="100%">
          <Typography
            {...(!notification.isRead && {fontWeight: 700})}
            sx={{textWrap: 'wrap', whiteSpace: 'pre-line'}}
          >
            {notification.subject}
          </Typography>
          {notification.subTitle ? (
            <>
              <Divider />
              <Typography
                {...(!notification.isRead && {fontWeight: 700})}
                sx={{textWrap: 'wrap', whiteSpace: 'pre-line'}}
              >
                {notification.subTitle}
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Typography
            ref={bodyRef}
            sx={{
              color: 'text.secondary',
              overflowWrap: 'anywhere',
              ...(!isExpand && {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '-webkit-box-orient': 'vertical',
                display: '-webkit-box',
                '-webkit-line-clamp': '3'
              })
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(bodyText, {ADD_ATTR: ['target']})
            }}
          />
          {showReadMore && (
            <Button
              disableRipple
              onClick={onReadMoreClick}
              onMouseDown={e => e.stopPropagation()}
              sx={{
                color: color,
                width: 'fit-content',
                '&:hover': {backgroundColor: 'transparent'}
              }}
            >
              {!isExpand ? 'Read more' : 'Read less'}
            </Button>
          )}
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <ScheduleIcon width={16} color="disabled" />
              <Typography variant="caption" color="text.disabled">
                {fDateTime(notification.createdAt)}
              </Typography>
            </Stack>
            <Stack direction="row">
              {getEnumOption(Flag).map((f: Flag) => (
                <IconButton
                  key={f}
                  onClick={e => {
                    e.stopPropagation()
                    updateFlag(notification, f)
                  }}
                >
                  {notification.flag === f ? flags[f][1] : flags[f][0]}
                </IconButton>
              ))}
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  updateIsRead(notification, !notification.isRead)
                }}
              >
                {notification.isRead ? <Unread /> : <Read />}
              </IconButton>
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  setIsDelete(true)
                }}
              >
                <Trash />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </ListItemButton>
      {isDelete && (
        <DeleteNotificationDialog
          notification={notification}
          onClose={() => setIsDelete(false)}
        />
      )}
    </>
  )
}

export default observer(NotificationItem)

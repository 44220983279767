import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {Outlet, useParams} from 'react-router-dom'
import {useEffect} from 'react'

const BoardContainer = observer(() => {
  const {
    departmentStore: {mainDepartments, isLoading, setCurrentMainDepartment},
    boardStore: {setCurrentBoard}
  } = useMainStore()
  const {boardId} = useParams()

  useEffect(() => {
    if (isLoading) return
    const md = mainDepartments.find(md =>
      md.departments.some(d => d.boards.some(b => b.id === Number(boardId)))
    )
    setCurrentMainDepartment(md)
    setCurrentBoard(
      md?.departments.map(d => d.boards.find(b => b.id === Number(boardId)))[0]
    )
  }, [
    boardId,
    isLoading,
    mainDepartments,
    setCurrentBoard,
    setCurrentMainDepartment
  ])

  return <Outlet />
})

export default BoardContainer

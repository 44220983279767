import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import {BoardKey} from 'src/entities/Board'
import HistoryData, {HistoryTypeId} from 'src/entities/HistoryData'
import {MainStore} from 'src/store/MainStore'

export default class HistoryStore {
  histories: HistoryData[] = []

  isLoading: boolean = false

  currentHistoryBoardId?: number

  historyBoards: BoardKey[] = []

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )
  }

  setHistories = (data: HistoryData[]) => {
    this.histories.push(...data)
  }

  clearHistories = () => {
    this.histories.length = 0
  }

  addHistory = (data: HistoryData) => {
    this.histories.unshift(data)
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setCurrentHistoryBoardId = (boardId: number) => {
    this.currentHistoryBoardId = boardId
  }

  setHistoryData = async () => {
    const fileNumber = this.owner.itemStore.currentItem?.order?.fileNumber
    if (fileNumber) {
      await this.getHistoryData(fileNumber)
      if (this.owner.boardStore.currentBoard !== undefined)
        this.setCurrentHistoryBoardId(this.owner.boardStore.currentBoard?.id)
      this.historyBoards = this.owner.orderStore.getBoardsByFN(fileNumber)
    }
  }

  getHistoryData = async (fileNumber: string) => {
    if (!fileNumber) return []
    this.setIsLoading(true)
    this.owner.loginStore
      .fetchWithUser(`${config.historyUrl}/History/${fileNumber}`)
      .then(res => {
        return res.json()
      })
      .then((data: HistoryData[]) => {
        if (data) {
          this.clearHistories()
          data.forEach(d => {
            const h = new HistoryData(d)
            if (
              h.historyDataType.typeId === HistoryTypeId.Actions ||
              h.before !== h.after
            ) {
              this.histories.push(h)
            }
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  get historiesItems() {
    if (
      this.histories.length > 0 &&
      this.owner.itemStore.currentItem?.order?.fileNumber !== undefined &&
      this.currentHistoryBoardId !== undefined
    ) {
      const itemsId = this.owner.orderStore.getItemsIdByBoardId(
        this.owner.itemStore.currentItem?.order.fileNumber,
        this.currentHistoryBoardId
      )
      if (itemsId.length > 0) {
        const histories = this.histories.filter(h => itemsId.includes(h.itemId))

        return histories
      }
    }
    return []
  }

  listener = () => {
    this.owner.signalrStore.on('addHistory', (history: HistoryData) => {
      const data = new HistoryData(history)
      if (
        data.fileNumber ===
          this.owner.itemStore.currentItem?.order?.fileNumber &&
        this.histories.find(h => h.id === data.id) === undefined
      ) {
        this.addHistory(data)
      }
    })

    this.owner.signalrStore.on('FixFnHistory', (jsonData: HistoryData[]) => {
      let fixHistories: HistoryData[] = []
      if (
        jsonData?.length > 0 &&
        this.owner.itemStore.currentItem?.id === jsonData[0].itemId
      ) {
        jsonData.forEach(d => {
          const h = new HistoryData(d)

          fixHistories.push(h)
        })
        this.clearHistories()
        this.setHistories(fixHistories)
        if (this.owner.boardStore.currentBoard !== undefined)
          this.setCurrentHistoryBoardId(this.owner.boardStore.currentBoard?.id)
      }
    })
  }
}

import {Skeleton, Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'

const NotificationSkeleton = () => {
  return (
    <Stack direction="row" spacing={2} py={1.5} px={2.5} mt={1}>
      <Skeleton variant="circular" height={30} width={30} />

      <Stack spacing={1} flexGrow={1}>
        <Skeleton variant="text" />
        <Skeleton variant="rectangular" height={90} />
        <Skeleton variant="text" />
      </Stack>
    </Stack>
  )
}

export default observer(NotificationSkeleton)

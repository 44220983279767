import {Button, CircularProgress, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import {useMainStore} from 'src/context/Main'
import {observer} from 'mobx-react-lite'
import {Checklist} from 'src/entities/Checklist'
import ChecklistTableRow from 'src/components/select-app/checklist/ChecklistTableRow'
import ChecklistTableHead from 'src/components/select-app/checklist/ChecklistTableHead'
import ChecklistNotes from 'src/components/select-app/checklist/ChecklistNotes'

const ChecklistDataGrid = observer(() => {
  const {
    selectStore: {
      setChecklistData,
      setIsChecklistView,
      setCurrentChecklist,
      checklistData
    }
  } = useMainStore()

  const [page, setPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(5)

  useEffect(() => {
    setChecklistData()
    setCurrentChecklist(undefined)
  }, [setChecklistData, setCurrentChecklist])

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <>
      <Button
        variant="text"
        size="small"
        sx={{width: '10px', margin: '2px', padding: '2px'}}
        onClick={setIsChecklistView}
      >
        Back
      </Button>
      {checklistData ? (
        <>
          <Typography m={2} variant="h4">
            Checklist Tasks
          </Typography>
          <Stack sx={{useFlexGap: 'true', flexWrap: 'wrap'}}>
            <Card sx={{width: '100%'}}>
              <TableContainer>
                ,{' '}
                <Table stickyHeader sx={{overflow: 'auto'}}>
                  <ChecklistTableHead />
                  <TableBody>
                    {checklistData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: Checklist) => (
                        <ChecklistTableRow
                          key={row.description}
                          checklist={row}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                page={page}
                component="div"
                count={checklistData ? checklistData.length : 0}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Stack>
          <ChecklistNotes />
        </>
      ) : (
        <CircularProgress sx={{marginLeft: '50%', marginTop: '10%'}} />
      )}
    </>
  )
})
export default ChecklistDataGrid

import {Button, CircularProgress} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import MicrosoftIcon from 'src/images/MicrosoftLogo.svg?react'

interface LoginButtonProps {
  color?: 'white'
}

const LoginButton = observer(function LoginButton({color}: LoginButtonProps) {
  const {loginStore} = useMainStore()

  return (
    <Button
      type="submit"
      variant="outlined"
      onClick={() => !loginStore.isLoggingIn && loginStore.login()}
      startIcon={<MicrosoftIcon />}
      endIcon={
        loginStore.isLoggingIn && (
          <CircularProgress sx={{color: color}} size={24} />
        )
      }
      disableRipple={loginStore.isLoggingIn}
      sx={{
        py: 1,
        px: 4,
        mt: 3,
        color: color,
        borderColor: color,
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: '0.4px',
        cursor: loginStore.isLoggingIn ? 'default' : undefined
      }}
    >
      Continue with Microsoft
    </Button>
  )
})

export default LoginButton

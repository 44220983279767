import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import User from 'src/entities/User'
import {toLocalDate} from 'src/utils/date'
import {fDateUtcDate, fixUtcDateTimeInString} from 'src/utils/stringHelper'

export default class HistoryData implements Base {
  id: number

  itemId: number

  fileNumber: string

  user: User

  historyDataType: HistoryType

  field: string | undefined

  before: string | undefined

  after: string | undefined

  additionalInfo: string | undefined

  date: Date

  constructor(history: HistoryData) {
    this.id = history.id
    this.itemId = history.itemId
    this.fileNumber = history.fileNumber
    this.user = new User(history.user)
    this.historyDataType = new HistoryType(history.historyDataType)
    this.field = history.field
    this.before = fDateUtcDate(history.before)
    this.after = fDateUtcDate(history.after)
    this.additionalInfo = fixUtcDateTimeInString(history.additionalInfo)
    this.date = toLocalDate(history.date)

    makeAutoObservable(this)
  }

  setItemIdFileNumber = (fileNumber: string, itemId: number) => {
    this.fileNumber = fileNumber
    this.itemId = itemId
  }
}

export class HistoryType implements Base {
  id: number

  name: string

  typeId: HistoryTypeId

  constructor(historyType: HistoryType) {
    this.id = historyType.id
    this.name = historyType.name
    this.typeId = historyType.typeId

    makeAutoObservable(this)
  }
}

export enum HistoryTypeId {
  Fields = 1,
  Actions
}

import {Close} from '@mui/icons-material'
import {Alert, IconButton, Snackbar, Stack} from '@mui/material'
import WarningIcon from 'src/images/warningSnackbarIcon.svg?react'

export interface CuiSnackbarAlertStatusProps {
  onClose: () => void
}
export default function CuiSnackbarAlertStatus({
  onClose
}: CuiSnackbarAlertStatusProps) {
  return (
    <>
      <Snackbar
        open
        onClose={onClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        autoHideDuration={6000}
        sx={{
          width: '541px',
          '& .MuiPaper-root': {
            backgroundColor: 'white',
            boxShadow:
              '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' // Add box shadow
          }
        }}
      >
        <Alert
          icon={false}
          action={
            <IconButton onClick={onClose} aria-label="close" size="small">
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          <Stack
            direction="row"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <WarningIcon />
            <Stack
              mb={1}
              ml={2}
              sx={{
                fontSize: '15px',
                color: 'black',
                fontWeight: 'bold',
                textAlign: 'left',
                lineHeight: '2'
              }}
            >
              An error occured while updating your status. <br />
              Please try again later
            </Stack>
          </Stack>
        </Alert>
      </Snackbar>
    </>
  )
}

import {IconButton, Stack, Typography, Box} from '@mui/material'
import {observer} from 'mobx-react-lite'
import React, {useState} from 'react'
import Item from 'src/entities/Item'
import CommentIcon from '@mui/icons-material/SmsOutlined'
import CloseIcon from '@mui/icons-material/Close'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import Popover from '@mui/material/Popover'
import {useMainStore} from 'src/context/Main'
import {fDateTimestamp} from 'src/utils/formatTime'
import {alpha} from '@mui/material/styles'
import Label from 'src/components/label/Label'
import CuiOverflowTypography from 'src/components/custom/CuiOverflowTypography'

interface CommaCommentColProps {
  item: Item
}

const CommaCommentCol = observer(({item}: CommaCommentColProps) => {
  const {boards, getStatusesWithComment} = useMainStore().boardStore
  const board = boards.find(b => b.id === item.boardId)
  const text = getStatusesWithComment(item, board)?.join(', ')

  return <CuiOverflowTypography>{text}</CuiOverflowTypography>
})
export {CommaCommentCol}

export interface CommentPopupProps {
  item: Item
}

interface CommentProps {
  text: string
}

interface PopoverProps {
  onClose: () => void
  anchorEl: HTMLElement | null
  item: Item
}

const Comment = ({text = ''}: CommentProps) => {
  return (
    <Box
      sx={{
        backgroundColor: theme => alpha(theme.palette.grey[500], 0.16),
        padding: 1,
        borderRadius: 0.75,
        display: 'inline-block'
      }}
    >
      <Typography
        sx={{
          overflowWrap: 'anywhere',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '-webkit-box-orient': 'vertical',
          display: '-webkit-box'
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const PopoverComponent = observer(({onClose, anchorEl, item}: PopoverProps) => {
  const {currentBoard, getStatusesWithComment} = useMainStore().boardStore

  const statusesString = getStatusesWithComment(item, currentBoard)

  const user = currentBoard?.getUserMember(item.commentUserId)

  console.log(user)

  return (
    <Popover
      open={Boolean(anchorEl)}
      onClose={() => onClose()}
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      transformOrigin={{vertical: 'bottom', horizontal: 'right'}}
      slotProps={{
        paper: {
          sx: {
            p: 1,
            width: 500,
            minHeight: 200,
            overflow: 'hidden',
            ml: 0.75,
            '& .MuiMenuItem-root': {
              px: 1,
              borderRadius: 0.75
            }
          }
        }
      }}
    >
      <Stack direction="row" py={2} px={2} alignItems="start" spacing={2}>
        <CuiAvatar
          name={user?.fullName}
          value={user?.initials}
          size={30}
          isHere={user?.isHere}
        />

        <Stack spacing={2} width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="baseline">
              <Typography sx={{fontSize: 18, fontWeight: '700'}}>
                {`${user?.firstName} ${user?.lastName}`}
              </Typography>
              <Typography variant="caption" color="text.disabled" fontSize={14}>
                {fDateTimestamp(item.commentCreatedOn)}
              </Typography>
            </Stack>
            <IconButton style={{padding: 0}} onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack sx={{display: 'block', flexWrap: 'wrap'}}>
            {statusesString
              ?.filter(s => s)
              ?.map((status, i) => (
                <Label
                  key={i}
                  sx={{
                    width: 'fit-content',
                    marginBottom: '0.5em',
                    marginLeft: '0.5em'
                  }}
                >
                  {status}
                </Label>
              ))}
          </Stack>
          <Comment text={item?.comment || ''} />
        </Stack>
      </Stack>
    </Popover>
  )
})

const CommentCol = observer(({item}: CommentPopupProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onIconClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClosePopover = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton disabled={!item.comment} onClick={onIconClick}>
        <CommentIcon />
      </IconButton>
      {anchorEl && (
        <PopoverComponent
          anchorEl={anchorEl}
          onClose={onClosePopover}
          item={item}
        />
      )}
    </>
  )
})
export default CommentCol

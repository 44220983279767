import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import {BoardItems, BoardKey} from 'src/entities/Board'

import {MainStore} from 'src/store/MainStore'

export default class OrderStore {
  orders: Map<string, BoardItems> = new Map()

  isOrderExistHP: boolean | undefined

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      isFirstLoggedin => {
        if (isFirstLoggedin) {
          this.getBoardItemsOfOrders()
        }
      }
    )
  }

  setIsOrderExistHP = (value: boolean) => {
    this.isOrderExistHP = value
  }

  getBoardItemsOfOrders = async () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Orders/BoardItemsOfOrders`)
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          Object.keys(data).forEach((fileNumber: string) => {
            const boardItems = new BoardItems()

            data[fileNumber].forEach((boardItem: any) => {
              const boardKey = new BoardKey({
                id: boardItem.boardId,
                name: boardItem.boardName
              })
              boardItems.setItem(boardKey, boardItem.itemsId)
            })

            this.orders.set(fileNumber, boardItems)
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getBoardsByFN = (fileNumber: string | undefined) => {
    if (fileNumber !== undefined && this.orders.has(fileNumber)) {
      const boardItems = this.orders.get(fileNumber)?.items

      if (boardItems) {
        return Array.from(boardItems.keys())
      }
    }

    return []
  }

  validateOrderInHomePgae = async (fileNumber?: string) => {
    return this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Orders/IsOrderExistInHomePage/${fileNumber}`
      )
      .then(res => res.text())
      .then((data: string) => {
        const isExist = data.toLowerCase() === 'true'
        this.setIsOrderExistHP(isExist)
      })
  }

  getItemsIdByBoardId = (fileNumber: string, boardId: number): number[] => {
    let itemsId: number[] = []
    if (this.orders.has(fileNumber)) {
      const boardItems = this.orders.get(fileNumber)?.items

      if (boardItems) {
        boardItems.forEach((value, key) => {
          if (key.id === boardId) {
            itemsId = value
            return
          }
        })
      }
    }

    return itemsId
  }
}

import * as _ from 'lodash'
import {
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  AlertColor
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {fDate} from 'src/utils/formatTime'
import FolderOpenOutlined from '@mui/icons-material/FolderOpenOutlined'
import {SelectAccessorResponse} from 'src/components/select-app/types'
import {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import CuiSnackbarAlert from 'src/components/custom/CuiSnackbarAlert'
import {LoadingButton} from '@mui/lab'

const getAlertInfo = (res: SelectAccessorResponse | undefined) => {
  switch (res) {
    case SelectAccessorResponse.Success:
      return {
        severity: 'success' as AlertColor,
        message: 'Your note was saved successfully.'
      }
    case SelectAccessorResponse.LockedOrder:
      return {
        severity: 'warning' as AlertColor,
        message:
          'Cant save your note because the order is locked, it will be saved automatically later.'
      }
    case SelectAccessorResponse.Fail:
    case undefined:
      return {
        severity: 'error' as AlertColor,
        message: 'Ooops somehing went wrong, cant save your note'
      }
  }
}

const ChecklistNotes = observer(() => {
  const {
    itemStore: {currentItem},
    selectStore: {createNoteToCheklist, currentChecklist, notes, setNotes}
  } = useMainStore()
  const [newNote, setNewNote] = useState('')
  const [noteLoading, setNoteLoading] = useState(false)
  const [alertInfo, setAlertInfo] = useState<{
    message: string
    severity: AlertColor
  }>()

  useEffect(() => {
    setNotes()
  }, [setNotes])

  const data = _.mapValues(
    _.groupBy(
      notes?.filter(n => n.taskDescription === currentChecklist?.description),
      'createdOn'
    ),
    clist => clist.map(note => _.omit(note, 'createdOn'))
  )

  const createNote = async () => {
    setNoteLoading(true)
    try {
      const res = await createNoteToCheklist(currentItem!, newNote)
      setAlertInfo(getAlertInfo(res))
    } finally {
      setNoteLoading(false)
      setNewNote('')
    }
  }

  const cancelNote = () => {
    setNewNote('')
  }

  return (
    <>
      {currentChecklist && (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{m: '15px'}} />}
              sx={{flexDirection: 'row-reverse'}}
            >
              <FolderOpenOutlined sx={{m: '15px'}} />
              <Typography fontWeight="fontWeightMedium" alignSelf={'center'}>
                Create Comment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={3} direction="column">
                <TextField
                  onChange={e => setNewNote(e.target.value)}
                  value={newNote}
                  placeholder="Add note to select"
                  multiline
                  sx={{width: '100%', p: 3}}
                />
                <Stack
                  sx={{m: 20, minWidth: 0}}
                  minWidth={0}
                  direction="row"
                  spacing={2}
                  alignSelf={'flex-end'}
                >
                  <Button variant="outlined" onClick={cancelNote}>
                    Cancel
                  </Button>

                  <LoadingButton
                    onClick={() => createNote()}
                    loading={noteLoading}
                    variant="contained"
                    disabled={currentChecklist === undefined || newNote === ''}
                  >
                    <span>Create</span>
                  </LoadingButton>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          {Object.entries(data).map(([k, v]) => {
            return (
              <Accordion key={k}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{m: '15px'}} />}
                  sx={{flexDirection: 'row-reverse'}}
                >
                  <FolderOpenOutlined sx={{m: '15px'}} />
                  <Typography
                    fontWeight="fontWeightMedium"
                    alignSelf={'center'}
                  >
                    Comment dated: {fDate(k, 'dd.MM.yyyy')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {v.map((v, i) => {
                    return <Typography key={i}>{v.text}</Typography>
                  })}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </>
      )}
      {alertInfo && (
        <CuiSnackbarAlert
          severity={alertInfo.severity}
          message={alertInfo.message}
          onClose={() => {
            setAlertInfo(undefined)
          }}
        />
      )}
    </>
  )
})
export default ChecklistNotes

import {Stack, Typography} from '@mui/material'
import {Component, ErrorInfo, ReactNode} from 'react'
import SeverErrorIllustration from 'src/images/illustrations/SeverErrorIllustration'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class CuiErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Stack m="auto">
          <SeverErrorIllustration sx={{height: 260, my: {xs: 5, sm: 10}}} />
          <Typography sx={{color: 'text.secondary', m: 'auto'}}>
            There was an error, please try again later.
          </Typography>
        </Stack>
      )
    }

    return this.props.children
  }
}

export default CuiErrorBoundary

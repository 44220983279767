import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import Department from 'src/entities/Department'

export default class User implements Base {
  id: number

  lastName: string

  firstName: string

  phone: string

  department?: Department

  email?: string

  isHere?: boolean

  constructor(user: User) {
    this.id = user.id
    this.lastName = user.lastName
    this.firstName = user.firstName
    this.department = user.department
    this.phone = user.phone
    this.email = user.email
    this.isHere = user.isHere

    makeAutoObservable(this)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get initials() {
    return ((this.firstName[0] || '') + (this.lastName[0] || '')).toUpperCase()
  }

  setIsHere(isHere: boolean) {
    this.isHere = isHere
  }
}

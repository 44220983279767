import {/*IconButton ,*/ iconButtonClasses, Stack} from '@mui/material'
// import SmsIcon from '@mui/icons-material/Sms'
import NotificationsPopover from 'src/components/notification/NotificationsPopover'

interface CommonHeaderProps {
  children: React.ReactNode
}

const CommonHeader = ({children}: CommonHeaderProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      height={72}
      px={3}
      py={1}
      sx={{
        [`& .${iconButtonClasses.root}`]: {
          width: 48,
          height: 48
        }
      }}
    >
      <Stack flexGrow={1}>{children}</Stack>
      {/* <IconButton>
        <SmsIcon />
      </IconButton> */}
      <NotificationsPopover />
    </Stack>
  )
}

export default CommonHeader

import {generatePath, Outlet, useLocation, useNavigate} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {Stack} from '@mui/material'
import ItemViewAppBar from 'src/components/itemView/ItemViewAppBar'
import GeneralInfoTab from './GeneralInfoTab'
import {PATH_WORKFLOW} from 'src/routes/paths'
import {useMainStore} from 'src/context/Main'
import {useEffect} from 'react'

const ItemViewWorkspace = observer(() => {
  const {currentItem} = useMainStore().itemStore
  const navigaete = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (currentItem?.order) {
      const path = generatePath(PATH_WORKFLOW.itemWSPart, {
        fileNumber: currentItem.order.fileNumber,
        isCommertial: currentItem.order.isCommertial ? 1 : 0
      })
      if (!location.pathname.includes(path)) {
        navigaete(
          {
            pathname: path,
            search: location.search
          },
          {replace: true}
        )
      }
    }
  }, [currentItem, navigaete, location.search, location.pathname])

  return (
    <>
      <ItemViewAppBar />
      <Stack direction="row" overflow="auto">
        <GeneralInfoTab />
        <Outlet />
      </Stack>
    </>
  )
})

export default ItemViewWorkspace

import {Button} from '@mui/material'
import {
  FilterPanelPropsOverrides,
  GridFilterModel,
  GridFilterPanel
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import * as _ from 'lodash'
import {UserSettingKey} from 'src/entities/UserSettings'

declare module '@mui/x-data-grid-pro' {
  interface FilterPanelPropsOverrides {
    filterModel: GridFilterModel
    saveFilters: () => void
  }
}

const FilterPanel = observer(
  ({filterModel, saveFilters}: FilterPanelPropsOverrides) => {
    const {getUserSettingsByKey} = useMainStore().userSettingsStore

    const isFilterModelChanged = !_.isEqual(
      getUserSettingsByKey(UserSettingKey.Filters),
      filterModel
    )

    return (
      <>
        <GridFilterPanel
          filterFormProps={{
            // Customize inputs by passing props
            logicOperatorInputProps: {
              variant: 'outlined',
              size: 'small'
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: {mt: 'auto'}
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: {mt: 'auto'}
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small'
              }
            }
          }}
          sx={{
            '& .MuiDataGrid-panelFooter': {
              justifyContent: 'flex-start',
              px: 2.5
            },
            '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
              color: theme => theme.palette.primary.main,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 171, 85, 0.08)'
              }
            },
            '& .MuiDataGrid-filterForm': {p: 2},
            '& .MuiDataGrid-filterFormLogicOperatorInput': {
              mr: 1,
              width: 75
            },
            '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
            '& .MuiDataGrid-filterFormOperatorInput': {mr: 1, width: 150},
            '& .MuiDataGrid-filterFormValueInput': {width: 280},
            '& .MuiDataGrid-filterFormDeleteIcon': {
              justifyContent: 'center'
            }
          }}
        />
        <Button
          disabled={!isFilterModelChanged}
          variant="contained"
          onClick={saveFilters}
          sx={{ml: 1.5, position: 'absolute', bottom: 16, right: 24}}
        >
          Save
        </Button>
      </>
    )
  }
)

export default FilterPanel

import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import {useMainStore} from 'src/context/Main'
import Notification from 'src/entities/Notification'

export interface DeleteNotificationDialogProps {
  notification: Notification
  onClose: () => void
}

const DeleteNotificationDialog = ({
  notification,
  onClose
}: DeleteNotificationDialogProps) => {
  const mainStore = useMainStore()
  const {deleteNotification} = mainStore.notificationStore

  return (
    <>
      <CuiAlertDialog
        isFullWidth
        showMessage={false}
        title="Delete Notification"
        okButtonText="Delete"
        showCancel
        open
        body={'Are you sure you want to delete this notification?'}
        okClick={() => {
          deleteNotification(notification)
          onClose()
        }}
        close={onClose}
      />
    </>
  )
}

export default DeleteNotificationDialog

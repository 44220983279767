import {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import CuiLoading from 'src/components/custom/CuiLoading'
import Menu from 'src/components/itemView/Menu'
import {Stack} from '@mui/material'
import ItemViewModal from 'src/components/itemView/ItemViewModal'
import {useMainStore} from 'src/context/Main'
import {ViewType} from 'src/entities/View'
import ItemViewWorkspace from 'src/components/itemView/ItemViewWorkspace'
import ArchiveDialog from 'src/components/itemView/ArchiveDialog'
import {useParams, useSearchParams} from 'react-router-dom'

const ItemView = observer(() => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {currentItem, setCurrentItem} = mainStore.itemStore

  const [openMenu, setOpenMenu] = useState(true)
  const {itemId} = useParams()
  const [searchParams] = useSearchParams()
  const view = searchParams.get('view')

  useEffect(() => {
    const item = currentBoard?.items.find(i => i.id === Number(itemId))
    if (item) {
      setCurrentItem(item, ViewType[view as keyof typeof ViewType])
    }
  }, [itemId, view, currentBoard?.items, setCurrentItem])

  if (!currentItem || !currentBoard) return <CuiLoading />

  return (
    <>
      {currentItem.isArchive ||
      !currentBoard?.items.some(i => i.id === currentItem.id) ? (
        <ArchiveDialog />
      ) : (
        <>
          {' '}
          {currentItem.order && <Menu open={openMenu} setOpen={setOpenMenu} />}
          <ItemViewModal openMenu={currentItem.order ? openMenu : false} />
          <Stack width="100%" height="100vh">
            <ItemViewWorkspace />
          </Stack>
        </>
      )}
    </>
  )
})

export default ItemView

import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import Department from 'src/entities/Department'

export default class MainDepartment implements Base {
  id: number

  name: string

  departments: Department[]

  constructor(mainDepartment: MainDepartment) {
    this.id = mainDepartment.id
    this.name = mainDepartment.name
    this.departments = mainDepartment.departments.map(d => new Department(d))
    makeAutoObservable(this)
  }

  get sumBoards() {
    return (
      this.departments.reduce((accumulator, department) => {
        return accumulator + department.boards.length
      }, 0) ?? 'No'
    )
  }
}

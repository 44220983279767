import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import BoardView from 'src/components/department/BoardView'
import Department from 'src/entities/Department'

const DepartmentView = observer(({department}: {department: Department}) => {
  return (
    <Stack gap={3}>
      <Typography variant="subtitle1" color="grey.600">
        {department.name}
      </Typography>
      <Stack direction="row" spacing={3}>
        {department.boards.map(b => (
          <BoardView key={b.id} board={b} />
        ))}
      </Stack>
    </Stack>
  )
})

export default DepartmentView

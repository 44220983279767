import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config from 'src/config'
import Board from 'src/entities/Board'
import Item, {FlowStatus} from 'src/entities/Item'
import Member from 'src/entities/Member'
import ItemData from 'src/entities/ItemData'
import View, {ViewType, UsingType} from 'src/entities/View'
import {MainStore} from 'src/store/MainStore'
import Document, {DocumentType} from 'src/entities/Document'
import {createAndDownloadZip} from 'src/utils/download'
import {FileType} from 'src/entities/FileType'
import Column, {
  DBColumn,
  DbTable,
  ColumnData,
  ColumnType
} from 'src/entities/Column'
import User from 'src/entities/User'
import {Control, PermissionType, RoleId} from 'src/entities/PermissionData'
import StatusAssignee, {Operation} from 'src/entities/StatusAssignee'
import RoleSettings from 'src/entities/RoleSettings'
import Order, {Property} from 'src/entities/Order'
import {UserChecklist} from 'src/entities/UserChecklist'
import {stringToLowerCase} from 'src/utils/stringHelper'
import ViewGroup, {inboundGroupName} from 'src/entities/ViewGroup'
import {FunctionUsed} from 'src/store/SharedStore'
import {
  MailboxOrderChangeEvent,
  MailboxOrderPropertyEvent,
  OrderProperty
} from '@madisoncres/title-general-package'
import BoardReport from 'src/entities/Report'
import Condition from 'src/entities/Condition'
import {hasValue} from 'src/utils/validations'
import Update from 'src/entities/Update'
const changeFailedMessage = 'The change you made was not saved in the system'

export class BoardStore {
  currentBoard?: Board

  loadingItemsStatus: Record<number, boolean> = {}

  loadingViewsStatus: Record<number, boolean> = {}

  showAllMyAssignFiles: boolean = false

  get boards() {
    return this.owner.departmentStore.departments.reduce(
      (accumulator: Board[], department) => {
        return accumulator.concat(department.boards)
      },
      []
    )
  }

  get items() {
    return this.boards.reduce((accumulator: Item[], board) => {
      return accumulator.concat(board.items)
    }, [])
  }

  get properties() {
    return this.boards.reduce(
      (accumulator: Record<number, Property[]>, board: Board) => {
        Object.keys(board.properties).forEach((key: string) => {
          const boardId = parseInt(key, 10) // Convert key to number if necessary
          if (accumulator[boardId]) {
            accumulator[boardId] = accumulator[boardId].concat(
              board.properties[boardId]
            )
          } else {
            accumulator[boardId] = board.properties[boardId]
          }
        })
        return accumulator
      },
      {}
    )
  }

  get users() {
    const allUsers = this.boards.flatMap(b => b.members.flatMap(m => m.user))
    const uniqueUsers = Array.from(new Set(allUsers))
    return uniqueUsers
  }

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
    reaction(
      () =>
        this.currentBoard?.items?.filter(
          item =>
            item.documents !== undefined &&
            !item.documents.some(d => d.documentType === DocumentType.Invoice)
        ).length,
      newValues => {
        this.currentBoard?.views.forEach(v => {
          if (v.viewType === ViewType.Invoices) v.count = newValues
        })
      }
    )

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )
  }

  get currentMember() {
    return this.currentBoard?.members.find(
      m => m.user.id === this.owner.loginStore.user?.id
    )
  }

  getWorkflowUser = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/WorkflowUser/${board.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: User) => {
        if (data) {
          board.setWorkflowUser(new User(data))
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  setIsLoadingViewsStatus = (boardId: number, isLoading: boolean) => {
    this.loadingViewsStatus[boardId] = isLoading
  }

  getViews = (board: Board) => {
    this.setIsLoadingViewsStatus(board.id, true)
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/views/all/${board.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: View[]) => {
        if (data) {
          board.setViews(data.map(v => new View(v)))
          if (this.currentBoard?.id === board.id) {
            this.owner.viewStore.setCurrentView(this.getInitialView())
          }
        }
      })
      .finally(() => {
        this.setIsLoadingViewsStatus(board.id, false)
      })
  }

  setItems = (data: Item[], board: Board) => {
    board.setItems(
      data.map(
        i =>
          new Item({
            ...i,
            statusAssignees: i.statusAssignees?.map(
              sa =>
                ({
                  ...sa,
                  user: board.members.find(u => u.userId === sa.userId)?.user
                }) as StatusAssignee
            )
          } as Item)
      )
    )
  }

  setIsLoadingItemsStatus = (boardId: number, isLoading: boolean) => {
    this.loadingItemsStatus[boardId] = isLoading
  }

  getItems = async (board: Board) => {
    this.setIsLoadingItemsStatus(board.id, true)
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Items/${board.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: Item[]) => {
        if (data.length) this.setItems(data, board)
      })
      .finally(() => {
        this.setIsLoadingItemsStatus(board.id, false)
      })
  }

  getAllItems = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Items/${board.id}/All`)
      .then(res => {
        return res.json()
      })
      .then((data: Item[]) => {
        if (data.length) {
          this.setItems(data, board)
        }
      })
  }

  getMembers = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/Members`)
      .then(res => {
        return res.json()
      })
      .then((data: Member[]) => {
        if (data) board.setMembers(data)
      })
  }

  getRoles = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/Roles`)
      .then(res => {
        return res.json()
      })
      .then((data: RoleSettings[]) => {
        if (data.length > 0) board.setRoles(data)
      })
  }

  getReports = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/Reports`)
      .then(res => {
        return res.json()
      })
      .then((data: BoardReport[]) => {
        if (data.length > 0) board.setReports(data)
      })
  }

  getChecklist = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/Checklist`)
      .then(res => {
        return res.json()
      })
      .then((data: UserChecklist[]) => {
        if (data.length > 0) board.setChecklist(data)
      })
  }

  getEmailAccountId = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/EmailAccountId`)
      .then(res => {
        return res.json()
      })
      .then((data: number) => {
        if (data) board.setEmailAccountId(data)
      })
  }

  getDocuments = async (item: Item) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Documents/${item.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: Document[]) => {
        if (data) {
          runInAction(() => (item.documents = data.map(d => new Document(d))))
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getBoardsDetails = () => {
    this.boards.forEach(b => {
      this.getWorkflowUser(b)
      this.getCountItems(b)
      this.getRoles(b)
      this.getReports(b)
      this.getMembers(b).then(() => {
        this.getViews(b)
        this.getItems(b)
        this.getAllItems(b)
        this.getProperties(b)
      })
      this.getEmailAccountId(b)
      this.getChecklist(b)
      this.getColumnData(b)
    })
  }

  getCountItems = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Items/countItems/${board.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: number) => {
        if (data) {
          board?.setItemsCount(data)
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getColumnData = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/ColumnData`)
      .then(res => {
        return res.json()
      })
      .then((data: ColumnData[]) => {
        if (data) board.setColumnData(data)
      })
  }

  getProperties = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Orders/${board.id}/properties`)
      .then(res => {
        return res.json()
      })
      .then((data: Record<number, Property[]>) => {
        if (
          this.owner.itemStore.currentItem?.order?.id &&
          this.owner.permissionStore.getPermissionType(
            this.currentBoard?.itemMenuViews.find(
              v => v.viewType === ViewType.MailBox
            )?.id,
            RoleId.User,
            undefined,
            Control.EmailTemplates
          ) !== PermissionType.Invisible
        )
          this.setMailboxOrderProperty(
            data[this.owner.itemStore.currentItem.order.id]?.[0]
          )
        board.setProperties(data)
      })
  }

  setMemberStatus = async (newValue: boolean) => {
    this.currentBoard?.members
      .find(m => m.user.id === this.owner.loginStore.user?.id)
      ?.setIsAvailable(newValue)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Boards/${this.currentBoard?.id}/IsAvailable`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newValue)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('failed to update member available user')
      }
    } catch (error) {
      this.currentBoard?.members
        .find(m => m.user.id === this.owner.loginStore.user?.id)
        ?.setIsAvailable(!newValue)
      this.owner.setErrorMessage('Something went wrong, please try again')
    }
  }

  saveStatusAssignees = async (
    itemId: number,
    statusAssignees: StatusAssignee[],
    commentAssignee: Record<string, string>
  ) => {
    const addList: StatusAssignee[] = []
    const deleteList: number[] = []

    statusAssignees.forEach(i => {
      if (i.operation === Operation.Add) addList.push(i)
      if (i.operation === Operation.Delete && i.id) deleteList.push(i.id)
      if (i.operation === Operation.Edit) {
        if (i.id) deleteList.push(i.id)
        addList.push(i)
      }
    })

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee/Assignees`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            additionalInfo: encodeURIComponent(JSON.stringify(commentAssignee))
          },
          body: JSON.stringify({
            AddedAssignees: addList,
            DeletededAssigneeIds: deleteList,
            BoardId: this.currentBoard?.id,
            ItemId: itemId
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to save the status assignees.')
      }
    } catch (error) {
      this.owner.setErrorMessage('Failed to assign user, please try again.')
    }
  }

  getInitialView = () => {
    const viewTabs = this.getViewTabs() || []

    if (this.currentMember?.roleId === RoleId.User) {
      const assignedView = viewTabs.find(v => v.isAssignedOnly)
      return assignedView || viewTabs[0]
    }

    return viewTabs[0]
  }

  setCurrentBoard = async (b: Board | undefined) => {
    if (this.currentBoard?.id !== b?.id) {
      this.currentBoard = this.boards.find(board => board.id === b?.id)
      if (this.currentBoard) {
        this.owner.viewStore.setCurrentView(this.getInitialView())
      }
      this.owner.reportStore.setSelectedBoard(this.currentBoard)
    }
  }

  setWorkflowUser = async (isWorkflow: boolean) => {
    const prevWorkflowUser = this.currentBoard?.workflowUser
    this.currentBoard?.setWorkflowUser(
      isWorkflow ? this.owner.loginStore.user || undefined : undefined
    )
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Boards/${this.currentBoard?.id}/WorkflowUser`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(isWorkflow)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('failed to update workflow user')
      }
    } catch (error) {
      this.currentBoard?.setWorkflowUser(prevWorkflowUser)
      this.owner.setErrorMessage('Something went wrong, please try again')
    }
  }

  getDefaultRoleStatus = (item: Item, roleId?: number) => {
    const defaultStatus = item
      .defaultStatusAssignees()
      .find(s => s.roleId === roleId)?.statusId
    if (defaultStatus) return defaultStatus

    const roleSettings = this.currentBoard?.roles.find(r => r.id === roleId)
    return roleSettings?.defaultStatusId
  }

  getViewByType = (type: ViewType, using: UsingType) => {
    return this.currentBoard?.views.find(
      v => v.viewType === type && v.usingType === using
    )
  }

  getViewIdByType = (type: ViewType, using: UsingType) => {
    return this.getViewByType(type, using)?.id
  }

  getViewTabs = () => {
    return this.currentBoard?.views.filter(
      v =>
        v.usingType === UsingType.Tab &&
        this.owner.permissionStore.getPermissionType(v.id) !==
          PermissionType.Invisible
    )
  }

  getColumnsData(item: any, columns: Column[]) {
    return columns.reduce((a, c) => {
      let value = item.getValue(c.dbTableId, c.dbColumn, c.roleId)
      return {
        ...a,
        [c.id]: value
      }
    }, {})
  }

  isUserAssignee = (item: Item, userId?: number) => {
    const isUser = !!item.statusAssignees.find(sa => sa.user?.id === userId)
    return isUser
  }

  getBoardIdByItemId = (itemId: number) => {
    return this.boards.find(b => b.items.find(i => i.id === itemId))?.id
  }

  allSelfAssignRoleExist = (item: Item, selfAssignRoleIds: number[]) => {
    return selfAssignRoleIds.every(roleId =>
      item.statusAssignees.some(sa => sa.roleId === roleId)
    )
  }

  getItemWithColumn(item: Item) {
    const columnsData = this.getColumnsData(
      item,
      this.owner.viewStore.currentView?.columns || []
    )
    return {...item, ...columnsData} as Item
  }

  isRelevantStatus = (item: Item) => {
    const roleIdToCheck = 4
    // To do - support dynamic values
    const statuses = [20, 23, 37]
    const currentStatus = item.getValue(
      DbTable.AssigneStatus,
      undefined,
      roleIdToCheck
    )
    if (currentStatus && statuses.includes(Number(currentStatus))) return true
    return false
  }

  isDefaultRoleExist = (item: Item) => {
    const isExist = item.statusAssignees.filter(
      s => s.roleId === this.currentBoard?.defaultRole?.id && s.userId !== -1
    ).length
    return isExist
  }

  get inboundItemsToAssign() {
    const selfAssignRolesIds =
      this.currentBoard?.roles.filter(r => r.selfAssign).map(r => r.id) || []

    const items = this.currentBoard?.items?.reduce(
      (arr: Item[], item: Item) => {
        if (
          !this.allSelfAssignRoleExist(item, selfAssignRolesIds) &&
          item.flowStatusId !== FlowStatus.Cancelled &&
          this.isRelevantStatus(item) &&
          this.isDefaultRoleExist(item)
        ) {
          const fullItem = this.getItemWithColumn(item)
          arr.push({...fullItem, inboundInAssignee: true} as Item)
        }
        return arr
      },
      []
    )

    return items
  }

  inboundCombinedItems = (group: ViewGroup, condition: Condition) => {
    const statusCondition =
      this.owner.sharedStore.conditions[
        FunctionUsed.FilterBacktitleViewFiles
      ]?.[0]

    const items =
      statusCondition &&
      this.getFilterdItems(
        (item: Item) =>
          this.isIncludeInGroup(group, item) ||
          (condition.viewGroupIds!.includes(item.itemGroupId) &&
            this.roleStatusCondition(statusCondition, item) &&
            !item.statusAssignees.find(
              sa =>
                condition.rolesToApply?.includes(sa.roleId) && sa.userId !== -1
            ))
      )
    return items
  }

  setShowAllMyAssignFiles = (isShow: boolean) => {
    this.showAllMyAssignFiles = isShow
  }

  doesAssigneeMatchCondition = (
    assignee: StatusAssignee,
    condition: Condition,
    group: ViewGroup,
    readingStatus?: number
  ) => {
    if (!condition?.viewGroupIds?.includes(group.id)) return false

    if (!condition?.rolesToApply?.includes(assignee.roleId)) return false

    if (assignee.statusId) {
      return condition?.statusIds?.includes(assignee.statusId)
    }
    // sender pull the status from reading status
    return readingStatus && condition?.statusIds?.includes(readingStatus)
  }

  applyMyAssignFilterCondition = (item: Item, group: ViewGroup) => {
    if (this.showAllMyAssignFiles) return true

    const conditions =
      this.owner.sharedStore.conditions[
        FunctionUsed.ApplyMyAssignFilterCondition
      ]

    const userAssignees = item.statusAssignees.filter(
      sa => sa.userId === this.owner.loginStore.user?.id
    )

    const defaultReadingStatus = item.statusAssignees.find(
      sa => sa.userId === -1 && sa.roleId === 4
    )?.statusId
    // check if have assignee that dont match any condition, if so the user should see that item, oterwise - not
    const nonMatchingAssignees = userAssignees.filter(assignee => {
      // Check if the assignee does not match both conditions
      const matchesCondition1 = this.doesAssigneeMatchCondition(
        assignee,
        conditions[0],
        group
      )
      const matchesCondition2 = this.doesAssigneeMatchCondition(
        assignee,
        conditions[1],
        group,
        defaultReadingStatus
      )

      // Return true if the assignee does not match either condition
      return !matchesCondition1 && !matchesCondition2
    })

    if (nonMatchingAssignees.length > 0) return true
    return false
  }

  getRoleToFilterAssigneeItems = () => {
    const conditionFilterByRole =
      this.owner.sharedStore.conditions[
        FunctionUsed.FilterAssigneeFilesByRole
      ]?.[0]

    const viewId = this.owner.viewStore.currentView?.id!
    if (conditionFilterByRole?.viewIds?.includes(viewId)) {
      const index = conditionFilterByRole?.viewIds?.indexOf(viewId)
      if (index === -1) return undefined
      const roleId = conditionFilterByRole?.rolesToApply?.[index]
      if (roleId) return roleId
    }
    return undefined
  }

  getFilterdItems = (filterFunction: (item: Item) => boolean) => {
    return this.currentBoard?.activeItems?.reduce((arr: Item[], item: Item) => {
      if (filterFunction(item)) {
        const fullItem = this.getItemWithColumn(item)
        arr.push(fullItem)
      }
      return arr
    }, [])
  }

  isIncludeInGroup = (group: ViewGroup, item: Item) => {
    const condition =
      this.owner.sharedStore.conditions[FunctionUsed.FilterBacktitleViewFiles]
    return condition &&
      condition[0].viewIds?.includes(this.owner.viewStore.currentView?.id!)
      ? this.roleStatusCondition(condition[0], item) &&
          group.itemGroupIds.includes(item.itemGroupId)
      : group.itemGroupIds.includes(item.itemGroupId)
  }

  isMatchAssignView = (group: ViewGroup, item: Item) => {
    const roleToFilter = this.getRoleToFilterAssigneeItems()

    return (
      this.isIncludeInGroup(group, item) &&
      item.isUserAssignee({
        userId: this.owner.loginStore.user?.id,
        roleToFilter: roleToFilter
      }) &&
      this.applyMyAssignFilterCondition(item, group)
    )
  }

  roleStatusCondition = (condition: Condition, item: Item) =>
    !!item.statusAssignees.find(
      sa =>
        condition.rolesToApply?.find(r => r === sa.roleId) &&
        condition.statusIds?.find(s => s === sa.statusId)
    )

  getInboundItems = (group: ViewGroup) => {
    let condition =
      this.owner.sharedStore.conditions[FunctionUsed.InboundGroupItems]
    if (condition?.[0].viewGroupIds?.includes(group.id)) {
      return this.inboundItemsToAssign
    }
    condition =
      this.owner.sharedStore.conditions[FunctionUsed.CombinedInboundGroupItems]
    if (
      condition?.[0].boardIds?.includes(this.currentBoard!.id) &&
      condition?.[0].viewIds?.includes(this.owner.viewStore.currentView!.id)
    ) {
      return this.inboundCombinedItems(group, condition[0])
    }

    return this.getFilterdItems((item: Item) =>
      this.isIncludeInGroup(group, item)
    )
  }

  getGroupItems = (group: ViewGroup) => {
    if (!this.currentBoard?.activeItems.length) return []

    if (this.owner.viewStore.currentView?.isAssignedOnly) {
      if (group.name === inboundGroupName) return this.getInboundItems(group)

      return this.getFilterdItems((item: Item) =>
        this.isMatchAssignView(group, item)
      )
    }
    return this.getFilterdItems((item: Item) =>
      this.isIncludeInGroup(group, item)
    )
  }

  getUpdateFunction = (tableId: number) => {
    switch (tableId) {
      case DbTable.Order:
        return this.updateOrder

      case DbTable.Item:
        return this.updateItem

      case DbTable.ItemData:
        return this.updateItemData

      case DbTable.AssigneStatus:
        return this.updateStatusAssignee

      default:
        return this.updateItemData
    }
  }

  getStatusesWithComment = (item: Item, board?: Board) => {
    const role = board?.roles.find(r => r.statusesWithComments)
    const statuses = item.statusAssignees.find(
      s => s.roleId === role?.id
    )?.subStatuses

    const statusesString = statuses?.map((status: number) => {
      const columnData = board?.columnData.find(
        cd =>
          cd.roleId === role?.id &&
          cd.columnTypeId === ColumnType.Status &&
          Number(cd.columnValue) === status
      )
      if (columnData) return JSON.parse(columnData?.data).text as string
      return ''
    })

    return statusesString || []
  }

  getUserRoleByBoard = (boardId: number) => {
    const role = this.boards
      .find(b => b.id === boardId)
      ?.members.find(m => m.userId === this.owner.loginStore.user?.id)?.roleId

    return role
  }

  isUserByBoard = (boardId: number) => {
    return this.getUserRoleByBoard(boardId) === RoleId.User
  }

  updateItemData = async (
    itemId: number,
    columnId: number,
    newValue: any,
    prevValue: any
  ) => {
    this.currentBoard?.items
      .find(i => i.id === itemId)
      ?.itemData.find(d => d.columnId === columnId)
      ?.setData(newValue)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/data`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            columnId: columnId,
            data: JSON.stringify(newValue)
          } as ItemData)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update data.')
      }
    } catch (error) {
      this.currentBoard?.items
        .find(i => i.id === itemId)
        ?.itemData.find(d => d.columnId === columnId)
        ?.setData(prevValue)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  updateItem = async (
    itemId: number,
    columnId: number | undefined,
    newValue: any,
    prevValue: any,
    dbColumn?: DBColumn
  ) => {
    if (dbColumn === null)
      dbColumn = this.owner.viewStore.currentView?.columns?.find(
        c => c.id === columnId
      )?.dbColumn
    this.currentBoard?.items
      .find(i => i.id === itemId)
      ?.setItemProperty(dbColumn?.name || '', newValue)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/property`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            PropertyId: dbColumn?.id,
            Value: hasValue(newValue) ? newValue.toString() : undefined
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update data.')
      }
      return true
    } catch (error) {
      this.currentBoard?.items
        .find(i => i.id === itemId)
        ?.setItemProperty(dbColumn?.name || '', prevValue)

      this.owner.setErrorMessage(changeFailedMessage)
      throw error
    }
  }

  updateOrder = async (
    itemId: number,
    columnId: number | undefined,
    newValue: any,
    prevValue: any,
    dbColumn?: DBColumn
  ) => {
    if (dbColumn === null)
      dbColumn = this.owner.viewStore.currentView?.columns?.find(
        c => c.id === columnId
      )?.dbColumn
    const order = this.currentBoard?.items.find(i => i.id === itemId)?.order
    order?.setOrderProperty(dbColumn?.name || '', newValue)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Orders/${order?.id}/property`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            PropertyId: dbColumn?.id,
            Value: newValue.toString()
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update data.')
      }
      return true
    } catch (error) {
      order?.setOrderProperty(dbColumn?.name || '', prevValue)
      this.owner.setErrorMessage(changeFailedMessage)
      throw error
    }
  }

  updateStatusAssignee = async (
    itemId: number,
    _: number,
    newValue: any,
    prevValue: any,
    roleId: number
  ) => {
    this.currentBoard?.items
      .find(i => i.id === itemId)
      ?.statusAssignees.find(sa => sa.roleId === roleId)
      ?.setStatusId(newValue)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee/item/${itemId}/role/${roleId}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newValue)
        }
      )

      if (!response.ok) {
        throw new Error('Failed to update status.')
      }
      return true
    } catch (error) {
      this.currentBoard?.items
        .find(i => i.id === itemId)
        ?.statusAssignees.find(sa => sa.roleId === roleId)
        ?.setStatusId(prevValue)

      this.owner.setErrorMessage(changeFailedMessage)
      throw error
    }
  }

  addItemUpdate = async (text: string, itemId: number) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Updates/${itemId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({text: text})
        }
      )
      if (!response.ok) {
        throw new Error('Failed to add update.')
      }
      return true
    } catch (error) {
      this.owner.setErrorMessage(changeFailedMessage)
      console.log(error)
      throw error
    }
  }

  uploadDocument = async (
    file: Blob,
    itemId: number,
    fileName: string,
    documentType: DocumentType
  ) => {
    try {
      const formdata = new FormData()
      formdata.append('file', file, fileName)
      formdata.append('documentType', documentType.toString())
      formdata.append('itemId', itemId.toString())
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Documents`,
        {
          method: 'POST',
          body: formdata
        }
      )
      const data = await response.json()
      if (data) {
        runInAction(() =>
          this.currentBoard?.items
            .find(m => m.id === data.itemId)
            ?.documents?.push(data)
        )

        return true
      } else {
        throw new Error('Failed to upload invoice.')
      }
    } catch (error) {
      throw error
    }
  }

  setItemGroup = async (
    itemId: number,
    prevGroupId: number,
    newGroupId: number
  ) => {
    var item = this.currentBoard?.items.find(i => i.id === itemId)
    item?.setGroup(newGroupId)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/group`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newGroupId)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to change group.')
      }
    } catch (error) {
      this.currentBoard?.items.find(i => i.id === itemId)?.setGroup(prevGroupId)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  setIsArchive = async (itemId: number, isArchive: boolean) => {
    var item = this.currentBoard?.items.find(i => i.id === itemId)
    item?.setIsArchive(isArchive)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/archive/${isArchive}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update isArchive.')
      }
    } catch (error) {
      this.currentBoard?.items
        .find(i => i.id === itemId)
        ?.setIsArchive(!isArchive)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  fixFileNumber = async (
    itemId: number,
    boardId: number,
    fileNumber: string
  ) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/fixFileNumber/${boardId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(fileNumber)
        }
      )
      if (response.status === 204) {
        this.owner.setErrorMessage("File number doesn't exist in Select.")
        return null
      }
      const data = await response.json()
      if (!data) throw new Error('Failed to fix fn')
      else if (data.srcItem === null)
        //case of new order
        runInAction(() => {
          this.setMailboxFullFileNumber(
            itemId,
            new Order(data.order).fullFileNumber
          )
          this.items.find(i => i.id === itemId)?.setOrder(new Order(data.order))
          this.owner.mocBoardStore.updateFileNumber(
            itemId,
            data.order.fileNumber
          )
          this.owner.orderStore.getBoardItemsOfOrders()
          this.updateMailboxOrderProperty(data.order)
        })
      //case of merge
      else {
        if (data.isSrcRestored) this.addOrUpdateItem(new Item(data.srcItem))
      }
      return data
    } catch (error) {
      console.error(error)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  getCountItemNoDocuments = async () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Items/countWithoutDocuments/${DocumentType.Invoice}`
      )
      .then(res => {
        return res.json()
      })
      .then((data: number) => {
        if (data) {
          runInAction(() => {
            this.currentBoard?.views?.forEach(iv => {
              if (iv.viewType === ViewType.Invoices) iv.count = data
            })
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  downloadInvoices = async (filteredItems: Item[], folderName: string) => {
    const blobs: {name: string; blob: Blob}[] = []
    if (filteredItems) {
      for (const item of filteredItems) {
        for (const document of item.documents.filter(
          d => d.documentType === DocumentType.Invoice
        )) {
          const blob = await this.owner.azureStorageStore.getBlob(
            document.url,
            FileType.Document
          )
          if (blob) {
            blobs.push({name: document.name, blob})
          }
        }
      }
      await createAndDownloadZip(blobs, folderName)
    }
  }

  setStatusAssignee = async (
    statusAssignee: StatusAssignee,
    prevStatusId: number | undefined,
    newStatusId: number,
    prevSubStatus: number[],
    isOverrideSubStatus: boolean,
    comment?: string
  ) => {
    const prevComment = statusAssignee.comment
    try {
      if (!newStatusId) return
      statusAssignee.setStatusId(newStatusId, isOverrideSubStatus)
      statusAssignee.setComment(comment)
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...statusAssignee,
            isOverrideSubStatus: isOverrideSubStatus
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to save status assignee.')
      }
    } catch (error) {
      statusAssignee.setStatusId(prevStatusId, false)
      statusAssignee.setSubStatusId(prevSubStatus)
      statusAssignee.setComment(prevComment)
      this.owner.setErrorMessage('Failed to update status, please try again.')
    }
  }

  setSubStatusAssignee = async (
    statusAssignee: StatusAssignee,
    newStatusId: number,
    prevStatusId: number,
    subStatus: ColumnData,
    prevSubStatus: number[],
    isAdded: boolean,
    isOverridePrev: boolean
  ) => {
    try {
      statusAssignee.setStatusAndSubId(
        newStatusId,
        parseInt(subStatus.columnValue),
        isAdded,
        isOverridePrev
      )
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee/${statusAssignee.id}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            statusAssigneeId: statusAssignee.id,
            statusId: newStatusId,
            subStatusId: subStatus.columnValue,
            isAdded: isAdded,
            isOverridePrev: isOverridePrev
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update status assignee with sub status.')
      }
    } catch (error) {
      statusAssignee.setStatusId(prevStatusId, false)
      statusAssignee.setSubStatusId(prevSubStatus)
      this.owner.setErrorMessage('Failed to update status, please try again.')
    }
  }

  setMultiSubStatus = async (
    statusAssignee: StatusAssignee,
    newStatusId: number,
    subStatusIdsToAdd: number[],
    subStatusIdsToDelete: number[],
    comment?: string
  ) => {
    const prevStatusId = statusAssignee.statusId!
    const prevSubStatusIds = statusAssignee.subStatuses
    try {
      statusAssignee.setStatusId(newStatusId, true)
      statusAssignee.addSubStatusIds(subStatusIdsToAdd)
      statusAssignee.deleteSubStatusIds(subStatusIdsToDelete)
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee/${statusAssignee.id}/multiSubStatus`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            statusAssigneeId: statusAssignee.id,
            statusId: newStatusId,
            subStatusIdsToAdd: subStatusIdsToAdd,
            subStatusIdsToDelete: subStatusIdsToDelete,
            comment: comment
          })
        }
      )
      if (!response.ok) {
        throw new Error('Failed to save multi sub statuses.')
      }
    } catch (error) {
      console.error(error)
      statusAssignee.setStatusId(prevStatusId, true)
      statusAssignee.setSubStatusId(prevSubStatusIds)
      this.owner.setErrorMessage('Failed to update status, please try again.')
    }
  }

  setMailboxFullFileNumber = (itemId: number, fullFileNumber: string) => {
    if (
      this.owner.itemStore.currentItem?.id === itemId &&
      this.owner.itemStore.currentItem?.order?.fullFileNumber !== fullFileNumber
    )
      MailboxOrderChangeEvent.dispatch({
        fullFileNumber: fullFileNumber
      })
  }

  setMailboxOrderProperty = (property: OrderProperty) => {
    MailboxOrderPropertyEvent.dispatch({
      orderProperty: property
    })
  }

  addOrUpdateItem = (newItem: Item) => {
    const board = this.boards.find(b => b.id === newItem.boardId)
    const item = board?.items.find(i => i.id === newItem.id)
    if (newItem.order?.fileNumber && newItem.isMain)
      this.owner.mocBoardStore.addOrUpdateItem(
        newItem.id,
        newItem.boardId,
        newItem.order.fileNumber
      )
    if (item) item.setItem(new Item(newItem))
    else {
      runInAction(() => {
        board?.items.unshift(newItem)

        this.owner.orderStore.getBoardItemsOfOrders()
      })
    }
    if (newItem.order) {
      this.updateOrderProperties(newItem, newItem.order)
      this.updateMailboxOrderProperty(newItem.order)
    }
  }

  get internalScreenViews() {
    return this.currentBoard?.views.filter(
      v => v.viewType === ViewType.FileData && v.usingType === UsingType.Item
    )
  }

  get infoFileDataViews() {
    return this.currentBoard?.views.filter(
      v => v.viewType === ViewType.FileData && v.usingType === UsingType.App
    )
  }

  archiveOrRestoreItems = (itemsId: number[], isArchive: boolean) => {
    this.items
      .filter(item => itemsId.includes(item.id))
      .forEach(i => {
        i.setIsArchive(isArchive)
      })
  }

  deleteItem = (itemId: number) => {
    runInAction(() => {
      this.owner.mocBoardStore.deleteItem(itemId)
      const board = this.boards.find(
        b => this.items.find(i => i.id === itemId)?.boardId === b.id
      )
      const indexToRemove = board?.items?.findIndex(i => i.id === itemId)
      if (indexToRemove !== undefined && indexToRemove !== -1) {
        board?.items?.splice(indexToRemove, 1)
        this.owner.orderStore.getBoardItemsOfOrders()
      }
    })
  }

  fixfn = (itemId: number, order: Order) => {
    runInAction(() => {
      this.setMailboxFullFileNumber(itemId, new Order(order).fullFileNumber)
      this.items.find(i => i.id === itemId)?.setOrder(new Order(order))
      this.owner.mocBoardStore.updateFileNumber(itemId, order.fileNumber)
      this.owner.orderStore.getBoardItemsOfOrders()
      this.updateMailboxOrderProperty(order)
    })
  }

  updateIsHere = (userId: number, isHere: boolean) => {
    this.boards.forEach(b =>
      b.members.find(m => m.user.id === userId)?.user?.setIsHere(isHere)
    )
  }

  updateAllIsHere = (isHere: boolean) => {
    this.boards.forEach(b => b.members.forEach(m => m.user?.setIsHere(isHere)))
    this.owner.loginStore.user?.setIsHere(isHere)
  }

  addOrUpdateProperty(property: Property) {
    const propToUpdate = this.properties[property.orderId].find(
      p => p.id === property.id
    )
    if (propToUpdate) propToUpdate?.setValues(new Property(property))
    else {
      if (!this.properties[property.orderId]) {
        this.properties[property.orderId] = []
      }
      this.properties[property.orderId].push(property)
    }
  }

  updateOrderProperties = (item: Item, order: Order) => {
    this.setMailboxFullFileNumber(item.id, new Order(order).fullFileNumber)
    item.setOrderProperties(order)
    item.setItemProperty('isPriorityEditable', !order.isRush)
    if (order.isRush) {
      item.setItemProperty('priority', order.isRush)
    }
    this.boards
      .find(b => b.id === item.boardId)
      ?.setPropertiesByOrderId(order.id, order.properties)
  }

  updateMailboxOrderProperty = (order: Order) => {
    if (
      order.id === this.owner.itemStore.currentItem?.order?.id &&
      order.properties &&
      order.properties.length > 0 &&
      this.owner.permissionStore.getPermissionType(
        this.currentBoard?.itemMenuViews.find(
          v => v.viewType === ViewType.MailBox
        )?.id,
        RoleId.User,
        undefined,
        Control.EmailTemplates
      ) !== PermissionType.Invisible
    )
      this.setMailboxOrderProperty(order.properties[0])
  }

  listener = () => {
    this.owner.signalrStore.on(
      'SetWorkflowUser',
      (boardId: number, user: User | undefined) => {
        this.boards.find(b => b.id === boardId)?.setWorkflowUser(user)
      }
    )

    this.owner.signalrStore.on(
      'AddOrDeleteAssignee',
      (addList: StatusAssignee[], deleteItemsIds: number[], itemId: number) => {
        this.items
          .find(i => i.id === itemId)
          ?.setStatusAssignees(
            addList.map(
              a =>
                ({
                  ...a,
                  user: this.users.find(u => u.id === a.userId)
                }) as StatusAssignee
            ),
            deleteItemsIds
          )
      }
    )

    this.owner.signalrStore.on(
      'AddOrUpdateStatusAssignee',
      (statusAssignee: StatusAssignee, isMultiUpdate: boolean) => {
        this.items
          .find(i => i.id === statusAssignee.itemId)
          ?.addOrUpdateStatusAssignee(
            {
              ...statusAssignee,
              user: this.users.find(u => u.id === statusAssignee.userId)
            } as StatusAssignee,
            isMultiUpdate
          )
      }
    )

    this.owner.signalrStore.on(
      'UpdatePriority',
      (itemId: number, isRush: boolean) => {
        this.items
          .find(i => i.id === itemId)
          ?.setItemProperty('priority', isRush)
      }
    )

    this.owner.signalrStore.on(
      'UpdateIsArchive',
      (itemsId: number[], isArchive: boolean) => {
        this.archiveOrRestoreItems(itemsId, isArchive)
      }
    )

    this.owner.signalrStore.on(
      'UpdateStatusAssigneeByRole',
      (itemId: number, roleId: number, statusId: number) => {
        this.items
          .find(i => i.id === itemId)
          ?.setStatusAssigneesByRole(roleId, statusId)
      }
    )

    this.owner.signalrStore.on(
      'DeleteStatusAssignee',
      (statusAssignee: StatusAssignee) => {
        this.items
          .find(i => i.id === statusAssignee.itemId)
          ?.deleteStatusAssignee(statusAssignee)
      }
    )

    this.owner.signalrStore.on('AddOrUpdateItem', (newItem: Item) => {
      this.addOrUpdateItem(new Item(newItem))
    })

    this.owner.signalrStore.on('AddOrUpdateItems', (items: Item[]) => {
      runInAction(() => items.map(i => this.addOrUpdateItem(new Item(i))))
    })

    this.owner.signalrStore.on(
      'UpdateItem',
      (itemId: number, dbColumnName: string, newValue: any) => {
        const dbColumnNameLower = stringToLowerCase(dbColumnName)
        this.items
          .find(i => i.id === itemId)
          ?.setItemProperty(dbColumnNameLower || '', newValue)
      }
    )

    this.owner.signalrStore.on(
      'UpdateOrder',
      (orderId: number, dbColumnName: string, newValue: any) => {
        const dbColumnNameLower = stringToLowerCase(dbColumnName)
        this.items.forEach(
          i =>
            i.order.id === orderId &&
            i.order.setOrderProperty(dbColumnNameLower || '', newValue)
        )
      }
    )

    this.owner.signalrStore.on('UpdateItemData', (itemData: ItemData) => {
      this.items
        .find(i => i.id === itemData.itemId)
        ?.itemData.find(id => id.columnId === itemData.columnId)
        ?.setData(itemData.data)
    })

    this.owner.signalrStore.on(
      'UpdateItemGroup',
      (itemId: number, groupId: number) => {
        this.items.find(i => i.id === itemId)?.setGroup(groupId)
      }
    )

    this.owner.signalrStore.on('syncOrders', (ordersToSync: Order[]) => {
      ordersToSync.forEach((o: Order) => {
        console.log('start to sync order', o.fileNumber)
        const items = this.items.filter(
          i => i.order?.fileNumber === o.fileNumber
        )
        items.forEach(item => this.updateOrderProperties(item, o))
        this.updateMailboxOrderProperty(o)
      })
    })

    this.owner.signalrStore.on(
      'UpdateMembersStatus',
      (members: Member[], boardId: number) => {
        const board = this.boards.find(b => b.id === boardId)
        members.forEach(m =>
          board?.members
            .find(mem => mem.user.id === m.userId)
            ?.setIsAvailable(m.isAvailable)
        )
      }
    )

    this.owner.signalrStore.on(
      'UpdateMemberStatus',
      (userId: number, boardId: number, status: boolean) => {
        const board = this.boards.find(b => b.id === boardId)
        board?.members.find(m => m.user.id === userId)?.setIsAvailable(status)
      }
    )

    this.owner.signalrStore.on(
      'UpdateIsHere',
      (userId: number, isHere: boolean) => {
        this.updateIsHere(userId, isHere)
      }
    )

    this.owner.signalrStore.on('UpdateAllIsHere', (isHere: boolean) => {
      this.updateAllIsHere(isHere)
    })

    this.owner.signalrStore.on('AddMember', (member: Member) => {
      const board = this.boards.find(b => b.id === member.boardId)
      const existMember = board?.members.find(m => m.userId === member.userId)
      if (existMember === undefined) {
        board?.addMember(new Member(member))
      }
    })

    this.owner.signalrStore.on(
      'UpdateNotAvailableMembers',
      (members: Member[]) => {
        console.log('updatedMembers', members)
        members.forEach(m => {
          const board = this.boards.find(b => b.id === m.boardId)
          board?.members
            .find(mb => mb.userId === m.userId)
            ?.setIsAvailable(false)
        })
      }
    )

    this.owner.signalrStore.on(
      'UpdateComment',
      (itemId: number, comment: string, userId: number, createdOn: Date) => {
        this.items
          .find(i => i.id === itemId)
          ?.setComment(comment, createdOn, userId)
      }
    )

    this.owner.signalrStore.on(
      'fixFileNumber',
      (itemId: number, order: Order) => {
        this.fixfn(itemId, order)
      }
    )

    this.owner.signalrStore.on('DeleteItem', (itemId: number) => {
      this.deleteItem(itemId)
    })

    this.owner.signalrStore.on(
      'UpdateStatusAssignees',
      (statusAssignees: StatusAssignee[], updatedItem?: Item) => {
        const item = this.items.find(i => i.id === statusAssignees[0].itemId)
        statusAssignees.forEach(s => {
          const currentStatusAssignee = item?.statusAssignees.find(
            x => x.id === s.id
          )
          currentStatusAssignee?.setStatusId(s.statusId)
          currentStatusAssignee?.setSubStatusId(s.subStatuses)
          currentStatusAssignee?.setComment(s.comment)
        })
        if (updatedItem)
          // the comment is overrided in case of override sub status.
          item?.setComment(
            updatedItem?.comment,
            updatedItem?.commentCreatedOn,
            updatedItem?.commentUserId
          )
      }
    )

    this.owner.signalrStore.on('AddItemUpdate', (update: Update) => {
      const item = this.items.find(i => i.id === update.itemId)
      item?.updates?.push(update)
    })
  }
}

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, {SelectProps} from '@mui/material/Select'

export interface selectOption {
  value: number
  label: string
}

interface CuiSelectWithOptionsProps extends SelectProps {
  options: selectOption[]
  title?: string
  withAllOption?: boolean
}

export default function CuiSelectWithOptions({
  options,
  title,
  withAllOption,
  ...props
}: CuiSelectWithOptionsProps) {
  return (
    <FormControl>
      <InputLabel>{props.label}</InputLabel>
      <Select {...props}>
        {title && (
          <MenuItem value="">
            <em>{title}</em>
          </MenuItem>
        )}
        {withAllOption && (
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
        )}
        {options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

import {useMemo} from 'react'
import CuiWhiteTooltip from 'src/components/custom/CuiWhiteTooltip'
import styled from '@emotion/styled'

import {Avatar, AvatarProps, Badge, Typography} from '@mui/material'
import {stringToColor} from 'src/utils/color'
import {observer} from 'mobx-react-lite'

interface CuiAvatarProps extends AvatarProps {
  photoSrc?: string
  value?: string
  name?: string
  color?: string
  size?: number
  border?: boolean
  isHere?: boolean
}

const OuterBorder = styled('div')(() => ({
  padding: '-0.5px',
  borderRadius: '900px',
  border: '2px solid var(--10-Gradients-02-Secondary, #5BE584)',
  zIndex: 3
}))

const CuiInnerAvatar = ({
  photoSrc,
  value,
  name,
  color,
  size,
  ...props
}: CuiAvatarProps) => {
  const style = useMemo(
    () => ({
      backgroundColor: color || stringToColor(name || ''),
      width: size,
      height: size
    }),
    [color, name, size]
  )

  return (
    <CuiWhiteTooltip title={name}>
      <Avatar src={photoSrc} {...props} style={{...props.style, ...style}}>
        {!photoSrc && (
          <Typography
            color="white"
            fontWeight={600}
            sx={{fontSize: size ? size / 2.4 : undefined}}
          >
            {value}
          </Typography>
        )}
      </Avatar>
    </CuiWhiteTooltip>
  )
}

const CuiOuterAvatar = ({
  photoSrc,
  value,
  name,
  color,
  size,
  border,
  ...props
}: CuiAvatarProps) => {
  return (
    <CuiWhiteTooltip title={name}>
      {border ? (
        <OuterBorder>
          <CuiInnerAvatar
            photoSrc={photoSrc}
            value={value}
            name={name}
            color={color}
            size={size}
            {...props}
          />
        </OuterBorder>
      ) : (
        <CuiInnerAvatar
          photoSrc={photoSrc}
          value={value}
          name={name}
          color={color}
          size={size}
          {...props}
        />
      )}
    </CuiWhiteTooltip>
  )
}

const CuiAvatar = observer(
  ({
    photoSrc,
    value,
    name,
    color,
    size,
    border,
    isHere,
    ...props
  }: CuiAvatarProps) => {
    return (
      <>
        {isHere !== undefined ? (
          <Badge
            overlap="circular"
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            variant="dot"
            color={isHere ? 'primary' : 'warning'}
            sx={{
              fontSize: size ? size / 4 : 3,
              '& .MuiBadge-badge': {
                zIndex: 3
              }
            }}
          >
            <CuiOuterAvatar
              photoSrc={photoSrc}
              value={value}
              name={name}
              color={color}
              size={size}
              border={border}
              {...props}
            />
          </Badge>
        ) : (
          <CuiOuterAvatar
            photoSrc={photoSrc}
            value={value}
            name={name}
            color={color}
            size={size}
            border={border}
            {...props}
          />
        )}
      </>
    )
  }
)

export default CuiAvatar

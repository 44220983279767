import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useState} from 'react'
import DepartmentView from 'src/components/department/DepartmentView'
import CommonHeader from 'src/components/layout/CommonHeader'
import {useMainStore} from 'src/context/Main'
import MainDepartmentView from './MainDepartmentView'

const DepartmentsPage = observer(() => {
  const {
    departmentStore: {mainDepartments}
  } = useMainStore()

  const [mainDepartmentId, setMainDepartment] = useState<number>()

  return (
    <Stack sx={{width: '100%', mx: 'auto'}}>
      <CommonHeader>
        <Typography variant="h5" py={2.25}>
          My Departments
        </Typography>
      </CommonHeader>
      <Stack gap={3} ml={5}>
        <Stack direction="row" alignItems="center" spacing={3} mb={6}>
          {mainDepartments.map(md => (
            <MainDepartmentView
              key={md.id}
              mainDepartment={md}
              isSelectedMainDepartment={md.id === mainDepartmentId}
              setMainDepartment={setMainDepartment}
            />
          ))}
        </Stack>
        {mainDepartments
          .find(md => md.id === mainDepartmentId)
          ?.departments.map(d => <DepartmentView key={d.id} department={d} />)}
      </Stack>
    </Stack>
  )
})

export default DepartmentsPage

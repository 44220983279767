import {observer} from 'mobx-react-lite'
import {useSearchParams} from 'react-router-dom'
import FileViewer from 'src/components/custom/FileViewer'

const PreviewViewer = observer(() => {
  const [searchParams] = useSearchParams()
  //get file
  const fileParam = searchParams.get('file')

  return <>{fileParam && <FileViewer fileUrl={fileParam} />}</>
})
export default PreviewViewer

import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'

export const inboundGroupName = 'Inbound Files'

export default class ViewGroup implements Base {
  id: number

  name: string

  color: string

  isVisible: boolean

  itemGroupIds: number[]

  constructor(group: ViewGroup) {
    this.id = group.id
    this.name = group.name
    this.color = group.color
    this.itemGroupIds = group.itemGroupIds
    this.isVisible = group.isVisible
    makeAutoObservable(this)
  }

  setName(newName: string): void {
    this.name = newName
  }

  setColor(newColor: string): void {
    this.color = newColor
  }
}

import {GridFilterInputValueProps, GridFilterItem} from '@mui/x-data-grid-pro'
import Member from 'src/entities/Member'
import StatusAssignee from 'src/entities/StatusAssignee'
import {
  LabelInputComponent,
  AssigneeInputComponent
} from 'src/components/view/columnTypes/viewHelper'
import {LabelProps} from 'src/components/view/columnTypes/LabelCol'

const isEmpty = (filterItem: GridFilterItem) => {
  if (
    !filterItem.field ||
    !filterItem.operator ||
    !filterItem.value ||
    !filterItem.value.length
  ) {
    return true
  }
  return false
}

const singleSelectFilterFunc = (data: any[] | undefined) => {
  return [
    {
      label: 'Is',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (isEmpty(filterItem)) return null
        return (params: any) => {
          return filterItem.value?.some((v: any) => {
            return v.value === params.value?.toString()
          })
        }
      },
      InputComponent: (props: GridFilterInputValueProps) => (
        <LabelInputComponent data={data} {...props} />
      ),

      getValueAsString: (value: LabelProps[]) =>
        value.map(v => v.text).join(', ')
    },
    {
      label: 'Is Not',
      value: 'is-not',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (isEmpty(filterItem)) return null
        return (params: any) => {
          return !filterItem.value?.some((v: any) => {
            return v.value === params.value?.toString()
          })
        }
      },
      InputComponent: (props: GridFilterInputValueProps) => (
        <LabelInputComponent data={data} {...props} />
      ),
      getValueAsString: (value: LabelProps[]) =>
        value.map(v => v.text).join(', ')
    }
  ]
}

const assigneesFilterFunc = () => {
  return [
    {
      label: 'Is',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (isEmpty(filterItem)) return null

        return (params: any) => {
          return filterItem.value?.some((v: any) => {
            return params.value?.some(
              (u: StatusAssignee) => v.userId === u.userId
            )
          })
        }
      },
      InputComponent: AssigneeInputComponent,
      getValueAsString: (value: Member[]) =>
        value.map(v => v.user.fullName).join(', ')
    },
    {
      label: 'Is Not',
      value: 'is-not',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (isEmpty(filterItem)) return null
        return (params: any) => {
          return filterItem.value?.every((v: any) => {
            return !params.value.some(
              (u: StatusAssignee) => v.userId === u.userId
            )
          })
        }
      },
      InputComponent: AssigneeInputComponent,
      getValueAsString: (value: Member[]) =>
        value.map(v => v.user.fullName).join(', ')
    }
  ]
}

const userAssigneesFilterFunc = (roleId?: number) => {
  return [
    {
      label: 'Is',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (isEmpty(filterItem)) return null
        return (params: any) => {
          return filterItem.value?.some((v: any) => {
            return params.value?.some(
              (u: StatusAssignee) =>
                v.userId === u.userId && u.roleId === roleId
            )
          })
        }
      },
      InputComponent: AssigneeInputComponent,
      getValueAsString: (value: Member[]) =>
        value.map(v => v.user.fullName).join(', ')
    },
    {
      label: 'Is Not',
      value: 'is-not',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (isEmpty(filterItem)) return null
        return (params: any) => {
          return filterItem.value?.every((v: any) => {
            return !params.value.some(
              (u: StatusAssignee) =>
                v.userId === u.userId && u.roleId === roleId
            )
          })
        }
      },
      InputComponent: AssigneeInputComponent,
      getValueAsString: (value: Member[]) =>
        value.map(v => v.user.fullName).join(', ')
    }
  ]
}

export {singleSelectFilterFunc, assigneesFilterFunc, userAssigneesFilterFunc}

import {SimplePaletteColorOptions} from '@mui/material'
import ExcelJS from 'exceljs'
import Column, {ColumnType} from 'src/entities/Column'
import ViewGroup from 'src/entities/ViewGroup'
import palette from 'src/theme/palette'
import {adjust} from 'src/utils/color'

const colors = palette('light')
export const delimiter = ';;;'
const maxWidth = 26

export const exportItemReportAsExcel = async (
  departmentName?: string,
  boardName?: string,
  columns?: Column[],
  groups?: ViewGroup[],
  groupGetDataAsCSV?: Record<number, (options?: any) => string>
) => {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'The Hub'
  workbook.created = new Date()

  const sheet = workbook.addWorksheet(boardName)
  const dCell = sheet.getCell('A1')
  dCell.value = boardName
  dCell.style = {font: {bold: true, size: 15}}
  const bCell = sheet.getCell('A2')
  bCell.value = departmentName
  bCell.style = {font: {bold: true, size: 14}}

  let rowIndex = 3
  for (let i = 0; i < (groups?.length ?? 0); i++) {
    const g = groups![i]
    sheet.getRow(rowIndex++).height = 40

    const gCell = sheet.getRow(rowIndex++).getCell(1)
    gCell.value = g.name
    gCell.style = {
      font: {bold: true, size: 14, color: {argb: g.color.replace('#', '')}}
    }

    for (let j = 0; j < (columns?.length ?? 0); j++) {
      sheet.getColumn(j + 1).width = (columns![j].width - 12) / 7 + 1
    }

    let row = sheet.getRow(rowIndex++)
    row.font = {size: 11, bold: true}
    for (let j = 0; j < (columns?.length ?? 0); j++) {
      const c = columns![j]
      const cell = row.getCell(j + 1)
      cell.value = c.name
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'C4CDD5'}
      }
      cell.alignment = {vertical: 'middle', horizontal: 'center'}
    }

    const lines = groupGetDataAsCSV?.[g.id]().split('\n')
    for (let i = 0; i < (lines?.length ?? 0); i++) {
      const line = lines![i]
      const row = sheet.getRow(rowIndex++)
      line.split(delimiter).forEach((value, i) => {
        const column = columns?.[i]
        const cell = row.getCell(i + 1)
        const cleanValue = value.replace(/\r/g, '')
        if (!cleanValue || isNaN(+cleanValue)) cell.value = cleanValue
        else cell.value = +cleanValue
        cell.alignment = {
          vertical: 'middle',
          horizontal: i === 0 ? 'left' : 'center'
        }
        setCellStyle(cell, value, column)
      })
    }
  }

  adjustColumnWidth(sheet)

  const buffer = await workbook.xlsx.writeBuffer()
  return buffer
}

const setCellStyle = (cell: ExcelJS.Cell, value: string, column?: Column) => {
  switch (column?.type) {
    case ColumnType.BoldText:
      if (column?.data?.some(d => JSON.parse(d?.data ?? '{}').text === value)) {
        cell.font = {bold: true}
      }
      break
    case ColumnType.Label:
    case ColumnType.Status:
      const data = column?.data?.find(
        d => JSON.parse(d?.data ?? '{}').text === value
      )
      const color = JSON.parse(data?.data ?? '{}').color as string
      const argb =
        color === 'default'
          ? colors.grey[500]
          : (colors[color as keyof typeof colors] as SimplePaletteColorOptions)
              ?.main
      if (argb) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: adjust(argb, 30).replace('#', '')}
        }
        cell.font = {color: {argb: 'FFFFFF'}} // white
        let borderColor = (
          colors[color as keyof typeof colors] as SimplePaletteColorOptions
        )?.dark
        if (borderColor) {
          borderColor = adjust(borderColor, 30).replace('#', '')
          cell.border = {
            top: {style: 'thin', color: {argb: borderColor}},
            left: {style: 'thin', color: {argb: borderColor}},
            bottom: {style: 'thin', color: {argb: borderColor}},
            right: {style: 'thin', color: {argb: borderColor}}
          }
        }
      }
      break
  }
}

const adjustColumnWidth = (worksheet: ExcelJS.Worksheet) => {
  for (let i = 0; i < worksheet.columns.length; i++) {
    const column = worksheet.columns[i]
    if (column.number === 1) continue
    const lengths = column.values?.map(v => v?.toString().length)
    const maxLength = Math.max(
      ...((lengths?.filter(v => typeof v === 'number') ?? []) as number[])
    )
    if (maxLength > (column.width ?? 0)) column.width = maxLength + 5
    if ((column.width ?? 0) > maxWidth) column.width = maxWidth
  }
}

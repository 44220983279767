import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import CommonHeader from 'src/components/layout/CommonHeader'
import DisplayBoard from 'src/components/workspace/DisplayBoard'
import {useMainStore} from 'src/context/Main'

const BoardView = observer(() => {
  const {
    boardStore: {currentBoard}
  } = useMainStore()

  if (!currentBoard?.id) return <></>

  return (
    <>
      <CommonHeader>
        <Stack>
          <Typography variant="h6">{currentBoard?.name}</Typography>
        </Stack>
      </CommonHeader>{' '}
      <DisplayBoard key={currentBoard?.id} />
    </>
  )
})

export default BoardView

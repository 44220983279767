import {Stack, Typography} from '@mui/material'
import NoContentIcon from 'src/images/empty_content.svg?react'

interface NoContentProps {
  text?: string
}

const NoContent = ({text}: NoContentProps) => {
  return (
    <Stack alignItems="center" justifyContent="center" spacing={3} m="auto">
      <NoContentIcon height="40vh" width="40vh" />
      <Typography>{text ?? 'No content'}</Typography>{' '}
    </Stack>
  )
}

export default NoContent

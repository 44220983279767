import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {Suspense, useMemo} from 'react'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import {Stack} from '@mui/material'
import {ConversationControl} from '@madisoncres/title-general-package'
import PermissionData, {
  Control,
  PermissionType,
  RoleId
} from 'src/entities/PermissionData'
import config from 'src/config'
import {ViewType} from 'src/entities/View'
import loadable from '@loadable/component'

const ConversationList = loadable(() => import('cbChat/ConversationList'))

const preparePermissions = (permissions: PermissionData[]) => {
  const newPermission = {
    [ConversationControl.Assign]: PermissionType.Invisible,
    [ConversationControl.Status]: PermissionType.Invisible,
    [ConversationControl.Filter]: PermissionType.Invisible,
    [ConversationControl.SeeAll]: PermissionType.Write
  } as Record<ConversationControl, PermissionType>
  permissions.forEach(p => {
    switch (p.controlId) {
      case Control.Create:
        newPermission[ConversationControl.Create] = p.permissionTypeId
        return
      case Control.Reply:
        newPermission[ConversationControl.Reply] = p.permissionTypeId
        return
    }
  })
  return newPermission
}

interface ProofingListProps {
  viewId: number
}

const ProofingList = observer(({viewId}: ProofingListProps) => {
  const mainStore = useMainStore()
  const {currentItem, myRolesId} = mainStore.itemStore
  const {currentBoard, currentMember} = mainStore.boardStore
  const {getPermissionTypesByView} = mainStore.permissionStore

  const additionalInfo = useMemo(
    () => ({
      fileNumber: currentItem?.order?.fileNumber,
      boardId: currentBoard?.id,
      roleId:
        myRolesId?.[0] ??
        (currentMember?.roleId === RoleId.Manager ? RoleId.Manager : undefined)
    }),
    [
      currentItem?.order?.fileNumber,
      currentBoard?.id,
      myRolesId,
      currentMember?.roleId
    ]
  )

  const permissions = useMemo(
    () => preparePermissions(getPermissionTypesByView(myRolesId, viewId)),
    [getPermissionTypesByView, myRolesId, viewId]
  )

  const isSendButtonDisabled =
    currentItem?.countySearchStatusId?.toString() !== '13' // Todo:: - support dynamic values
  const isSendButtonDisabledReason = isSendButtonDisabled
    ? 'Hold on! Please upload county search and update status before handing it in for proofing.'
    : ''

  return (
    <ConversationList
      viewId={ViewType[ViewType.Proofing]}
      entityId={currentItem?.id}
      entityTypeId={config.itemEntityTypeId}
      conversationTypeId={config.proofingConversationType}
      additionalInfo={additionalInfo}
      importanceConversationTypeId={config.questionConversationType}
      permissions={permissions}
      isSendButtonDisabled={isSendButtonDisabled}
      isSendButtonDisabledReason={isSendButtonDisabledReason}
    />
  )
})

interface ProofingProps {
  viewId: number
}

const Proofing = observer(({viewId}: ProofingProps) => {
  return (
    <Stack>
      <CuiErrorBoundary>
        <Suspense fallback={<></>}>
          <ProofingList viewId={viewId} />
        </Suspense>
      </CuiErrorBoundary>
    </Stack>
  )
})

export default Proofing

import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip'
import {styled} from '@mui/material'

const CuiWhiteTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    maxWidth: 500,
    maxHeight: 300,
    overflow: 'auto',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid'
  }
}))

export default CuiWhiteTooltip

import {Autocomplete, Chip, TextField} from '@mui/material'
import {observer} from 'mobx-react-lite'
import LabelCol from 'src/components/view/columnTypes/LabelCol'
import DownIcon from 'src/images/down.svg?react'
import {useMainStore} from 'src/context/Main'
import {Filter} from 'src/store/ReportStore'
import {ColumnType} from 'src/entities/Column'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

interface FieldFilterToolbarProps {
  columnId: number
}

const FieldFilterToolbar: React.FC<FieldFilterToolbarProps> = observer(
  ({columnId}) => {
    const mainStore = useMainStore()
    const {
      view,
      setCurrentFieldFilter,
      setCurrentPeriodFilter,
      periodFilters,
      removeFieldFilter,
      updateFieldFilter
    } = mainStore.reportStore
    const filterColumn = view?.columns?.find(c => c.id === columnId)
    const data = filterColumn?.data?.map(d => ({
      value: d.columnValue,
      ...JSON.parse(d.data)
    }))

    const handleChange = (_event: React.SyntheticEvent, newValue: any[]) => {
      removeFieldFilter(columnId) //remove previous filter.
      const newFilters: Filter[] = newValue.map(item => ({
        filterKey: columnId.toString(),
        filterValue: item.value
      }))
      setCurrentFieldFilter(newFilters)
    }

    const shouldDisableMonth = (date: Date) => {
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth()
      const selectedMonth = date.getMonth()
      return selectedMonth > currentMonth
    }

    const onDateChange = (date: Date | null) => {
      if (date) {
        setCurrentPeriodFilter(periodFilters?.find(p => p.functionName === '')) //change the period filter to "all".
        updateFieldFilter({
          filterKey: columnId.toString(),
          filterValue: date
        })
      } else removeFieldFilter(columnId)
    }

    const render = () => {
      switch (filterColumn?.type) {
        default:
        case ColumnType.Status: {
          return (
            <>
              <Autocomplete
                multiple
                size="small"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      <LabelCol
                        pointer
                        text={option.text || ''}
                        color={option.color || 'primary'}
                      />
                    </li>
                  )
                }}
                getOptionLabel={option => option.text}
                limitTags={2}
                popupIcon={<DownIcon />}
                onChange={handleChange}
                isOptionEqualToValue={(option, v) =>
                  option.value ? option.value === v.value : option === v
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      size="small"
                      color={option.color}
                      variant="soft"
                      label={option.text}
                      {...getTagProps({index})}
                      key={option.value}
                    />
                  ))
                }
                sx={{width: 270}}
                options={data || []}
                renderInput={params => (
                  <TextField {...params} label={filterColumn?.name} />
                )}
              />
            </>
          )
        }
        case ColumnType.Date: {
          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['month']}
                  label="Select Month"
                  onChange={date => onDateChange(date as Date)}
                  shouldDisableMonth={shouldDisableMonth}
                  slotProps={{
                    textField: {size: 'small'},
                    field: {clearable: true}
                  }}
                />
              </LocalizationProvider>
            </>
          )
        }
      }
    }

    return <>{render()}</>
  }
)

export default FieldFilterToolbar

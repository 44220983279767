import JSZip from 'jszip'

export function downloadUrl(
  url: string,
  fileName: string,
  isMultiple?: boolean
) {
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  if (isMultiple) link.target = '_blank'
  link.click()
}

export function downloadBlob(blob: Blob, fileName: string) {
  const href = window.URL.createObjectURL(blob)
  downloadUrl(href, fileName)
  window.URL.revokeObjectURL(href)
}
export async function createAndDownloadZip(
  blobs: {name: string; blob: Blob}[],
  fileName: string
) {
  const zip = new JSZip()
  const nameCounts: Record<string, number> = {}

  for (const blob of blobs) {
    const {name, blob: fileBlob} = blob

    if (nameCounts[name]) {
      const count = nameCounts[name]
      const extension = name.substring(name.lastIndexOf('.'))
      const newName = `${name.substring(
        0,
        name.lastIndexOf('.')
      )} (${count})${extension}`
      nameCounts[name] += 1

      zip.file(newName, fileBlob)
    } else {
      nameCounts[name] = 1
      zip.file(name, fileBlob)
    }
  }

  const content = await zip.generateAsync({type: 'blob'})
  downloadBlob(content, fileName)
}

import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import Item from 'src/entities/Item'
import User from 'src/entities/User'
import View, {UsingType, ViewType} from 'src/entities/View'
import Member from 'src/entities/Member'
import RoleSettings from 'src/entities/RoleSettings'
import {UserChecklist} from 'src/entities/UserChecklist'
import {ColumnData} from 'src/entities/Column'
import {Property} from 'src/entities/Order'
import BoardReport from 'src/entities/Report'

export default class Board implements Base {
  id: number

  name: string

  views: View[]

  members: Member[]

  roles: RoleSettings[]

  reports: BoardReport[]

  items: Item[]

  emailAccountId: number

  workflowUser?: User

  itemsCount: number

  checklist: UserChecklist[]

  columnData: ColumnData[]

  isDisplayPublicFolder: boolean

  properties: Record<number, Property[]> = {}

  constructor(board: Board) {
    this.id = board.id
    this.name = board.name
    this.views = board.views?.map(v => new View(v))
    this.members = board.members?.map(m => new Member(m)) || []
    this.emailAccountId = board.emailAccountId
    this.items = board.items?.map(i => new Item(i))
    this.roles = board.roles?.map(r => new RoleSettings(r)) || []
    this.workflowUser = board.workflowUser
    this.itemsCount = board.itemsCount
    this.checklist = board.checklist
    this.columnData = board.columnData
    this.isDisplayPublicFolder = board.isDisplayPublicFolder
    this.properties = board.properties
    this.reports = board.reports
    makeAutoObservable(this)
  }

  setWorkflowUser(user: User | undefined) {
    this.workflowUser = user
  }

  get itemMenuViews() {
    return this.views.filter(v => v.usingType === UsingType.ItemMenu)
  }

  get infoTabUsingViews() {
    return this.views.filter(v => v.usingType === UsingType.Info)
  }

  get itemHeaderView() {
    return this.views.find(
      v => v.usingType === UsingType.Item && v.viewType === ViewType.Header
    )
  }

  get availableMembers() {
    return this.members.filter(m => m.isAvailable)
  }

  get notAvailableMembers() {
    return this.members.filter(m => !m.isAvailable)
  }

  get report() {
    if (this.reports) return this.reports[0]
  }

  getParentRoleId = (roleId: number) => {
    return this.roles.find(r => r.id === roleId)?.parentRole?.id
  }

  setViews(views: View[]) {
    this.views = views
  }

  setItems(items: Item[]) {
    this.items = items
  }

  setRoles(roles: RoleSettings[]) {
    this.roles = roles.map(r => new RoleSettings(r))
  }

  setReports(reports: BoardReport[]) {
    this.reports = reports.map(r => new BoardReport(r))
  }

  addMember(member: Member) {
    this.members.push(member)
    this.members = this.members.sort((a, b) =>
      a.user.fullName.localeCompare(b.user.fullName)
    )
  }

  setMembers(members: Member[]) {
    this.members = members
      .map(r => new Member(r))
      .sort((a, b) => a.user.fullName.localeCompare(b.user.fullName))
  }

  setEmailAccountId(emailAccountId: number) {
    this.emailAccountId = emailAccountId
  }

  getUserMember(userId?: number) {
    return this.members.find(m => m.user.id === userId)?.user
  }

  getUserMemberByName(userName?: string) {
    return this.members.find(m => m.user.fullName === userName)?.user
  }

  setItemsCount(itemsCount: number) {
    this.itemsCount = itemsCount
  }

  setProperties(properties: Record<number, Property[]>) {
    this.properties = {}
    Object.entries(properties).forEach(([orderIdStr, propertiesList]) => {
      const orderId = parseInt(orderIdStr, 10)
      if (!this.properties[orderId]) this.properties[orderId] = []
      propertiesList.forEach(p =>
        this.properties[orderId].push(new Property(p))
      )
    })
  }

  setPropertiesByOrderId(orderId: number, properties?: Property[]) {
    if (properties) {
      this.properties[orderId] = []
      properties.forEach(p => this.properties[orderId].push(new Property(p)))
    }
  }

  setChecklist(checklist: UserChecklist[]) {
    this.checklist = checklist
  }

  setColumnData = (columnData: ColumnData[]) => {
    this.columnData = columnData
  }

  get defaultRole() {
    return this.roles.find(r => r.isDefault)
  }

  getRoleById = (roleId: number) => {
    return this?.roles.find(r => r.id === roleId)
  }

  getColumnDataByStatus = (statusId: number | undefined) => {
    if (!statusId) return {}
    const data = this.columnData?.find(
      d => d.columnValue === statusId?.toString()
    )?.data

    if (data) return JSON.parse(data)
    return {}
  }

  get isEditableRoles() {
    return this.roles.filter(r => r.isEditable)
  }

  getItemByFileNumber = (fileNumber: string) => {
    return this.items.find(i => i.order?.fileNumber === fileNumber)
  }

  isEditableRole = (roleId: number) => {
    return this.roles.find(r => r.id === roleId)?.isEditable
  }

  get activeItems() {
    return this.items.filter(i => !i.isArchive)
  }

  get itemsLength() {
    return this.activeItems.length || this.itemsCount
  }

  get itemsCountFormat() {
    return `${this.itemsLength} item${this.itemsLength > 1 ? 's' : ''}`
  }
}

export class BoardKey {
  id: number

  name: string

  constructor(boardKey: BoardKey) {
    this.id = boardKey.id
    this.name = boardKey.name
    makeAutoObservable(this)
  }
}
export class BoardItems {
  items: Map<BoardKey, number[]>

  constructor(
    boardItems: BoardItems = {
      items: new Map<BoardKey, number[]>()
    } as BoardItems
  ) {
    this.items = boardItems.items
    makeAutoObservable(this)
  }

  setItem(boardKey: BoardKey, itemsId: number[]) {
    this.items.set(boardKey, itemsId)
  }
}

import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {paletteColors} from 'src/config'
import MainDepartment from 'src/entities/MainDepartment'

interface MainDepartmentProps {
  mainDepartment: MainDepartment
  isSelectedMainDepartment: boolean
  setMainDepartment: (id: number) => void
}

const MainDepartmentView = observer(
  ({
    mainDepartment,
    isSelectedMainDepartment,
    setMainDepartment
  }: MainDepartmentProps) => {
    return (
      <Stack
        onClick={() => setMainDepartment(mainDepartment.id)}
        bgcolor={
          isSelectedMainDepartment
            ? `${paletteColors[mainDepartment.id]}.light`
            : `${paletteColors[mainDepartment.id]}.lighter`
        }
        boxShadow={isSelectedMainDepartment ? 6 : 0}
        color={`${paletteColors[mainDepartment.id]}.darker`}
        borderRadius={2}
        sx={{cursor: 'pointer'}}
        width={380}
        height={242}
      >
        <Stack m="auto" alignItems="center">
          <Typography variant="h3" textAlign="center">
            {mainDepartment.name}
          </Typography>
          <Typography variant="body1">
            {`${mainDepartment.sumBoards} Open Board${
              mainDepartment.sumBoards === 1 ? '' : 's'
            }`}
          </Typography>
        </Stack>
      </Stack>
    )
  }
)

export default MainDepartmentView

import {List, ListSubheader, Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import NoContent from 'src/components/custom/NoContent'
import NotificationItem from 'src/components/notification/NotificationItem'
import NotificationSkeleton from 'src/components/notification/NotificationSkeleton'
import {useMainStore} from 'src/context/Main'

const NotificationsBody = () => {
  const {todayNotifications, olderNotifications, isLoading} =
    useMainStore().notificationStore

  const hasTodayNotifications = todayNotifications.length > 0
  const hasOlderNotifications = olderNotifications.length > 0

  if (isLoading)
    return (
      <>
        {' '}
        <NotificationSkeleton />
        <NotificationSkeleton />
      </>
    )

  return (
    <Stack maxHeight="80vh" overflow="auto">
      {hasTodayNotifications && (
        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{py: 2, px: 2.5, typography: 'overline'}}
            >
              Today
            </ListSubheader>
          }
        >
          {todayNotifications.map(notification => (
            <NotificationItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </List>
      )}
      {hasOlderNotifications && (
        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{py: 2, px: 2.5, typography: 'overline'}}
            >
              Older notifications
            </ListSubheader>
          }
        >
          {olderNotifications.map(notification => (
            <NotificationItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </List>
      )}
      {!hasTodayNotifications && !hasOlderNotifications && (
        <NoContent text="No notifications" />
      )}
    </Stack>
  )
}

export default observer(NotificationsBody)

import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import InfoView from 'src/components/view/itemView/InfoView'
import GeneralNotes from 'src/components/select-app/GeneralNotes'
import {IconButton, Stack} from '@mui/material'
import ChecklistDataGrid from 'src/components/select-app/checklist/Checklist'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import CuiResizable from '../custom/CuiResizable'
import {UsingType, ViewType} from 'src/entities/View'
import {Control} from 'src/entities/PermissionData'
import {PermissionType} from '@madisoncres/title-general-package'

const GeneralInfoTab = observer(() => {
  const {
    permissionStore: {getColumnsPermissionType, getPermissionType},
    itemStore: {currentItem, myRolesId},
    boardStore: {
      currentBoard,
      internalScreenViews: fileDataViews,
      getViewIdByType
    },
    selectStore: {setIsChecklistView, isChecklistView}
  } = useMainStore()

  const showControl = (control: Control) => {
    if (!currentBoard?.views.length) return false

    const generalNotesPermissionType = getPermissionType(
      getViewIdByType(ViewType.GeneralInfo, UsingType.App),
      undefined,
      undefined,
      control
    )

    return generalNotesPermissionType
  }
  const generalNotesPermissionType = showControl(Control.GeneralNotes)
  const checkListPermissionType = showControl(Control.Checklist)

  return (
    <CuiResizable
      minWidth="20%"
      maxWidth="80%"
      minHeight="100%"
      defaultSize={{height: '100%', width: '45%'}}
      enable={{right: true}}
      showColor={true}
      style={{height: '100%'}}
    >
      <Stack
        pl={4}
        pr={2}
        mt={1}
        width="100%"
        height="100%"
        overflow="auto"
        position="relative"
      >
        {isChecklistView === false ? (
          <>
            <Stack mb={4} spacing={4}>
              {fileDataViews?.map(v => (
                <InfoView
                  key={v.id}
                  view={v}
                  currentItem={currentItem!}
                  permissionsColumn={getColumnsPermissionType(v, myRolesId)}
                />
              ))}
            </Stack>
            {generalNotesPermissionType !== PermissionType.Invisible && (
              <Stack pb={3}>
                <GeneralNotes />
              </Stack>
            )}
            {checkListPermissionType !== PermissionType.Invisible && (
              <IconButton
                onClick={() => setIsChecklistView()}
                sx={{position: 'absolute', right: 16}}
              >
                <TaskOutlinedIcon />
              </IconButton>
            )}
          </>
        ) : (
          <ChecklistDataGrid />
        )}
      </Stack>
    </CuiResizable>
  )
})

export default GeneralInfoTab

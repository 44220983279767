import {CircularProgress, Typography, Grid, Box} from '@mui/material'

interface CuiLoadingProps {
  message?: string | undefined
  color?: string
  size?: number
  mt?: number
}

export default function CuiLoading({
  message,
  color,
  size,
  mt
}: CuiLoadingProps) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{mt: mt ?? '13%'}}
    >
      <Box color={color ? color : 'primary'}>
        <CircularProgress
          color={color ? 'inherit' : 'primary'}
          size={size || 70}
        />
      </Box>

      <Box color={color ? color : 'inherit'}>
        <Typography color="inherit">{message}</Typography>
      </Box>
    </Grid>
  )
}

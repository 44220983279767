import {useState, useCallback} from 'react'
import {
  DataGridPro,
  DataGridProProps,
  gridClasses,
  GridCellModesModel,
  GridCellParams,
  GridCellModes
} from '@mui/x-data-grid-pro'

interface DataGridWrapperProps extends DataGridProProps {}

export default function DataGridWrapper({...props}: DataGridWrapperProps) {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) return

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return
      }

      setCellModesModel(prevModel => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: {mode: GridCellModes.View}
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({...acc, [field]: {mode: GridCellModes.View}}),
              {}
            ),
            [params.field]: {mode: GridCellModes.Edit}
          }
        }
      })
    },
    []
  )

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel)
    },
    []
  )

  return (
    <DataGridPro
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      {...props}
      sx={{
        [`&. MuiDataGrid-main`]: {width: '100%'},
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none'
          },
        [`& .${gridClasses.overlayWrapperInner}`]: {
          display: 'none'
        },
        minHeight: '150px',
        maxHeight: '80vh',
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none'
          },
        [`& .${gridClasses.overlayWrapperInner}`]: {
          display: 'none'
        },
        [`& .${gridClasses.pinnedColumns}:first-of-type, .${gridClasses.pinnedColumns}:first-of-type`]:
          {
            boxShadow: 'none'
          }
      }}
    />
  )
}

import {useCallback, useEffect, useRef, useState} from 'react'
import WebViewer, {WebViewerInstance} from '@pdftron/webviewer'
import config from 'src/config'
interface FileViewerProps {
  fileUrl?: string
}

export default function FileViewer({fileUrl}: FileViewerProps) {
  const viewer = useRef(null)
  const [viewerInstance, setViewerInstance] =
    useState<WebViewerInstance | null>(null)

  useEffect(() => {
    ;(async () => {
      if (!viewerInstance) return
      if (!fileUrl) {
        viewerInstance.UI.closeDocument()
        return
      }
      if (fileUrl) {
        viewerInstance.UI.loadDocument(fileUrl)
      }
    })()
  }, [fileUrl, viewerInstance])

  const initWebViewer = useCallback(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        enableAzureWorkaround: true,
        streaming: false,
        licenseKey: config.viewerLicenseKey,
        disabledElements: [
          'toolsHeader',
          'ribbons',
          'menuButton',
          'thumbnailControl'
        ]
      },
      viewer.current || new HTMLElement()
    ).then(instance => {
      setViewerInstance(instance)
    })
  }, [])

  useEffect(() => {
    if (!viewer.current) return
    initWebViewer()
  }, [viewer, initWebViewer])

  return (
    <>
      <div className="MyComponent">
        <div className="webviewer" ref={viewer} style={{height: '85vh'}} />
      </div>
    </>
  )
}

import React, {useEffect, useState} from 'react'
import {
  Autocomplete,
  Button,
  DialogActions,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import VirtualizeListboxComponent from 'src/components/custom/VirtualizeAutocomplete'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {ItemFileNumber} from 'src/entities/MocBoard'
import PopperComponent from 'src/components/custom/PopperComponent'
import {
  EntityFileNumber,
  MailboxMocEvent
} from '@madisoncres/title-general-package'
import RenderOption from 'src/components/moc/RenderOption'
import RenderInput from 'src/components/moc/RenderInput'

interface ItemsProps {
  boardId: number
  onBack: () => void
  onClose: () => void
  onRerender: (shouldRerender: boolean) => void
}

const getStringValue = (newValue: ItemFileNumber) => {
  return typeof newValue === 'string' ? newValue : newValue.fileNumber
}

const Items = observer(({boardId, onBack, onClose, onRerender}: ItemsProps) => {
  const [selectedCurrentItem, setSelectedCurrentItem] = useState(true)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')
  const [newItems, setNewItems] = useState<string[]>([])
  const mainStore = useMainStore()
  const {isChecking, getMocBoard, addItems, validateFileNumber} =
    mainStore.mocBoardStore
  const {currentItem} = mainStore.itemStore

  const mocBoard = getMocBoard(boardId)

  const options = newItems
    .map(o => ({id: 0, fileNumber: o}) as ItemFileNumber)
    .concat(mocBoard.itemFileNumbers.filter(i => i.id !== currentItem?.id))

  const unselectCurrentItem = () => {
    setSelectedCurrentItem(prev => !prev)
  }

  const onDone = async () => {
    onClose()
    const items = options
      .filter(o => selectedItems.includes(o.fileNumber))
      .map(
        i =>
          ({
            id: i.id,
            fileNumber: i.fileNumber,
            isNew: i.id === 0
          }) as EntityFileNumber
      )
    if (items.some(i => i.id === 0)) {
      const data = await addItems(
        boardId,
        items.filter(i => i.id === 0).map(i => i.fileNumber)
      )
      if (data)
        items.map(e =>
          e.id === 0
            ? (e.id = data.find(d => d.fileNumber === e.fileNumber)!.id)
            : e.id
        )
    }
    MailboxMocEvent.dispatch({
      entityFileNumbers: items,
      isCopy: selectedCurrentItem
    })
  }
  const onCreate = async () => {
    if (await validateFileNumber(boardId, inputValue)) {
      setSelectedItems(prev => [...prev, inputValue])
      setNewItems(prev => [...prev, inputValue])
      setInputValue('')
    }
  }

  const fnsForValue =
    options?.filter(i => selectedItems?.includes(i.fileNumber)) || []
  const noOptions =
    inputValue.length > 0 &&
    !options.find(e =>
      e.fileNumber.toLowerCase().includes(inputValue.toLowerCase())
    )
  const isDisplayCreateButton =
    inputValue.toLowerCase() !==
      currentItem?.getFileNumberText().toLowerCase() &&
    (noOptions ||
      (inputValue.length === 6 &&
        !options.find(
          e => e.fileNumber.toLowerCase() === inputValue.toLowerCase()
        )))

  useEffect(() => {
    onRerender(true)
  }, [onRerender])

  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          p: 1
        }}
      >
        <ChevronLeftIcon onClick={onBack} />
        <Typography variant="h6">
          {!selectedItems.length
            ? 'Choose File'
            : `${selectedCurrentItem ? 'Copy' : 'Move'} Email to `}
        </Typography>
      </Stack>
      <Autocomplete
        open
        multiple
        onClose={(_, reason) => {
          if (reason === 'escape') {
            onClose()
          }
        }}
        sx={{px: 2, pb: 1}}
        options={options}
        value={fnsForValue}
        onChange={(event, newValue, reason) => {
          if (
            event.type === 'keydown' &&
            (event as React.KeyboardEvent).key === 'Backspace' &&
            reason === 'removeOption'
          ) {
            return
          }
          setSelectedItems(newValue.map(v => getStringValue(v)))
        }}
        onInputChange={(_, value, reason) =>
          reason !== 'reset' && setInputValue(value)
        }
        inputValue={inputValue}
        PopperComponent={PopperComponent}
        slotProps={{paper: {sx: {boxShadow: 'none', borderRadius: 0}}}}
        ListboxComponent={VirtualizeListboxComponent}
        renderOption={RenderOption()}
        getOptionLabel={option => option.fileNumber}
        renderInput={RenderInput(
          currentItem!.getFileNumberText(),
          selectedCurrentItem,
          unselectCurrentItem
        )}
      />
      <Divider />
      <DialogActions sx={{p: 2}}>
        {isDisplayCreateButton && (
          <Button variant="outlined" onClick={onCreate} disabled={isChecking}>
            Create
          </Button>
        )}
        <Button
          variant="contained"
          disabled={noOptions || !selectedItems.length || isChecking}
          onClick={onDone}
        >
          Done
        </Button>
      </DialogActions>
    </Stack>
  )
})

export default Items

import {makeAutoObservable} from 'mobx'

export default class MocBoard {
  id: number

  itemFileNumbers: ItemFileNumber[]

  constructor(board: MocBoard) {
    this.id = board.id
    this.itemFileNumbers =
      board.itemFileNumbers?.map(i => new ItemFileNumber(i)) || []
    makeAutoObservable(this)
  }
}

export class ItemFileNumber {
  id: number

  fileNumber: string

  constructor(item: ItemFileNumber) {
    this.id = item.id
    this.fileNumber = item.fileNumber
    makeAutoObservable(this)
  }

  setFilenumber = (fileNumber: string) => {
    this.fileNumber = fileNumber
  }
}

import {Button, Card, CardContent, Link, Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useNavigate} from 'react-router-dom'
import {useMainStore} from 'src/context/Main'
import PasswordIcon from 'src/images/ic_password.svg?react'
import CIcon from 'src/images/c.svg?react'
import {PATH_AUTH} from 'src/routes/paths'

const AccessDenied = observer(function AccessDenied() {
  const {loginStore} = useMainStore()

  const navigate = useNavigate()

  const onTryAgainClick = async () => {
    loginStore.init()
    navigate(PATH_AUTH.login)
  }

  return (
    <>
      <Stack
        height={80}
        sx={{
          opacity: 0.8,
          background: 'linear-gradient(135deg, #5BE584 0%, #007B55 100%)'
        }}
        justifyContent="center"
        alignItems="center"
      >
        <CIcon />
      </Stack>
      <Stack
        mt={15}
        mr={2}
        justifyContent="center"
        direction="row"
        alignItems="center"
        color="text.primary"
      >
        <Card sx={{width: 420, height: 400, boxShadow: 'none'}}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <PasswordIcon />
            <Stack alignItems="center" spacing={5}>
              <Typography component="h1" variant="h4">
                User identification problem.
              </Typography>
              <Typography sx={{textAlign: 'center'}} color="GrayText">
                User doesnt exist in the system. please contact your
                administrator:
                <Link
                  ml={1}
                  href={'mailto:ildevadmin@madisoncres.com'}
                  underline="always"
                >
                  ildevadmin@madisoncres.com
                </Link>
              </Typography>
            </Stack>
            <Button
              type="button"
              sx={{marginTop: '10%'}}
              variant="contained"
              fullWidth
              onClick={onTryAgainClick}
            >
              Try again
            </Button>
          </CardContent>
        </Card>
      </Stack>
    </>
  )
})

export default AccessDenied

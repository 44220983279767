import {BlobServiceClient} from '@azure/storage-blob'
import {makeAutoObservable} from 'mobx'
import config from 'src/config'
import {FileType} from 'src/entities/FileType'
import {MainStore} from 'src/store/MainStore'
import {downloadBlob} from 'src/utils/download'
export class AzureStorageStore {
  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
  }

  private generateSas = async (fileType: FileType) => {
    const res = await this.owner.loginStore.fetchWithUser(
      `${config.apiUrl}/Blob/GenerateSAS?fileType=${fileType}`
    )
    return res.text()
  }

  private isExpired = (sas: string) => {
    if (!sas) return true

    const params = new URLSearchParams(sas)
    const sasExpiration = new Date(params.get('se') || '')
    return (
      !sasExpiration ||
      Math.round(
        (new Date(sasExpiration).getTime() - new Date().getTime()) / 60000
      ) < 10
    )
  }

  private getSas = async (fileType: FileType) => {
    let sas = localStorage.getItem(FileType[fileType] + 'sas') || ''
    if (this.isExpired(sas)) {
      sas = await this.generateSas(fileType)
      localStorage.setItem(FileType[fileType] + 'sas', sas)
    }
    return sas
  }

  private getClient = async (fileType: FileType) => {
    const sas = await this.getSas(fileType)
    if (sas) {
      const service = new BlobServiceClient(
        `${config.azureBlobStorage.storageName}?${sas}`
      )
      const containerName =
        fileType === FileType.Email
          ? config.azureBlobStorage.emailContainerName
          : config.azureBlobStorage.invoiceContainerName
      return service.getContainerClient(containerName)
    }
  }

  getBlob = async (filePath: string, fileType: FileType) => {
    const containerClient = await this.getClient(fileType)
    if (containerClient) {
      const blockBlobClient = containerClient.getBlockBlobClient(filePath)

      const downloadBlockBlobResponse = await blockBlobClient.download()
      const blob = await downloadBlockBlobResponse.blobBody
      return blob
    }
  }

  uploadFile = async (file: Blob, path: string, type: string) => {
    try {
      const formdata = new FormData()
      formdata.append('file', file)
      formdata.append('path', path)
      formdata.append('fileType', type)
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Blob`,
        {
          method: 'POST',
          body: formdata
        }
      )
      const data = await response.json()
      if (data) {
        return true
      } else {
        throw new Error('Failed to upload invoice.')
      }
    } catch (error) {
      throw error
    }
  }

  downloadFile = async (
    filePath: string,
    fileType: FileType,
    fileName: string
  ) => {
    var blob = await this.getBlob(filePath, fileType)
    if (blob) {
      downloadBlob(blob, fileName)
    }
  }
}

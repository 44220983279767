import {useLocation, matchPath} from 'react-router-dom'

// ----------------------------------------------------------------------

type ReturnType = {
  active: boolean
  isExternalLink: boolean
}

export default function useActiveLink(
  path: string,
  subPaths?: string[],
  deep = true
): ReturnType {
  const {pathname} = useLocation()

  const paths = [path, ...(subPaths ?? [])]

  const normalActive = paths.some(path =>
    path ? !!matchPath({path, end: true}, pathname) : false
  )

  const deepActive = paths.some(path =>
    path ? !!matchPath({path, end: false}, pathname) : false
  )

  return {
    active: deep ? deepActive : normalActive,
    isExternalLink: path.includes('http')
  }
}

import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridLogicOperator,
  GridValueGetterParams,
  getGridStringOperators
} from '@mui/x-data-grid-pro'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import {useEffect, useState} from 'react'
import HistoryToolbar from './HistoryToolBar'
import {fDateTime} from 'src/utils/formatTime'
import {HistoryTypeId} from 'src/entities/HistoryData'
import {LabelInputComponent} from 'src/components/view/columnTypes/viewHelper'
import DataGridWrapper from 'src/components/custom/DataGridWrapper'

import NoContent from 'src/components/custom/NoContent'
import {Stack} from '@mui/material'
import HistorySkeleton from './HistorySkeleton'
import CuiOverflowTypography from 'src/components/custom/CuiOverflowTypography'

interface HistoryProps {
  isGetData: boolean
}

const options = [
  {id: HistoryTypeId.Fields, text: 'Fields', color: 'warning'},
  {id: HistoryTypeId.Actions, text: 'Actions', color: 'error'}
]
const actionFilterOperator: GridFilterOperator<any, any, any> = {
  label: 'type',
  value: 'type',
  InputComponent: (props: GridFilterInputValueProps) => (
    <LabelInputComponent data={options} {...props} />
  ),
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    return (params: any) => {
      if (
        !filterItem.field ||
        !filterItem.value ||
        !filterItem.operator ||
        filterItem.value.length === 0
      ) {
        return true
      }
      return params.row.historyDataType.typeId === filterItem.value[0].id
    }
  }
}

const columns: GridColDef[] = [
  {
    field: 'user',
    headerName: 'User',
    width: 60,
    headerAlign: 'center',
    type: 'string',
    disableColumnMenu: true,

    valueGetter: (params: GridValueGetterParams) => params.value.fullName,
    sortable: false,
    renderCell: params => (
      <CuiAvatar
        size={30}
        name={params.row?.user.fullName}
        value={params.row.user.initials}
        isHere={params.row.user.isHere}
      />
    )
  },

  {
    field: 'date',
    headerName: 'Date',
    width: 110,
    sortable: true,
    filterable: true,
    type: 'date',
    valueGetter: params => new Date(params.row?.date),
    valueFormatter: params => {
      const cellDate = new Date(params.value as string)
      return fDateTime(cellDate)
    }
  },
  {
    field: 'action',
    headerName: 'Actions/Fields',
    width: 120,
    type: 'string',
    sortable: false,
    valueGetter: params =>
      params.row?.historyDataType.typeId === HistoryTypeId.Fields
        ? params.row?.field
        : params.row?.historyDataType.name,
    filterable: true,
    headerClassName: 'headerCell',
    filterOperators: [...getGridStringOperators(), actionFilterOperator]
  },
  {
    field: 'before',
    headerName: 'Before',
    width: 100,
    type: 'string',
    sortable: false,
    headerAlign: 'center',
    filterable: true,
    renderCell: params => (
      <CuiOverflowTypography
        title={params.row?.before}
        sx={{fontSize: 14}}
        lineClamp={2}
      >
        {params.row?.before}
      </CuiOverflowTypography>
    )
  },
  {
    field: 'after',
    headerName: 'After',
    width: 100,
    type: 'string',
    sortable: false,
    headerAlign: 'center',
    filterable: true,
    renderCell: params => (
      <CuiOverflowTypography
        title={params.row?.after}
        sx={{fontSize: 14}}
        lineClamp={2}
      >
        {params.row?.after}
      </CuiOverflowTypography>
    )
  },
  {
    field: 'additionalInfo',
    headerName: 'Additional Info',
    width: 120,
    headerAlign: 'center',
    sortable: false,
    filterable: true,
    renderCell: params => (
      <CuiOverflowTypography
        lineClamp={2}
        title={params.row?.additionalInfo}
        sx={{fontSize: 14}}
      >
        {params.row?.additionalInfo}
      </CuiOverflowTypography>
    )
  }
]

const History = observer(({isGetData}: HistoryProps) => {
  const mainStore = useMainStore()
  const {historiesItems, isLoading, setHistoryData} = mainStore.historyStore

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  useEffect(() => {
    if (isGetData) setHistoryData()
  }, [isGetData, setHistoryData])

  return (
    <Stack>
      {isLoading ? (
        <HistorySkeleton />
      ) : historiesItems.length === 0 ? (
        <NoContent />
      ) : (
        <DataGridWrapper
          columns={columns}
          rows={historiesItems}
          hideFooter
          getRowHeight={() => 'auto'}
          filterModel={filterModel}
          disableColumnMenu
          onFilterModelChange={(newValue: GridFilterModel) => {
            setFilterModel(newValue)
          }}
          slots={{
            toolbar: HistoryToolbar
          }}
          slotProps={{
            panel: {
              anchorEl: filterButtonEl
            },
            baseSelect: {native: false, defaultValue: ''},
            toolbar: {
              setFilterButtonEl: setFilterButtonEl,
              setFilterModel: setFilterModel
            },
            filterPanel: {
              filterFormProps: {
                // Customize inputs by passing props
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small'
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: {mt: 'auto'}
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: {mt: 'auto'}
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small'
                  }
                }
              },
              sx: {
                '& .MuiDataGrid-panelFooter': {
                  justifyContent: 'flex-start',
                  px: 2.5
                },
                '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                  color: (theme: any) => theme.palette.primary.main,
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 171, 85, 0.08)'
                  }
                },
                '& .MuiDataGrid-filterForm': {p: 2},
                '& .MuiDataGrid-filterFormLogicOperatorInput': {
                  mr: 1,
                  width: 75
                },
                '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                '& .MuiDataGrid-filterFormOperatorInput': {mr: 1, width: 150},
                '& .MuiDataGrid-filterFormValueInput': {width: 280},
                '& .MuiDataGrid-filterFormDeleteIcon': {
                  justifyContent: 'center'
                }
              }
            }
          }}
        />
      )}
    </Stack>
  )
})
export default History

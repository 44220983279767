import {observer} from 'mobx-react-lite'
import Column, {ColumnData} from 'src/entities/Column'
import Item from 'src/entities/Item'
import {PermissionType} from 'src/entities/PermissionData'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import LabelCol from 'src/components/view/columnTypes/LabelCol'
import {useMainStore} from 'src/context/Main'
import Dropdown, {DropdownMenuItem} from 'src/components/custom/CuiDropdown'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import StatusAssignee from 'src/entities/StatusAssignee'
import CuiWhiteTooltip from '../../custom/CuiWhiteTooltip'
import {useState} from 'react'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import CuiAvatar from 'src/components/custom/CuiAvatar'

interface StatusProps {
  column: Column
  item: Item
  permissionType: PermissionType
}

interface SubRoleMenuItemProps {
  currentStatusAssignee: StatusAssignee
  currentColumnData: ColumnData
  forceClose: boolean
  setForceClose: React.Dispatch<React.SetStateAction<boolean>>
}

interface CommentDialogProps {
  open: boolean
  title: string
  onClose: () => void
  onSubmit: () => void
  comment: string | undefined
  setComment: React.Dispatch<React.SetStateAction<string | undefined>>
  label: string
}

const CommentDialog = ({
  open,
  title,
  onClose,
  onSubmit,
  comment,
  setComment,
  label
}: CommentDialogProps) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={label}
            fullWidth
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={'contained'} onClick={onSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const SubRoleMenuItem = ({
  currentStatusAssignee,
  currentColumnData,
  forceClose,
  setForceClose
}: SubRoleMenuItemProps) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [comment, setComment] = useState<string | undefined>(
    currentStatusAssignee.comment
  )
  const {
    boardStore: {setStatusAssignee}
  } = useMainStore()

  const data = JSON.parse(currentColumnData.data)

  const handleDialogSubmit = () => {
    setOpenDialog(false)
    setForceClose(!forceClose)
    setStatusAssignee(
      currentStatusAssignee,
      currentStatusAssignee.statusId,
      parseInt(currentColumnData.columnValue),
      currentStatusAssignee.subStatuses,
      false,
      comment
    )
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
    setForceClose(!forceClose)
  }

  const onClick = () => {
    if (currentColumnData.commentText !== null) {
      setOpenDialog(true)
    } else {
      setForceClose(!forceClose)
      setStatusAssignee(
        currentStatusAssignee,
        currentStatusAssignee.statusId,
        parseInt(currentColumnData.columnValue),
        currentStatusAssignee.subStatuses,
        false,
        undefined
      )
    }
  }

  return (
    <>
      <DropdownMenuItem
        onClick={() => {
          onClick()
        }}
      >
        <LabelCol
          pointer
          text={data.text ?? ''}
          color={data.color ?? 'primary'}
        />
      </DropdownMenuItem>
      <CommentDialog
        open={openDialog}
        title={currentColumnData.commentTitle || 'Add Your Comment'}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        comment={comment}
        setComment={setComment}
        label={currentColumnData.commentText || ''}
      />
    </>
  )
}

const SubRoleStatus = observer(
  ({column, item, permissionType}: StatusProps) => {
    const {
      boardStore: {currentBoard}
    } = useMainStore()

    const [forceClose, setForceClose] = useState(false)

    const isReadOnly = permissionType === PermissionType.Readonly

    var subRoles = currentBoard?.roles.filter(
      r => r.parentRole?.id === column.roleId
    )
    var relevantStatusAssignees = item.statusAssignees.filter(
      s => subRoles?.some(r => r.id === s.roleId)
    )

    if (relevantStatusAssignees.length === 0) return <></>

    return (
      <Stack>
        <Typography sx={{fontSize: 14, fontWeight: 600}}>
          {column.name}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            border: 1,
            borderColor: theme => theme.palette.grey[500]
          }}
        >
          <Table size="small">
            <TableBody>
              {relevantStatusAssignees.map(s => {
                const currentColumnData = column.data?.find(
                  d => d.columnValue === s.statusId?.toString()
                )
                const currentData = currentColumnData
                  ? JSON.parse(currentColumnData!.data!)
                  : undefined
                return (
                  <TableRow
                    key={s.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {subRoles?.find(r => r.id === s.roleId)?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <CuiAvatar
                        name={s.user?.fullName}
                        value={s.user?.initials}
                        size={25}
                        isHere={s.user?.isHere}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Dropdown
                        trigger={
                          <Button
                            disabled={isReadOnly}
                            sx={{
                              flexDirection: 'column',
                              borderColor: theme => theme.palette.grey[500],
                              p: 0.5
                            }}
                          >
                            <Stack
                              width="150px"
                              direction="row"
                              justifyContent="space-between"
                              alignItems={'center'}
                            >
                              <LabelCol
                                pointer
                                text={currentData?.text ?? ''}
                                color={currentData?.color ?? 'primary'}
                              />
                              {currentColumnData?.commentText && s.comment && (
                                <CuiWhiteTooltip
                                  title={
                                    currentColumnData?.commentText +
                                    ' ' +
                                    s.comment
                                  }
                                >
                                  <ChatOutlinedIcon
                                    sx={{
                                      color: 'grey.500',
                                      pointerEvents: 'auto'
                                    }}
                                  />
                                </CuiWhiteTooltip>
                              )}
                              <ArrowDownIcon sx={{color: 'grey.500'}} />
                            </Stack>
                          </Button>
                        }
                        menu={column.data!.map(d => {
                          return (
                            <SubRoleMenuItem
                              key={currentColumnData?.id}
                              currentStatusAssignee={s}
                              currentColumnData={d}
                              forceClose={forceClose}
                              setForceClose={setForceClose}
                            />
                          )
                        })}
                        forceClose={forceClose}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    )
  }
)
export default SubRoleStatus

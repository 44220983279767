import {
  IconButton,
  Dialog,
  DialogTitle,
  Stack /*, Typography*/
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useState} from 'react'
import InfoPopupTabs from 'src/components/infoPopup/InfoPopupTabs'
import Item from 'src/entities/Item'
import InfoIcon from 'src/images/info.svg?react'
import OpenItemView from 'src/components/view/columnTypes/OpenItemView'

export interface InfoPopupProps {
  item: Item
}
const InfoPopup = observer(({item}: InfoPopupProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() => {
              setOpen(true)
            }}
          >
            <InfoIcon />
          </IconButton>

          {/* <Typography
            sx={{color: theme => theme.palette.primary.main}}
            fontSize="11px"
            fontWeight="700"
          >
            4
          </Typography> */}
        </Stack>
      </Stack>
      <Dialog
        fullWidth
        maxWidth="md"
        PaperProps={{sx: {height: '100%', display: 'flex'}}}
        sx={{height: '80%', m: 'auto'}}
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogTitle>
          <OpenItemView item={item} value={item.getFullNumberText()} />
        </DialogTitle>
        <InfoPopupTabs item={item} />
      </Dialog>
    </>
  )
})
export default InfoPopup

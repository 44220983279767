import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {Suspense, useEffect, useMemo} from 'react'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import {Stack} from '@mui/material'
import {
  ConversationControl,
  ConversationCountEvent,
  ConversationCountDetails
} from '@madisoncres/title-general-package'
import PermissionData, {
  Control,
  PermissionType
} from 'src/entities/PermissionData'
import config from 'src/config'
import {ViewType} from 'src/entities/View'
import loadable from '@loadable/component'
import {moveObjectToStart} from 'src/utils/array'

const ConversationList = loadable(() => import('cbChat/ConversationList'))

const preparePermissions = (permissions: PermissionData[]) => {
  const newPermission = {
    [ConversationControl.Assign]: PermissionType.Readonly
  } as Record<ConversationControl, PermissionType>
  permissions.forEach(p => {
    switch (p.controlId) {
      case Control.Create:
        newPermission[ConversationControl.Create] = p.permissionTypeId
        return
      case Control.Filter:
        newPermission[ConversationControl.Filter] = p.permissionTypeId
        return
      case Control.Reply:
        newPermission[ConversationControl.Reply] = p.permissionTypeId
        return
      case Control.SeeAll:
        newPermission[ConversationControl.SeeAll] = p.permissionTypeId
        return
      case Control.Status:
        newPermission[ConversationControl.Status] = p.permissionTypeId
        return
    }
  })
  return newPermission
}

interface QuestionListProps {
  viewId: number
}

const QuestionList = observer(({viewId}: QuestionListProps) => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {currentItem, myRolesId} = mainStore.itemStore
  const {getPermissionTypesByView} = mainStore.permissionStore

  const additionalInfo = useMemo(
    () => ({
      fileNumber: currentItem?.order?.fileNumber,
      boardId: currentBoard?.id
    }),
    [currentBoard?.id, currentItem?.order?.fileNumber]
  )

  const permissions = useMemo(
    () => preparePermissions(getPermissionTypesByView(myRolesId, viewId)),
    [getPermissionTypesByView, myRolesId, viewId]
  )

  const users = useMemo(() => {
    const users = currentBoard?.members?.map(m => m.user)
    const defaultRoleUser = currentItem?.getStatusAssigneeByRole(
      currentBoard?.defaultRole?.id!
    )[0]?.user
    const sortedUsers = moveObjectToStart(users || [], defaultRoleUser)
    return sortedUsers
  }, [currentBoard?.members, currentBoard?.defaultRole?.id, currentItem])

  return (
    <ConversationList
      viewId={ViewType[ViewType.Question]}
      entityId={currentItem?.id}
      entityTypeId={config.itemEntityTypeId}
      conversationTypeId={config.questionConversationType}
      additionalInfo={additionalInfo}
      permissions={permissions}
      users={users}
    />
  )
})

interface QuestionProps {
  viewId: number
  onCountChange: (count: number) => void
  onUnreadCountChange: (count: number) => void
}

const Question = observer(
  ({viewId, onCountChange, onUnreadCountChange}: QuestionProps) => {
    const {currentItem} = useMainStore().itemStore

    useEffect(() => {
      const callback = (e: CustomEvent<ConversationCountDetails>) => {
        const details = e.detail
        if (
          details.entityId === currentItem?.id &&
          details.entityTypeId === config.itemEntityTypeId &&
          details.conversationTypeId === config.questionConversationType
        ) {
          onCountChange(details.count)
          onUnreadCountChange(details.unreadCount)
        }
      }

      ConversationCountEvent.subscribe(callback)
      return () => ConversationCountEvent.unsubscribe(callback)
    }, [currentItem?.id, onCountChange, onUnreadCountChange])

    return (
      <Stack>
        <CuiErrorBoundary>
          <Suspense fallback={<></>}>
            <QuestionList viewId={viewId} />
          </Suspense>
        </CuiErrorBoundary>
      </Stack>
    )
  }
)

export default Question

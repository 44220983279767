export function getEnumOption(type: any, filterArr?: number[]) {
  return Object.keys(type)
    .filter(key => {
      return !filterArr || filterArr?.includes(Number(key))
    })
    .reduce((prev, dpType) => {
      const resNumber = Number(dpType)
      if (!isNaN(resNumber) && resNumber) {
        prev.push(resNumber as any)
      }
      return prev
    }, [] as number[])
}

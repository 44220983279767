import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import SelectSingleField from 'src/components/select-app/favoriteFields/SelectFieldSingle'
import Column from 'src/entities/Column'

interface SelectFieldsListProps {
  columns: Column[]
  onRemoveColumn: (columnId: number) => void
}

const SelectFieldsList = ({columns, onRemoveColumn}: SelectFieldsListProps) => {
  return (
    <Stack
      direction="row"
      justifyContent={'flex-start'}
      sx={{useFlexGap: 'true', flexWrap: 'wrap', gap: 1}}
    >
      {columns.map(c => {
        return (
          <SelectSingleField key={c.id} column={c} onRemove={onRemoveColumn} />
        )
      })}
    </Stack>
  )
}

export default observer(SelectFieldsList)

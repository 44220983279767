import {Paper, Typography, Stack, IconButton} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import Column, {ColumnType} from 'src/entities/Column'
import {fDate, fDateTime} from 'src/utils/formatTime'
import CloseIcon from '@mui/icons-material/Close'

const getDisplayValue = (value: string, column: Column) => {
  switch (column.type) {
    case ColumnType.Date:
      return value ? fDate(value) : ''
    case ColumnType.DateTime:
      return value ? fDateTime(value) : ''
    case ColumnType.Text:
    case ColumnType.BoldText:
      return value ? value : ''
    default:
      return JSON.parse(
        column.data?.find(cd => cd.columnValue === value)?.data ?? '{}'
      ).text
  }
}

export interface SelectFieldSingleProps {
  column: Column
  onRemove: (columnId: number) => void
}

const SelectFieldSingle = observer(
  ({column, onRemove}: SelectFieldSingleProps) => {
    const mainStore = useMainStore()
    const {currentItem} = mainStore.itemStore

    const onCloseClick = () => {
      onRemove(column.id)
    }

    return (
      <Paper
        sx={{
          p: 1,
          width: 160,
          height: 52,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'grey.300'
        }}
        elevation={12}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography sx={{fontSize: 14, fontWeight: 600}}>
              {column.name}
            </Typography>
            <Typography sx={{fontSize: 12, fontWeight: 400}}>
              {getDisplayValue(
                currentItem
                  ?.getValue(column.dbTableId, column.dbColumn)
                  ?.toString() ?? '',
                column
              )}
            </Typography>
          </Stack>
          <IconButton onClick={onCloseClick} sx={{p: 0, alignSelf: 'start'}}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Paper>
    )
  }
)
export default SelectFieldSingle

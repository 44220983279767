import {Stack, Divider} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {NAV} from 'src/config'
import {hideScrollbarX} from 'src/utils/cssStyles'
import DoubleArrowLeft from 'src/images/double_arrow_left.svg?react'
import DoubleArrowRight from 'src/images/double_arrow_right.svg?react'
import IconButton from '@mui/material/IconButton'
import MenuList from 'src/components/itemView/MenuList'
import MenuItemData from 'src/components/itemView/MenuItemData'
import MenuStatusTimeline from 'src/components/itemView/MenuStatusTimeline'

interface MenuProps {
  open: boolean
  setOpen: (open: boolean | ((prevSelectedItems: boolean) => boolean)) => void
}

interface ExpandMenuButtonProps {
  isOpen: boolean
}

const Menu = observer(({open, setOpen}: MenuProps) => {
  const ExpandMenuButton = ({isOpen}: ExpandMenuButtonProps) => {
    return (
      <Stack>
        <IconButton
          onClick={() => setOpen(prev => !prev)}
          sx={{
            width: open ? '20%' : '100%',
            p: 0,
            top: 10
          }}
        >
          {isOpen ? <DoubleArrowLeft /> : <DoubleArrowRight />}
        </IconButton>{' '}
        <Divider sx={{mt: 1, borderStyle: 'dashed'}} />
      </Stack>
    )
  }

  return (
    <Stack
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: open ? NAV.W_ITEM : NAV.W_ITEM_CLOSED
      }}
    >
      <Stack
        sx={{
          height: '100%',
          background: theme => theme.palette.grey[800],
          pb: 1,
          width: open ? NAV.W_ITEM : NAV.W_ITEM_CLOSED,
          borderRight: theme => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX
        }}
      >
        {open ? (
          <Stack height="100vh">
            <ExpandMenuButton isOpen={true} />
            <Stack height="100%" justifyContent={'space-between'}>
              <MenuList />
              <Stack spacing={2} pb={2} alignItems={'center'}>
                <MenuItemData />
                <MenuStatusTimeline />
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <ExpandMenuButton isOpen={false} />
        )}
      </Stack>
    </Stack>
  )
})

export default Menu

import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import UserSettings, {UserSettingKey} from 'src/entities/UserSettings'
import {MainStore} from 'src/store/MainStore'
import {useDebouncedCallback} from 'use-debounce'

export default class UserSettingsStore {
  userSettings: UserSettings[] = []

  isLoading: boolean = false

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      isFirstLoggedin => {
        if (isFirstLoggedin) this.getUserSettings()
      }
    )

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )
  }

  setUserSettings = (userSettings: UserSettings[]) => {
    this.userSettings.push(...userSettings)
  }

  getUserSettingsByKey = (userSettingsKey: UserSettingKey, viewId?: number) => {
    return this.userSettings.find(
      us => us.userSettingKey === userSettingsKey && us.viewId === viewId
    )
  }

  getUserSettings = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/user/settings`)
      .then(res => res.json())
      .then((data: UserSettings[]) =>
        this.setUserSettings(data.map(d => new UserSettings(d)))
      )
  }

  updateUserSettingsDB = useDebouncedCallback(async () => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/user/settings`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.userSettings)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to save userSettings')
      }
    } catch (error) {
      this.owner.setErrorMessage('Failed to save settings')
    }
  }, 5000)

  updateUserSettings = async (
    newValue: any,
    userSettingKey: UserSettingKey,
    viewId?: number
  ) => {
    let userSettings = this.getUserSettingsByKey(userSettingKey, viewId)
    const prevValue = userSettings?.value
    if (prevValue === JSON.stringify(newValue)) return
    if (!userSettings) {
      userSettings = new UserSettings({
        userId: this.owner.loginStore.user?.id!,
        userSettingKey: userSettingKey,
        viewId: viewId
      } as UserSettings)
      this.userSettings.push(userSettings)
    }
    userSettings.setValue(JSON.stringify(newValue))
    this.updateUserSettingsDB()
  }

  removeLastSettings = () => {
    this.userSettings.pop()
  }

  private listener = () => {
    this.owner.signalrStore.on(
      'AddOrUpdateUserSettings',
      (userSetting: UserSettings) => {
        const oldUserSettings = this.userSettings.find(
          g =>
            g.id === userSetting.id ||
            (!g.id &&
              g.userSettingKey === userSetting.userSettingKey &&
              g.viewId === (userSetting.viewId ?? undefined))
        )
        if (oldUserSettings) {
          oldUserSettings.setValue(userSetting.value)
          if (!oldUserSettings.id) oldUserSettings.setId(userSetting.id)
        } else {
          this.setUserSettings([
            ...this.userSettings,
            new UserSettings(userSetting)
          ])
        }
      }
    )

    this.owner.signalrStore.on('UpdateImHere', (isHere: boolean) => {
      this.owner.loginStore.user?.setIsHere(isHere)
    })
  }
}

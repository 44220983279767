import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import Column, {ColumnType, Direction} from 'src/entities/Column'
import ViewGroup, {inboundGroupName} from 'src/entities/ViewGroup'

export enum ViewSettingsType {
  Filter = 1,
  ColumnSettings,
  Sort
}

export enum ViewType {
  Table = 1,
  Invoices,
  Select,
  SelectFavorite,
  FileData,
  Question,
  Header,
  Footer,
  Info,
  MailBox,
  Documents,
  Proofing,
  Chat,
  History,
  Assign,
  Report,
  Property,
  GeneralInfo
}

export enum UsingType {
  Tab = 1,
  Item,
  App,
  Info,
  ItemMenu
}

export default class View implements Base {
  id: number

  name: string

  color: string

  viewGroups: ViewGroup[]

  columns?: Column[]

  viewType: ViewType

  usingType: UsingType

  count?: number

  isAssignedOnly: boolean

  constructor(view: View) {
    this.id = view.id
    this.name = view.name
    this.color = view.color
    this.viewGroups = view.viewGroups.map(g => new ViewGroup(g))
    this.viewType = view.viewType
    this.usingType = view.usingType
    this.columns = view.columns?.map(c => new Column(c))
    this.count = view.count
    this.isAssignedOnly = view.isAssignedOnly
    makeAutoObservable(this)
  }

  get assigneeColumns() {
    return this.columns?.filter(
      c => c.type === ColumnType.Assignee || c.type === ColumnType.UserAssignee
    )
  }

  get visibleViewGroups() {
    return this.viewGroups.filter(g => g.isVisible)
  }

  get leftDirectionPinnedColumns() {
    return this.columns?.filter(c => c.pinnedDirection === Direction.Left)
  }

  get rightDirectionPinnedColumns() {
    return this.columns?.filter(c => c.pinnedDirection === Direction.Right)
  }

  setInboundGroup = () => {
    return this.viewGroups.forEach((i: ViewGroup) => {
      if (i.name === inboundGroupName) i.isVisible = !i.isVisible
    })
  }

  get isInboundVisible() {
    return this.viewGroups.find(g => g.name === inboundGroupName)?.isVisible
  }
}

import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'

export default class Update implements Base {
  id: number

  text: string

  createdBy: number

  createdAt: Date

  itemId: number

  constructor(update: Update) {
    this.id = update.id
    this.text = update.text
    this.createdBy = update.createdBy
    this.createdAt = update.createdAt
    this.itemId = update.itemId
    makeAutoObservable(this)
  }
}

import {useTheme} from '@mui/material/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'

export interface CheckCircleIconColProps {
  value: boolean
}

export default function CheckCircleIconCol({value}: CheckCircleIconColProps) {
  const theme = useTheme()

  if (!value) return <></>

  return <CheckCircleIcon style={{color: theme.palette.primary.main}} />
}

import {format, getTime, formatDistanceToNow} from 'date-fns'

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MMM dd yyyy'

  return date ? format(new Date(date), fm) : ''
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MMM dd yyyy p'

  return date ? format(new Date(date), fm) : ''
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : ''
}

export function fDateTimestamp(date?: Date) {
  return date ? format(new Date(date), 'MMM dd yyyy | hh:mm a') : ''
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true
      })
    : ''
}

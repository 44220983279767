import {IconButton, Stack, Typography, TextField, Button} from '@mui/material'
import {observer} from 'mobx-react-lite'
import React, {useEffect, useState} from 'react'
import Item from 'src/entities/Item'
import CommentIcon from '@mui/icons-material/SmsOutlined'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import Popover from '@mui/material/Popover'
import {useMainStore} from 'src/context/Main'
import {fDateTimestamp} from 'src/utils/formatTime'
import Update from 'src/entities/Update'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'

export interface CommentPopupProps {
  item: Item
}

interface PopoverProps {
  onClose: () => void
  anchorEl: HTMLElement | null
  updates: Update[]
  itemId: number
}

const PopoverComponent = observer(
  ({onClose, anchorEl, updates, itemId}: PopoverProps) => {
    const {currentBoard, addItemUpdate} = useMainStore().boardStore
    const [text, setText] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      setLoading(false)
    }, [updates.length])

    return (
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => onClose()}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'right'}}
        slotProps={{
          paper: {
            sx: {
              p: 3,
              width: 500,
              minHeight: 200,
              overflow: 'hidden',
              ml: 0.75,
              '& .MuiMenuItem-root': {
                px: 1,
                borderRadius: 0.75
              }
            }
          }
        }}
      >
        <Typography fontSize="18px" fontWeight={700} mb={4}>
          Updates
        </Typography>
        {updates.map(u => {
          const user = currentBoard?.getUserMember(u.createdBy)
          return (
            <Stack spacing={2} key={u.id} mb={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="start"
                  alignItems="center"
                >
                  <CuiAvatar
                    name={user?.fullName}
                    value={user?.initials}
                    size={30}
                    isHere={user?.isHere}
                  />
                  <Typography variant="body1">
                    {`${user?.firstName} ${user?.lastName}`}
                  </Typography>
                </Stack>
                <Typography
                  variant="caption"
                  color="text.disabled"
                  fontSize={14}
                >
                  {fDateTimestamp(u.createdAt)}
                </Typography>
              </Stack>
              <Typography color="GrayText" variant="body2">
                {u.text}
              </Typography>
            </Stack>
          )
        })}
        <Stack spacing={2} mt={2}>
          <TextField
            placeholder="Write a comment..."
            value={text}
            onChange={e => {
              setText(e.target.value)
            }}
          />
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <CuiProgressButton
              variant="contained"
              onClick={() => {
                addItemUpdate(text, itemId)
                setText('')
                setLoading(true)
              }}
              loading={loading}
            >
              Submit
            </CuiProgressButton>
          </Stack>
        </Stack>
      </Popover>
    )
  }
)

const UpdateCol = observer(({item}: CommentPopupProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onIconClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClosePopover = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={onIconClick}>
        <CommentIcon />
      </IconButton>
      {anchorEl && (
        <PopoverComponent
          anchorEl={anchorEl}
          onClose={onClosePopover}
          updates={item.updates || []}
          itemId={item.id}
        />
      )}
    </>
  )
})
export default UpdateCol

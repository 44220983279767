import {useState, useEffect, useMemo, useCallback} from 'react'
import {
  GridFilterModel,
  GridPreferencePanelsValue,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
  GridColDef,
  GridCellParams,
  GridApiPro,
  GridColumnsInitialState,
  GridSortingInitialState,
  GridCallbackDetails
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import User from 'src/entities/User'
import StatusAssignee from 'src/entities/StatusAssignee'
import View from 'src/entities/View'
import DataGridWrapper from 'src/components/custom/DataGridWrapper'
import ViewGroup from 'src/entities/ViewGroup'
import Item from 'src/entities/Item'
import {computed} from 'mobx'
import {UserSettingKey} from 'src/entities/UserSettings'
import {getUniqueDuplicates} from 'src/utils/array'

export interface GroupItemsProps {
  view?: View
  group: ViewGroup
  getGroupItems: (group: ViewGroup) => Item[] | undefined
  columns: GridColDef[]
  filterModel?: GridFilterModel
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>
  isExternalFilter?: boolean
  assigneeFilter?: User | undefined
  setAssigneeFilter?: React.Dispatch<React.SetStateAction<User | undefined>>
  hasToolbar: boolean
  Toolbar?: React.JSXElementConstructor<any> | null
  FilterPanel?: React.JSXElementConstructor<any>
  hiddenColumns?: string[]
  setGroupGridpiRef?: (groupId: number, apiRef: GridApiPro) => void
  setNumOfResults: (groupId: number, numOfResults: number) => void
  isLoadingBoardItems: boolean
}

const GroupItems = observer(
  ({
    view,
    group,
    getGroupItems,
    columns,
    filterModel,
    setFilterModel,
    isExternalFilter,
    assigneeFilter,
    setAssigneeFilter,
    hasToolbar,
    Toolbar,
    FilterPanel,
    hiddenColumns,
    setGroupGridpiRef,
    setNumOfResults,
    isLoadingBoardItems
  }: GroupItemsProps) => {
    const {
      userSettingsStore: {updateUserSettings, getUserSettingsByKey}
    } = useMainStore()
    const [filterButtonEl, setFilterButtonEl] =
      useState<HTMLButtonElement | null>(null)

    const updateColumnsSettings = () => {
      updateUserSettings(
        apiRef.current.exportState().columns || {},
        UserSettingKey.ColumnSettings,
        view?.id
      )
    }

    const updateColumnsSorting = () => {
      updateUserSettings(
        apiRef.current.exportState().sorting || {},
        UserSettingKey.ColumnSort,
        view?.id
      )
    }

    const setQuickFilter = useCallback(
      (model: GridFilterModel) => {
        setFilterModel?.(prev => {
          if (prev.quickFilterValues !== model.quickFilterValues)
            return {...prev, quickFilterValues: model.quickFilterValues}
          return prev
        })
      },
      [setFilterModel]
    )

    const filteredRows = useMemo(() => {
      return computed(() => {
        const rows = getGroupItems(group) as any[]

        if (!assigneeFilter) return rows

        return rows?.filter(
          r =>
            view?.assigneeColumns?.find(
              c =>
                r[c.id]?.find(
                  (sa: StatusAssignee) => sa.userId === assigneeFilter.id
                )
            )
        )
      })
    }, [assigneeFilter, view?.assigneeColumns, getGroupItems, group]).get()

    const apiRef = useGridApiRef()

    useEffect(() => {
      setGroupGridpiRef?.(group.id, apiRef.current)
    }, [group.id, apiRef, setGroupGridpiRef])

    useEffect(() => {
      if (isLoadingBoardItems) return
      // Check this together in case there is no match item to filter and a new match item was inserted by signlr.
      const mergedList = [
        ...filteredRows.map(fr => fr.id),
        ...gridFilteredSortedRowIdsSelector(apiRef)
      ]
      const result = getUniqueDuplicates(mergedList)
      setNumOfResults(group.id, result.length)
    }, [
      group.id,
      apiRef,
      filterModel,
      filteredRows,
      isLoadingBoardItems,
      setNumOfResults
    ])

    const columnSettings = getUserSettingsByKey(
      UserSettingKey.ColumnSettings,
      view?.id
    )?.value

    const columnSorting = getUserSettingsByKey(
      UserSettingKey.ColumnSort,
      view?.id
    )?.value

    useEffect(() => {
      if (
        columnSettings &&
        JSON.parse(columnSettings) !== apiRef.current.exportState().columns
      )
        apiRef.current.restoreState({
          ...apiRef.current.exportState(),
          columns: JSON.parse(columnSettings) as GridColumnsInitialState
        })
      if (
        columnSorting &&
        JSON.parse(columnSorting) !== apiRef.current.exportState().sorting
      )
        apiRef.current.restoreState({
          ...apiRef.current.exportState(),
          sorting: JSON.parse(columnSorting) as GridSortingInitialState
        })
    }, [apiRef, columnSettings, columnSorting])

    const getTogglableColumns = (columns: GridColDef[]) => {
      return columns.filter(c => c.headerName).map(c => c.field)
    }

    const onFilterModelChange = (
      newValue: GridFilterModel,
      details: GridCallbackDetails<'filter'>
    ) => {
      // remove blank filter item - get it after was value in filter and deleted

      const filterValues = {
        ...newValue,
        items: newValue.items.filter(item => item.value !== '')
      } as GridFilterModel

      if (!isExternalFilter) {
        if (setFilterModel) setFilterModel(filterValues)
        if (details.reason === 'deleteFilterItem')
          apiRef.current.showFilterPanel()
      } else setQuickFilter(filterValues)
    }

    return (
      <DataGridWrapper
        columnBuffer={columns.length + 1}
        rowBuffer={filteredRows.length > 9 ? 9 : filteredRows.length}
        apiRef={apiRef}
        disableRowSelectionOnClick
        columns={columns}
        rows={filteredRows}
        initialState={{
          pinnedColumns: {
            left: view?.leftDirectionPinnedColumns?.map(c => c.id.toString()),
            right: view?.rightDirectionPinnedColumns?.map(c => c.id.toString())
          },
          columns: {
            columnVisibilityModel: hiddenColumns?.reduce(
              (acc, item) => ((acc[item] = false), acc),
              {} as Record<string, boolean>
            )
          }
        }}
        hideFooter
        filterModel={filterModel}
        onColumnVisibilityModelChange={updateColumnsSettings}
        onColumnOrderChange={updateColumnsSettings}
        onColumnWidthChange={updateColumnsSettings}
        onSortModelChange={updateColumnsSorting}
        isCellEditable={(params: GridCellParams) =>
          params.row.isPropertyEditable(params.colDef.headerName)
        }
        onFilterModelChange={onFilterModelChange}
        slots={{
          toolbar: hasToolbar ? Toolbar : undefined,
          filterPanel: FilterPanel,
          columnHeaderFilterIconButton: () => <></>
        }}
        slotProps={{
          panel: {
            anchorEl: filterButtonEl
          },
          toolbar: {
            setFilterButtonEl: setFilterButtonEl,
            onOpenColumnVisibilityPanel: () =>
              apiRef.current?.showPreferences(
                GridPreferencePanelsValue.columns
              ),
            assigneeFilter: assigneeFilter,
            setAssigneeFilter: setAssigneeFilter,
            filterModel: filterModel,
            setFilterModel: setFilterModel
          },
          filterPanel: {
            filterModel: filterModel,
            saveFilters: () => {
              updateUserSettings(
                {
                  ...filterModel,
                  quickFilterValues: undefined
                } as GridFilterModel,
                UserSettingKey.Filters,
                view?.id
              )
              apiRef.current.hideFilterPanel()
            }
          },
          baseSelect: {native: false, defaultValue: ''},
          columnsPanel: {getTogglableColumns}
        }}
        localeText={{
          columnsPanelTextFieldLabel: 'Find columns to display',
          columnsPanelTextFieldPlaceholder: 'Search column'
        }}
      />
    )
  }
)

export default GroupItems

import {Stack, ListItemText} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import View, {ViewType} from 'src/entities/View'
import {
  StyledDotIcon,
  StyledIcon,
  StyledItem
} from 'src/components/nav-section/styles'
import Iconify from 'src/components/iconify'
import {useSearchParams} from 'react-router-dom'

const MenuList = observer(() => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {currentView, setCurrentView} = mainStore.itemStore
  const [, setSearchParams] = useSearchParams()

  const onChooseView = (view: View) => {
    setCurrentView(view)
    setSearchParams(prev => {
      prev.set('view', ViewType[view.viewType])
      prev.delete('viewEntityId')
      return prev
    })
  }

  const itemMenuViews = currentBoard?.itemMenuViews.filter(
    v =>
      v.viewType !== ViewType.FileData &&
      v.viewType !== ViewType.Property &&
      v.viewType !== ViewType.Select
  )

  return (
    <Stack>
      {itemMenuViews?.map(v => (
        <StyledItem
          key={v.id}
          active={v.id === currentView?.id}
          depth={1}
          onClick={() => onChooseView(v)}
        >
          <StyledIcon>
            <StyledDotIcon active={v.id === currentView?.id} />
          </StyledIcon>
          <ListItemText primary={v.name} />
          {v.id === currentView?.id && (
            <Iconify
              width={16}
              icon="eva:chevron-right-fill"
              color="primary"
              sx={{
                right: 16,
                position: 'absolute'
              }}
            />
          )}
        </StyledItem>
      ))}
    </Stack>
  )
})

export default MenuList

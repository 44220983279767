import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'

// ----------------------------------------------------------------------

export default function ChecklistTableHead() {
  const headLabels = [
    'Status',
    'Task',
    'Assign To',
    'Due Date',
    'Completed By',
    'Completed Date'
  ]

  return (
    <TableHead>
      <TableRow>
        {headLabels.map(c => (
          <TableCell sx={{verticalAlign: 'top'}} align="left" key={c}>
            {c}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

import React, {Suspense, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {Box, Stack, Tab, Tabs} from '@mui/material'
import CuiResizable from 'src/components/custom/CuiResizable'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import FavoriteSelectField from 'src/components/select-app/favoriteFields/FavoriteSelectField'
import MailBoxPreview from 'src/components/itemView/MailBoxPreview'
import loadable from '@loadable/component'
import MocModal from 'src/components/moc/MocModal'
import {
  MailboxMocClickEvent,
  MailboxNavigateEntityEvent,
  NavigateEntityDetails,
  OnMocClickDetails
} from '@madisoncres/title-general-package'
import {generatePath, useNavigate} from 'react-router-dom'
import {PATH_WORKFLOW} from 'src/routes/paths'

const MailboxView = loadable(() => import('cbMailbox/Mailbox'))

interface MailboxProps {}

const Mailbox = observer(({}: MailboxProps) => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {departments} = mainStore.departmentStore
  const {currentItem} = mainStore.itemStore
  const [tab, setTab] = useState(0)
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const navigate = useNavigate()

  const onCloseMoc = () => {
    setAnchorEl(undefined)
  }

  useEffect(() => {
    const callback = (e: CustomEvent<NavigateEntityDetails>) => {
      if (e.detail.id) {
        navigate(
          generatePath(PATH_WORKFLOW.items, {
            boardId: currentBoard?.id,
            itemId: e.detail.id
          })
        )
      }
    }
    MailboxNavigateEntityEvent.subscribe(callback)
    return () => MailboxNavigateEntityEvent.unsubscribe(callback)
  }, [navigate, currentBoard?.id])

  useEffect(() => {
    const callback = (e: CustomEvent<OnMocClickDetails>) => {
      setAnchorEl(e.detail.anchor)
    }
    MailboxMocClickEvent.subscribe(callback)
    return () => MailboxMocClickEvent.unsubscribe(callback)
  }, [])

  return (
    <Stack sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
      <CuiResizable
        minWidth="30%"
        maxWidth="90%"
        enable={{right: true}}
        style={{height: '100%', flex: `0 0 65%`, borderRight: 1}}
      >
        <CuiErrorBoundary>
          <Suspense fallback={<></>}>
            <MailboxView />
          </Suspense>
        </CuiErrorBoundary>
      </CuiResizable>
      <Box sx={{flex: 1, borderLeft: 1, borderColor: 'grey.300'}}>
        <Tabs
          value={tab}
          onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
            setTab(newValue)
          }}
          sx={{pl: 2}}
        >
          <Tab value={0} key={0} label="View Doc" />
          {currentItem?.order && <Tab value={1} key={1} label="Select" />}
        </Tabs>
        {tab === 1 && <FavoriteSelectField />}
        <Stack
          display={tab === 0 ? 'block' : 'none'}
          sx={{overflowY: 'auto', height: '95%', width: '100%'}}
        >
          <MailBoxPreview />
        </Stack>
      </Box>
      {currentBoard && anchorEl && (
        <MocModal
          availableBoards={
            departments.find(d => d.boards.some(b => b.id === currentBoard?.id))
              ?.allBoards || []
          }
          currentBoardId={currentBoard.id}
          onClose={onCloseMoc}
          anchorEl={anchorEl}
        />
      )}
    </Stack>
  )
})

export default Mailbox

import {observer} from 'mobx-react-lite'
import {Outlet} from 'react-router-dom'
import {Stack} from '@mui/material'
import MainMenu from 'src/components/workspace/menu/Main'

const Container = observer(() => {
  return (
    <Stack direction="row" width="100%" height="100vh" overflow={'hidden'}>
      <MainMenu />
      <Stack flex={1} height="100%" width={'1px'}>
        <Outlet />
      </Stack>
    </Stack>
  )
})

export default Container

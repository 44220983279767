import {fDate, fDateTime} from './formatTime'

export const stringToLowerCase = (s: string) => {
  if (!s) return ''
  return s[0].toLowerCase() + s.slice(1)
}

export const fDateUtcDate = (
  dateString: string | undefined
): string | undefined => {
  if (dateString && dateString.endsWith('Z')) {
    const date = new Date(dateString)
    if (!isNaN(date.getTime())) {
      return fDate(date)
    }
  }
  return dateString
}
export const fDateTimeUtcDate = (
  dateString: string | undefined
): string | undefined => {
  if (dateString && dateString.endsWith('Z')) {
    const date = new Date(dateString)
    if (!isNaN(date.getTime())) {
      return fDateTime(date, 'dd/MM/yyyy p')
    }
  }
  return dateString
}

export const fixUtcDateTimeInString = (
  str: string | undefined
): string | undefined => {
  if (str && str.indexOf('Date') !== -1) {
    let pattern = /\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z\b/g
    let modifiedString = str.replace(pattern, match => {
      const fdate = fDateTimeUtcDate(match)

      if (fdate) {
        return fdate
      } else {
        return match
      }
    })

    return modifiedString
  }

  return str
}

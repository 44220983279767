export class Checklist {
  status: ChecklistStatus

  dueDate: string

  description: string

  assignedTo: string

  requestedBy: string

  completedOn: string

  completedBy: string

  constructor(checklist: Checklist) {
    this.status = checklist.status
    this.dueDate = checklist.dueDate
    this.description = checklist.description
    this.assignedTo = checklist.assignedTo
    this.requestedBy = checklist.requestedBy
    this.completedOn = checklist.completedOn
    this.completedBy = checklist.completedBy
  }
}
export enum ChecklistStatus {
  Required,
  Received,
  InProgress,
  Closed,
  Completed,
  Done
}

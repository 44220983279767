import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {useParams} from 'react-router-dom'
import {useEffect} from 'react'
import EmptyContentIcon from 'src/images/illustration_empty_content.svg?react'
import {Stack, Typography} from '@mui/material'

const DepartmentView = observer(() => {
  const {
    departmentStore: {mainDepartments, isLoading, setCurrentMainDepartment}
  } = useMainStore()

  const {departmentId} = useParams()

  useEffect(() => {
    if (isLoading || !departmentId) return
    const md = mainDepartments.find(md => md.id === Number(departmentId))
    setCurrentMainDepartment(md)
  }, [departmentId, isLoading, mainDepartments, setCurrentMainDepartment])

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography fontSize="32px" fontWeight={700} mb={2}>
        No board selected
      </Typography>
      <Typography color="GrayText" variant="body1" mb={1.25}>
        Choose a board to be displayed in the system
      </Typography>
      <EmptyContentIcon width="272.5px" height="200px" />
    </Stack>
  )
})

export default DepartmentView

import {Backdrop, Card, CardContent, Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import LoginButton from 'src/components/auth/LoginButton'

const LoginPopup = observer(function LoginPopup() {
  return (
    <Backdrop
      open
      sx={{position: 'absolute', zIndex: theme => theme.zIndex.snackbar + 1}}
    >
      <Card>
        <CardContent>
          <Stack>
            Your session has expired. Please relogin.
            <LoginButton />
          </Stack>
        </CardContent>
      </Card>
    </Backdrop>
  )
})

export default LoginPopup

import {GridApiPro} from '@mui/x-data-grid-pro'
import {makeAutoObservable, reaction} from 'mobx'
import Board from 'src/entities/Board'
import Column, {DbTable} from 'src/entities/Column'
import Item from 'src/entities/Item'
import View, {ViewType} from 'src/entities/View'
import ViewGroup from 'src/entities/ViewGroup'
import {MainStore} from 'src/store/MainStore'
import {downloadBlob} from 'src/utils/download'
import {fDate} from 'src/utils/formatTime'
import {delimiter, exportItemReportAsExcel} from 'src/utils/itemReport'
import BoardReport, {PeriodFilter} from '../entities/Report'
import config from '../config'
import Condition from '../entities/Condition'
import {FunctionUsed} from './SharedStore'

export type Filter = {
  filterKey: string
  filterValue: any
}

export default class ReportStore {
  selectedBoard?: Board

  view?: View

  report?: BoardReport

  periodFilters?: PeriodFilter[]

  currentPeriodFilter?: PeriodFilter

  currentFieldFilters?: Filter[] = []

  collapseGroups: Record<number, boolean> = {}

  groupGetDataAsCSV: Record<number, () => string> = {}

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    //reaction(() => this.selectedBoard?.report, this.setReport)

    reaction(
      () => [
        this.selectedBoard,
        this.selectedBoard?.report,
        this.periodFilters,
        this.selectedBoard?.views,
        this.owner?.sharedStore?.conditions
      ],
      ([selectedBoard, report, periodFilters, views, conditions]) => {
        if (selectedBoard && report && periodFilters && views && conditions) {
          this.setReport()
          this.updateView()
          this.setCurrentPeriodFilter(undefined)
          this.setCurrentFieldFilter(undefined)
        }
      }
    )

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      isMsalAuth => {
        if (isMsalAuth) this.getPeriodFilters()
      }
    )
  }

  getPeriodFilters = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/periodFilters`)
      .then(res => {
        return res.json()
      })
      .then((data: PeriodFilter[]) => {
        if (data) {
          this.setPeriodFilters(data.map(p => new PeriodFilter(p)))
        }
      })
  }

  setPeriodFilters = (data: PeriodFilter[]) => {
    this.periodFilters = data
  }

  setCurrentPeriodFilter = (periodFilter: PeriodFilter | undefined) => {
    if (periodFilter?.functionName) this.currentPeriodFilter = periodFilter
    else if (periodFilter)
      //case of selected period, but no period.functionName. i.e. the period is "ALL"
      this.currentPeriodFilter = undefined
    //case of undefined. no selected period filter.
    else
      this.currentPeriodFilter = this.periodFilters?.find(
        f => f.id === this.report?.periodFilters[0]
      )
  }

  setCurrentFieldFilter = (filters: Filter[] | undefined) => {
    if (filters)
      this.currentFieldFilters = [
        ...(this.currentFieldFilters || []),
        ...filters
      ]
  }

  removeFieldFilter = (columnId: number) => {
    this.currentFieldFilters = this.currentFieldFilters?.filter(
      filter => filter.filterKey !== columnId.toString()
    )
  }

  updateFieldFilter = (newFilter: Filter) => {
    const index = this.currentFieldFilters?.findIndex(
      filter => filter.filterKey === newFilter.filterKey
    )

    if (index !== undefined && index !== -1) {
      this.currentFieldFilters![index] = newFilter
    } else {
      this.currentFieldFilters?.push(newFilter)
    }
  }

  setSelectedBoard = (board?: Board) => {
    this.owner.boardStore.setCurrentBoard(board)
    this.selectedBoard = board
  }

  setView = (view?: View) => {
    this.view = view
    this.periodFilters?.forEach(filter => {
      if (!this.view?.columns?.some(c => c.dbColumn.name === filter!.name)) {
        this.view?.columns?.push(
          new Column({
            id: filter?.value,
            name: filter?.functionName,
            dbColumn: {id: filter?.value, name: filter?.functionName},
            dbTableId: DbTable.Item,
            sort: 0
          } as Column)
        )
      }
    })
  }

  updateView = () => {
    this.setView(
      this.selectedBoard?.views?.find(v => v.viewType === ViewType.Report)
    )
  }

  setReport = () => {
    if (this.selectedBoard?.reports) this.report = this.selectedBoard.reports[0]
  }

  setCollapseGroup = (id: number, isCollapse: boolean) => {
    this.collapseGroups[id] = isCollapse
  }

  setAllCollapseGroup = () => {
    this.collapseGroups =
      Object.values(this.collapseGroups).filter(v => v).length > 0
        ? {}
        : this.view?.viewGroups.reduce((acc: any, g) => {
            acc[g.id] = true
            return acc
          }, {})
  }

  setGroupGetDataAsCSV = (groupId: number, apiRef: GridApiPro) =>
    (this.groupGetDataAsCSV[groupId] = () =>
      apiRef.getDataAsCsv({includeHeaders: false, delimiter: delimiter}))

  getItemWithColumn(item: Item) {
    const columnsData = this.view?.columns?.reduce((a, c) => {
      const value = item.getValue(c.dbTableId, c.dbColumn, c.roleId)

      const getValue = (value: any) => {
        if (typeof value === 'function') {
          return (value as Function)().toString()
        } else {
          return value?.toString()
        }
      }
      return {
        ...a,
        [c.id]: getValue(value)
      }
    }, {})

    return {...item, ...columnsData} as Item
  }

  getGroupItems = (group: ViewGroup) => {
    const periodFilters: Filter[] = this.currentPeriodFilter
      ? [
          {
            filterKey: this.currentPeriodFilter!.value.toString(),
            filterValue: true
          }
        ]
      : []
    const conditions =
      this.owner.sharedStore.conditions[FunctionUsed.FilterReport]
    const condition = conditions?.find(
      (condition: Condition) =>
        condition.boardIds?.includes(this.selectedBoard!.id) &&
        condition.viewIds?.includes(this.view!.id)
    )

    return this.selectedBoard?.activeItems.reduce((items, item) => {
      let isToPush = false
      if (group.itemGroupIds.includes(item.itemGroupId)) isToPush = true
      if (condition?.rolesToApply && condition.statusIds) {
        const currentStatus = item.getStatusAssigneeWithDefaultsByRole(
          condition.rolesToApply![0]
        )[0]?.statusId
        if (
          currentStatus &&
          condition.statusIds.includes(currentStatus) &&
          this.currentPeriodFilter?.name.toLowerCase() !== 'all'
        )
          isToPush = false
      }
      if (isToPush) items.push(this.getItemWithColumn(item))

      const filteredItems = this.filterItems(
        items,
        this.currentFieldFilters || []
      )
      return this.filterItems(filteredItems, periodFilters)
    }, [] as Item[])
  }

  exportExcel = async () => {
    const department = this.owner.departmentStore.departments.find(d =>
      d.boards.some(b => b.id === this.selectedBoard?.id)
    )
    const columns = this.view?.columns?.filter(c => c.sort)

    const buffer = await exportItemReportAsExcel(
      department?.name,
      this.selectedBoard?.name,
      columns,
      this.view?.viewGroups,
      this.groupGetDataAsCSV
    )

    downloadBlob(
      new Blob([buffer]),
      `${this.selectedBoard?.name} ${fDate(new Date())}.xlsx`.replace(
        /(\s|-)/g,
        '_'
      )
    )
  }

  // this function groups filters by filterKey
  groupFiltersByKey = (filters: Filter[]): Map<string, Filter[]> => {
    return filters.reduce((groups, filter) => {
      const {filterKey} = filter
      if (!groups.has(filterKey)) {
        groups.set(filterKey, [])
      }
      groups.get(filterKey)!.push(filter)
      return groups
    }, new Map<string, Filter[]>())
  }

  filterItems = (items: Item[], filters: Filter[]): Item[] => {
    if (filters.length === 0) return items

    // Group filters by filterKey
    const filterGroups = this.groupFiltersByKey(filters)

    return items.filter(item => {
      return Array.from(filterGroups.entries()).every(
        ([filterKey, groupFilters]) => {
          //filters with different filterKey -check that all the filter are pass.
          return groupFilters.some(({filterValue}) => {
            //filters with same filterKey - check if at least one filter in the group matches
            const value = item[filterKey as keyof Item]

            if (!value || !filterValue) {
              return false
            }

            if (filterValue instanceof Date) {
              const valueDate = new Date(value.toString())
              return valueDate.getMonth() === filterValue.getMonth() //check if value is suitable to the filter value.
            }
            return value.toString() === filterValue.toString()
          })
        }
      )
    })
  }
}

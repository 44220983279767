import {IconButton, Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Label from 'src/components/label'
import View, {ViewType} from 'src/entities/View'
import {useMainStore} from 'src/context/Main'
import ItemViewMailboxHeader from './ItemViewMailboxHeader'
import NotificationsPopover from 'src/components/notification/NotificationsPopover'

interface ItemViewModalHeaderProps {
  view: View
  count?: number
  unreadCount?: number
  onClose: () => void
}

const ItemViewModalHeader = observer(
  ({view, count, unreadCount, onClose}: ItemViewModalHeaderProps) => {
    const mainStore = useMainStore()
    const {currentItem} = mainStore.itemStore

    const isMailBoxView = view.viewType === ViewType.MailBox

    return (
      <Stack position="sticky" top={0} zIndex={2} bgcolor="background.paper">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={1.5}
          pl={2}
          pr={1}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack direction={'row'} spacing={1}>
              <Typography variant="h5"> {view.name}</Typography>
              {isMailBoxView && <ItemViewMailboxHeader />}
            </Stack>

            <Typography variant="subtitle1">
              {!!count ? `(${count})` : ''}
            </Typography>
            {!!unreadCount && <Label color="info">{unreadCount}</Label>}
          </Stack>
          <Stack direction="row" justifyContent="end">
            {isMailBoxView && <NotificationsPopover />}
            <IconButton disabled={!currentItem?.order} onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
    )
  }
)

export default ItemViewModalHeader

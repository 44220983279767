import {makeAutoObservable} from 'mobx'
import User from 'src/entities/User'
import Role from 'src/entities/Role'
import {RoleId} from 'src/entities/PermissionData'

export default class Member {
  boardId: number

  role: Role

  user: User

  userId: number

  isAvailable: boolean

  constructor(member: Member) {
    this.boardId = member.boardId
    this.role = new Role(member.role)
    this.user = new User(member.user)
    this.userId = member.userId
    this.isAvailable = member.isAvailable
    makeAutoObservable(this)
  }

  setIsAvailable = (newValue: boolean) => {
    this.isAvailable = newValue
  }

  get roleId() {
    return this.role.id || RoleId.User
  }
}

import {
  Menu,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  ListSubheader,
  Divider
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import {useMainStore} from 'src/context/Main'
import Member from 'src/entities/Member'
import User from 'src/entities/User'

interface UserListItemProps {
  user: User
  onChoose: (userId?: number) => void
}

const UserListItem = observer(({user, onChoose}: UserListItemProps) => {
  return (
    <ListItemButton key={user.id} dense onClick={() => onChoose(user.id)}>
      <ListItemAvatar sx={{mr: 1}}>
        <CuiAvatar
          size={20}
          name={user.fullName}
          value={user.initials}
          isHere={user.isHere}
        />
      </ListItemAvatar>
      <ListItemText primary={user.fullName} />
    </ListItemButton>
  )
})

interface UsersListItemProps {
  name: string
  users?: Member[]
  divider?: boolean
  onChoose: (userId?: number) => void
}

const UsersListItem = observer(
  ({name, users, onChoose}: UsersListItemProps) => {
    return (
      <>
        <ListSubheader>
          <b>{name}</b>
        </ListSubheader>
        {!!users?.length ? (
          users?.map(m => (
            <UserListItem key={m.user.id} user={m.user} onChoose={onChoose} />
          ))
        ) : (
          <Divider
            variant="middle"
            sx={{
              m: 'auto',
              mt: 1,
              mb: 3,
              width: '40%',
              borderStyle: 'dashed',
              borderColor: theme => theme.palette.grey[500]
            }}
          />
        )}
      </>
    )
  }
)

interface UserMenuListProps {
  anchorEl: HTMLElement
  ignoreUsers?: number[]
  onChoose: (userId?: number) => void
}

const UserMenuList = observer(
  ({anchorEl, ignoreUsers, onChoose}: UserMenuListProps) => {
    const {currentBoard} = useMainStore().boardStore

    const availableMembers = ignoreUsers
      ? currentBoard?.availableMembers.filter(
          m => !ignoreUsers.includes(m.user.id)
        )
      : currentBoard?.availableMembers

    const notAvailableMembers = ignoreUsers
      ? currentBoard?.notAvailableMembers.filter(
          m => !ignoreUsers.includes(m.user.id)
        )
      : currentBoard?.notAvailableMembers

    return (
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => onChoose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        MenuListProps={{
          sx: {width: 260, maxHeight: 300, overflow: 'auto', p: 0}
        }}
      >
        <UsersListItem
          name="Available"
          users={availableMembers}
          onChoose={onChoose}
        />
        <Divider sx={{mt: 1}} />
        <UsersListItem
          name="Not Available"
          users={notAvailableMembers}
          onChoose={onChoose}
        />
      </Menu>
    )
  }
)

export default UserMenuList

import {
  AutocompleteRenderInputParams,
  Checkbox,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import SearchIcon from 'src/images/search-textField.svg?react'

const RenderInput = (
  currentFileNumber: string,
  selectedCurrentItem: boolean,
  unselectCurrentItem: () => void
) => {
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <>
      <TextField
        ref={params.InputProps.ref}
        inputProps={params.inputProps}
        size="small"
        fullWidth
        autoFocus
        placeholder="Search file number"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      <Typography pt={0.5}>Mentioned E-mail</Typography>
      <Stack flexDirection={'row'} alignItems={'center'}>
        <Checkbox onClick={unselectCurrentItem} checked={selectedCurrentItem} />
        <Typography variant="body2">{currentFileNumber}</Typography>
      </Stack>
      <Divider />
    </>
  )
  renderInput.displayName = 'RenderInput'
  return renderInput
}
export default RenderInput

import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import cuiSubDropdown from 'src/components/custom/cuiSubDropdown'
import {forwardRef, useCallback, useEffect} from 'react'
import {styled} from '@mui/material'

export const DropdownMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`

export const DropdownNestedMenuItem = styled(cuiSubDropdown)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`

const Dropdown = forwardRef(
  (
    {
      trigger,
      menu,
      keepOpen: keepOpenGlobal,
      isOpen: controlledIsOpen,
      onOpen: onControlledOpen,
      minWidth,
      forceClose
    }: any,
    ref
  ) => {
    const [isInternalOpen, setInternalOpen] = React.useState(null)

    const isOpen = controlledIsOpen || isInternalOpen

    const handleForceClose = useCallback(() => {
      if (onControlledOpen) onControlledOpen(null)
      else setInternalOpen(null)
    }, [onControlledOpen])

    useEffect(() => {
      handleForceClose()
    }, [forceClose, handleForceClose])

    let anchorRef: any = React.useRef(null)
    if (ref) {
      anchorRef = ref
    }

    const handleOpen = (event: any) => {
      event.stopPropagation()

      if (menu.length) {
        if (onControlledOpen) onControlledOpen(event.currentTarget)
        else setInternalOpen(event.currentTarget)
      }
    }

    const handleClose = (event: any) => {
      event.stopPropagation()

      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return
      }

      handleForceClose()
    }

    const renderMenu = (menuItem: any, index: number): any => {
      const {keepOpen: keepOpenLocal, ...props} = menuItem.props

      let extraProps = {}
      if (props.menu) {
        extraProps = {
          parentMenuOpen: isOpen
        }
      }

      return React.createElement(
        menuItem.type,
        {
          ...props,
          key: index,
          ...extraProps,
          onClick: (event: any) => {
            event.stopPropagation()

            if (!keepOpenGlobal && !keepOpenLocal) {
              handleClose(event)
            }

            if (menuItem.props.onClick) {
              menuItem.props.onClick(event)
            }
          }
        },
        props.menu ? React.Children.map(props.menu, renderMenu) : props.children
      )
    }

    return (
      <>
        {React.cloneElement(trigger, {
          onClick: isOpen ? handleForceClose : handleOpen,
          ref: anchorRef
        })}

        <Menu
          slotProps={{paper: {sx: {minWidth: minWidth ?? 0}}}}
          anchorEl={isOpen}
          open={!!isOpen}
          onClose={handleClose}
        >
          {React.Children.map(menu, renderMenu)}
        </Menu>
      </>
    )
  }
)

Dropdown.displayName = 'Dropdown'
export default Dropdown

import {useRef, useState} from 'react'
import {Typography, Menu, Stack, TextField} from '@mui/material'
import {ColorSinglePicker} from 'src/components/color-utils'
import DotIcon from 'src/images/dot.svg?react'
import Group from 'src/entities/ViewGroup'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {Control, PermissionType} from 'src/entities/PermissionData'
import Label from 'src/components/label'

const COLOR_OPTIONS = [
  '#00AB55',
  '#00B8D9',
  '#003768',
  '#FFC107',
  '#FFAC82',
  '#B71D18'
]

export interface DisplayGroupProps {
  group: Group
  viewId: number
  numOfItems: number
}

const DisplayGroup = observer(
  ({group, viewId, numOfItems}: DisplayGroupProps) => {
    const {
      viewStore: {setGroupColor, setGroupName},
      permissionStore: {getPermissionType}
    } = useMainStore()

    const [editName, setEditName] = useState('')
    const textFieldRef = useRef<HTMLDivElement | null>(null)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const onColorClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
      event.stopPropagation()
    }
    const handleClose = (
      event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>
    ) => {
      setAnchorEl(null)
      event.stopPropagation()
    }

    const onChangeColor = (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string
    ) => {
      handleClose(event)
      setGroupColor(group, value)
    }

    const onChangeName = () => {
      const newName = editName
      setEditName('')
      if (newName !== group.name) setGroupName(group, newName)
    }
    return (
      <>
        <Stack direction="row" alignItems="center" spacing={2}>
          <div onClick={onColorClick}>
            {' '}
            <DotIcon stroke={group.color} />
          </div>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              py: 1.5
            }}
          >
            {getPermissionType(
              viewId,
              undefined,
              undefined,
              Control.EditGroupColor
            ) === PermissionType.Write && (
              <Stack spacing={1} mx={2.5}>
                <Typography fontWeight={600}>Color</Typography>

                <ColorSinglePicker
                  colors={COLOR_OPTIONS}
                  value={group.color}
                  onClick={e => e.stopPropagation()}
                  onChange={onChangeColor}
                />
              </Stack>
            )}
          </Menu>
          {editName &&
          getPermissionType(
            viewId,
            undefined,
            undefined,
            Control.EditGroupName
          ) === PermissionType.Write ? (
            <TextField
              autoFocus
              variant="standard"
              value={editName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEditName(event.target.value)
              }}
              onKeyDown={ev => {
                if (ev.key === 'Enter') {
                  onChangeName()
                }
              }}
              onBlur={onChangeName}
              ref={textFieldRef}
              onClick={e => e.stopPropagation()}
            />
          ) : (
            <div
              onClick={e => {
                setEditName(group.name)
                e.stopPropagation()
              }}
            >
              <Typography fontWeight={600}>{group.name}</Typography>
            </div>
          )}
          <Label>{numOfItems}</Label>
        </Stack>
      </>
    )
  }
)

export default DisplayGroup

import {IconButton, Stack, Typography, Paper} from '@mui/material'
import LabelCol from 'src/components/view/columnTypes/LabelCol'
import Column, {ColumnType, DbTable, Direction} from 'src/entities/Column'
import Item from 'src/entities/Item'
import View from 'src/entities/View'
import SelectLinkIcon from 'src/images/select.svg?react'
import HomePageIcon from 'src/images/homePage.svg?react'
import {observer} from 'mobx-react-lite'
import {fDate} from 'src/utils/formatTime'
import config from 'src/config'
import {PermissionType} from 'src/entities/PermissionData'
import AssigneeStatus from 'src/components/view/columnTypes/AssigneeStatus'
import Status from 'src/components/view/columnTypes/Status'
import SubRoleStatus from 'src/components/view/columnTypes/SubRoleStatus'
import React from 'react'
import redirectWebsite from 'src/utils/redirectWebsite'
import GooleMapsIcon from 'src/images/google_maps.svg?react'
import WorkspaceIcon from 'src/images/workspace.svg?react'
import GridAssigneeStatus from '../columnTypes/GridAssigneeStatus'
export interface InfoViewProps {
  view: View
  currentItem: Item
  permissionsColumn?: Map<number, PermissionType>
}

const getValue = (value: string, type: ColumnType) => {
  switch (type) {
    case ColumnType.DateTime:
    case ColumnType.Date: {
      return fDate(value)
    }
    case ColumnType.Text:
    case ColumnType.BoldText:
      return value !== undefined ? value : ''
    case ColumnType.Dollar:
      return value ? `${value} $` : ''
  }
}

const render = (
  column: Column,
  value: any,
  item: Item,
  permissionType: PermissionType
) => {
  switch (column.type) {
    case ColumnType.Dollar:
    case ColumnType.Text:
    case ColumnType.BoldText:
    case ColumnType.Date:
    case ColumnType.DateTime: {
      return (
        <>
          <Paper
            sx={{
              p: 1,
              minWidth: 160,
              minHeight: 52
            }}
            elevation={12}
          >
            <Stack
              display="flex"
              direction="row"
              justifyContent="space-between"
            >
              <Stack>
                <Typography sx={{fontSize: 14, fontWeight: 600}}>
                  {column.name}
                </Typography>
                <Typography sx={{fontSize: 12, fontWeight: 400}}>
                  {getValue(value, column.type)}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </>
      )
    }
    case ColumnType.HomePage: {
      return (
        <IconButton
          onClick={() =>
            redirectWebsite(`${config.linkToHP}${item.order?.fileNumber}`)
          }
        >
          <HomePageIcon />
        </IconButton>
      )
    }
    case ColumnType.Select: {
      return (
        <IconButton
          onClick={() =>
            redirectWebsite(`${config.linkToSelect}${item.order.guid}`)
          }
        >
          <SelectLinkIcon />
        </IconButton>
      )
    }
    case ColumnType.Label: {
      const columnData = JSON.parse(
        column.data?.find(
          d =>
            d.columnValue ===
            item.getValue(column.dbTableId, column.dbColumn)?.toString()
        )?.data || '{}'
      )
      return (
        <Stack width={70}>
          <LabelCol text={columnData.text} color={columnData.color} />
        </Stack>
      )
    }
    case ColumnType.Status: {
      return column.dbTableId === DbTable.AssigneStatus ? (
        <AssigneeStatus
          column={column}
          item={item}
          permissionType={permissionType}
          isCell={false}
        />
      ) : (
        <Status column={column} item={item} permissionType={permissionType} />
      )
    }
    case ColumnType.GridStatus: {
      return (
        <GridAssigneeStatus
          column={column}
          item={item}
          permissionType={permissionType}
          isCell={false}
        />
      )
    }
    case ColumnType.SubRoleStatus: {
      return (
        <SubRoleStatus
          column={column}
          item={item}
          permissionType={permissionType}
        />
      )
    }
    // case ColumnType.Intel: {
    //   return (
    //     <Link
    //       onClick={() =>
    //         redirectWebsite(
    //           `${generatePath(config.intelLink, {
    //             stateId: item?.order?.state ?? 'no_state'
    //           })}`
    //         )
    //       }
    //       sx={{
    //         cursor: 'pointer',
    //         ml: '1',
    //         fontWeight: 'bold',
    //         alignContent: 'center'
    //       }}
    //       underline="always"
    //     >
    //       Intel
    //     </Link>
    //   )
    // }
    case ColumnType.GoogleMaps: {
      return (
        <IconButton
          onClick={() => {
            redirectWebsite(
              `${config.googleMapsLink}${item.order.propertyAddress}`
            )
          }}
        >
          <GooleMapsIcon />
        </IconButton>
      )
    }
    case ColumnType.Workspace: {
      return (
        <IconButton
          onClick={() => {
            redirectWebsite(
              `${config.workspaceLink}/orderservice/${item.order.fileNumber}/${
                item.order.isCommertial ? 1 : 0
              }`
            )
          }}
        >
          <WorkspaceIcon />
        </IconButton>
      )
    }
  }
}

const InfoView = observer(
  ({view, currentItem, permissionsColumn}: InfoViewProps) => {
    return (
      <Stack spacing={2}>
        {view.name && (
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle1">{view.name}</Typography>
          </Stack>
        )}
        <Stack spacing={2}>
          <Stack useFlexGap direction="row" spacing={2} sx={{flexWrap: 'wrap'}}>
            {view.columns
              ?.filter(
                c =>
                  c.direction === Direction.Right &&
                  permissionsColumn?.get(c.id) !== PermissionType.Invisible
              )
              .map(c => (
                <React.Fragment key={c.id}>
                  {render(
                    c,
                    currentItem!.getValue(c.dbTableId, c.dbColumn),
                    currentItem!,
                    permissionsColumn
                      ? permissionsColumn.get(c.id) || PermissionType.Write
                      : PermissionType.Readonly
                  )}
                </React.Fragment>
              ))}
          </Stack>

          <Stack useFlexGap sx={{flexWrap: 'wrap'}} direction="row" spacing={2}>
            {view.columns
              ?.filter(
                c =>
                  c.direction === Direction.Left &&
                  permissionsColumn?.get(c.id) !== PermissionType.Invisible
              )
              .map(c => (
                <React.Fragment key={c.id}>
                  {render(
                    c,
                    currentItem!.getValue(c.dbTableId, c.dbColumn),
                    currentItem!,
                    permissionsColumn
                      ? permissionsColumn.get(c.id) || PermissionType.Write
                      : PermissionType.Readonly
                  )}
                </React.Fragment>
              ))}
          </Stack>
        </Stack>
      </Stack>
    )
  }
)

export default InfoView

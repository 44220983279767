import {Status} from '@madisoncres/title-general-package/src/stores/SignalStore'
import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import {MainStore} from 'src/store/MainStore'
import Condition from 'src/entities/Condition'

export default class SharedStore {
  currentTab?: Tabs

  conditions: Record<string, Condition[]> = {}

  isIncorrectVersion: boolean = false

  isNeedToRefresh: boolean = false

  currentVersion: string = '-1'

  setConditions = (conditions: any[]) => {
    conditions.forEach(c => {
      const {functionUsed, ...otherProps} = c
      if (this.conditions[functionUsed]) {
        this.conditions[functionUsed].push(new Condition(otherProps))
      } else {
        this.conditions[functionUsed] = [new Condition(otherProps)]
      }
    })
  }

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      isFirstLoggedin => {
        if (isFirstLoggedin) {
          this.getLastVersion()
          this.getConditions()
        }
      }
    )

    reaction(
      () => this.owner.signalrStore.status,
      status => {
        if (status === Status.IncorrectVersion) {
          this.setIsIncorrectVersion(true)
          this.owner.signalrStore.stopConnection()
        }
      }
    )
  }

  setCurrentTab = (tab: Tabs) => {
    this.currentTab = tab
  }

  getConditions = async () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Conditions`)
      .then(res => {
        return res.json()
      })
      .then((data: any[]) => {
        if (data) {
          this.setConditions(data)
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  setCurrentVersion = (version: string) => {
    this.currentVersion = version
  }

  setIsIncorrectVersion = (isIncorrectVersion: boolean) => {
    this.isIncorrectVersion = isIncorrectVersion
  }

  setIsNeedToRefresh = (isNeedToRefresh: boolean) => {
    this.isNeedToRefresh = isNeedToRefresh
  }

  getLastVersion = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/version`, {
        headers: {
          Version: '-1'
        }
      })
      .then(res => {
        return res.json()
      })
      .then((data: string) => {
        if (data) {
          this.setIsIncorrectVersion(false)
          this.setCurrentVersion(data)
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }
}

export enum Tabs {
  Workflow = 1,
  Reports
}

export enum FunctionUsed {
  ApplyMyAssignFilterCondition = 1,
  AssigneeCell,
  FilterBacktitleViewFiles,
  EveryoneCanAssignInBoard,
  FilterAssigneeFilesByRole,
  InboundGroupItems,
  CombinedInboundGroupItems,
  MoveToAvaibleGroup,
  IsViewGroupsEnableToMoveGroup,
  FilterReport
}

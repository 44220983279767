import {Alert, AlertColor, IconButton, Snackbar} from '@mui/material'
import {CloseIcon} from 'src/theme/overrides/CustomIcons'

export interface CuiSnackbarAlertProps {
  severity: AlertColor
  message: string
  onClose: () => void
}
export default function CuiSnackbarAlert({
  severity,
  message,
  onClose
}: CuiSnackbarAlertProps) {
  const close = () => {
    onClose()
  }

  return (
    <>
      <Snackbar
        open
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={6000}
      >
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={close}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

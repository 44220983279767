import {useState} from 'react'
import {IconButton} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import CuiGroupAvatars from 'src/components/custom/CuiGroupAvatars'
import Item from 'src/entities/Item'
import AssigneeDialog from 'src/components/view/AssigneeDialog'
import {DynamicGridColumn} from 'src/entities/Column'
import User from 'src/entities/User'
import {RoleId} from 'src/entities/PermissionData'
import {moveObjectToStart} from 'src/utils/array'
import StatusAssignee from 'src/entities/StatusAssignee'
import {FunctionUsed} from 'src/store/SharedStore'

export interface AssigneeProps {
  column: DynamicGridColumn
  item: Item
}

const AssigneeCell = observer(({column, item}: AssigneeProps) => {
  const mainStore = useMainStore()
  const {currentView} = mainStore.viewStore
  const {user} = mainStore.loginStore
  const {currentMember, currentBoard} = mainStore.boardStore
  const {conditions} = mainStore.sharedStore

  const condition = conditions[FunctionUsed.AssigneeCell]?.[0]
  const boardCondition = conditions[FunctionUsed.EveryoneCanAssignInBoard]?.[0]
  const [openDialog, setOpenDialog] = useState(false)

  const onClose = () => setOpenDialog(false)

  const subRoles = column?.subColumns?.map((c: any) => c.roleId)
  const canSelfAssign = column.role?.selfAssign && item.inboundInAssignee

  const assignBoardRole =
    currentBoard?.id && boardCondition.boardIds?.includes(currentBoard?.id)

  var relevantDataList = canSelfAssign
    ? []
    : item.statusAssigneesWithoutDefault()

  if (currentView?.isAssignedOnly || assignBoardRole) {
    const releventRoleIds = [column.roleId, ...subRoles]
    relevantDataList = relevantDataList.filter(sa =>
      releventRoleIds.includes(sa.roleId)
    )
  }

  const users = moveObjectToStart<StatusAssignee>(
    relevantDataList,
    relevantDataList.find(d => d.roleId === currentBoard?.defaultRole?.id)
  )
    .map(s => s.user)
    .filter(c => c !== undefined) as User[]

  const canAssignSubRoles = !!(
    subRoles.length > 0 && users.find(u => u.id === user?.id)
  )

  const hasRoleAnyoneCanAssign = [column.roleId, ...subRoles].some(
    r => condition.rolesToApply?.includes(r)
  )

  const canAssign =
    assignBoardRole ||
    (!currentView?.isAssignedOnly &&
      currentMember?.roleId === RoleId.Manager) ||
    canAssignSubRoles ||
    canSelfAssign ||
    hasRoleAnyoneCanAssign

  return (
    <>
      {canAssign && (
        <IconButton onClick={() => setOpenDialog(true)}>
          <AddIcon />
        </IconButton>
      )}
      <CuiGroupAvatars
        max={4}
        size={24}
        users={users}
        borderedUserId={
          relevantDataList.find(
            d => d.user && d.roleId === currentBoard?.defaultRole?.id
          )?.user?.id
        }
      />
      {openDialog && (
        <AssigneeDialog
          item={item}
          statusAssigneesList={relevantDataList}
          onClose={onClose}
          parentRole={canAssignSubRoles ? column.roleId : undefined}
          initialRoleId={
            (assignBoardRole || canSelfAssign) && column.roleId
              ? column.roleId
              : null
          }
          initialUserId={canSelfAssign && user ? user?.id : null}
          condition={condition}
          boardCondition={boardCondition}
        />
      )}
    </>
  )
})

export default AssigneeCell

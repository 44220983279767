import {makeObservable, reaction} from 'mobx'
import config from 'src/config'
import {MainStore} from 'src/store/MainStore'
import SignalrStorePackage from '@madisoncres/title-general-package/src/stores/SignalStore'
import {LogLevel, MessageHeaders} from '@microsoft/signalr'

export default class SignalrStore extends SignalrStorePackage {
  constructor(readonly owner: MainStore) {
    super({
      signalrConnectionUrl: `${config.signalrConnectionUrl}/wf_messagehub`,
      accessTokenFactory: async () => this.owner.loginStore.getAccessToken(),
      dataToLog: 'WF',
      logLevel: LogLevel.Debug,
      headers: () => this.getHeaders()
    })

    makeObservable(this)

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      async isAuth => {
        if (isAuth) this.startConnection()
        else this.stopConnection()
      }
    )
  }

  getHeaders = (): MessageHeaders => {
    return {Version: this.owner.sharedStore.currentVersion}
  }
}

import React, {useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import config from 'src/config'
import {useMainStore} from 'src/context/Main'

const ReportersContext = React.createContext({})

const ReportersContextProvider = observer(function ReportersContextProvider({
  children
}: any) {
  const reportersRef = useRef<any>(null)
  const [ready, setReady] = useState(false)
  const {user} = useMainStore().loginStore

  useEffect(() => {
    if (!config.isHubProduction) return // run only in production

    import(/* webpackChunkName: 'reporters' */ '../utils/reporters').then(x => {
      reportersRef.current = x.default
      setReady(true)
    })
  }, [])

  // update reporters with user data
  useEffect(() => {
    if (!ready || !user || !Object.keys(user).length) return

    const logrocket = reportersRef.current

    const {fullName: name, email} = user

    logrocket.identify(user.id, {name, email})
  }, [ready, user])

  return (
    <ReportersContext.Provider value={{}}>{children}</ReportersContext.Provider>
  )
})

export {ReportersContextProvider, ReportersContext}

import {generatePath, useNavigate} from 'react-router-dom'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import {PATH_WORKFLOW} from 'src/routes/paths'
import {useMainStore} from 'src/context/Main'

const ArchiveDialog = () => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {currentItem, setCurrentItem} = mainStore.itemStore
  const navigate = useNavigate()

  const onClose = () => {
    setCurrentItem(undefined)
    navigate(generatePath(PATH_WORKFLOW.boards, {boardId: currentBoard?.id}))
  }

  return (
    <>
      <CuiAlertDialog
        title="Oooops,"
        okButtonText="Ok, Got it"
        open={true}
        body={`This file (${currentItem?.getFileNumberText()}) has been archived or deleted by another user and is no longer available for editing`}
        okClick={() => onClose()}
        close={() => {
          onClose()
        }}
      />
    </>
  )
}

export default ArchiveDialog

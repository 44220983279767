import {observer} from 'mobx-react-lite'
import Column, {ColumnData} from 'src/entities/Column'
import Item from 'src/entities/Item'
import {PermissionType} from 'src/entities/PermissionData'
import {Button, Stack, Typography} from '@mui/material'
import Dropdown, {DropdownMenuItem} from 'src/components/custom/CuiDropdown'
import LabelCol from 'src/components/view/columnTypes/LabelCol'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {useMainStore} from 'src/context/Main'

interface StatusProps {
  column: Column
  item: Item
  permissionType: PermissionType
}

const Status = observer(({column, item, permissionType}: StatusProps) => {
  const {
    boardStore: {updateItem}
  } = useMainStore()

  const currentValue = item.getValue(column.dbTableId, column.dbColumn)
  const currentColumnData = column.data!.find(
    d => d.columnValue === currentValue?.toString()
  )
  const isReadOnly = permissionType === PermissionType.Readonly
  const currentData = currentColumnData
    ? JSON.parse(currentColumnData!.data)
    : undefined

  const onClick = (columnData: ColumnData) => {
    updateItem(
      item.id,
      column.id,
      columnData.columnValue,
      currentValue,
      column.dbColumn
    )
  }

  return (
    <Stack width="200px">
      <Typography sx={{fontSize: 14, fontWeight: 600}}>
        {column.name}
      </Typography>
      <Dropdown
        trigger={
          <Button
            disabled={isReadOnly}
            variant="outlined"
            sx={{
              p: 0.5,
              flexDirection: 'column',
              borderColor: theme => theme.palette.grey[500]
            }}
          >
            <Stack width="100%" direction="row" justifyContent="space-between">
              <LabelCol
                disableTextTransform
                pointer
                text={currentData ? currentData.text : ''}
                color={currentData ? currentData.color : 'primary'}
              />
              <ArrowDownIcon sx={{color: 'grey.500'}} />
            </Stack>
          </Button>
        }
        menu={column.data!.map(d => {
          const data = JSON.parse(d.data)
          return (
            <DropdownMenuItem key={d.id} onClick={() => onClick(d)}>
              <LabelCol
                disableTextTransform
                pointer
                text={data.text ?? ''}
                color={data.color ?? 'primary'}
              />
            </DropdownMenuItem>
          )
        })}
      />
    </Stack>
  )
})

export default Status

import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useNavigate, generatePath} from 'react-router-dom'
import CuiSelectWithOptions from 'src/components/custom/CuiSelectWithOptions'
import Departments from 'src/components/workspace/menu/Departments'
import {useMainStore} from 'src/context/Main'
import {PATH_WORKFLOW} from 'src/routes/paths'
import MenuInfoView from 'src/components/view/itemView/MenuInfoView'

const MainMenu = observer(() => {
  const {
    departmentStore: {
      mainDepartments,
      currentMainDepartment,
      setCurrentMainDepartment
    }
  } = useMainStore()
  const {
    itemStore: {currentOverItem}
  } = useMainStore()

  const navigate = useNavigate()

  return (
    <Stack
      width={260}
      height="100%"
      pt={5}
      alignItems="center"
      sx={{
        backgroundColor: 'grey.800'
      }}
      spacing={5}
      overflow="auto"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <CuiSelectWithOptions
          options={mainDepartments.map(sd => ({
            label: sd.name,
            value: sd.id
          }))}
          displayEmpty
          value={currentMainDepartment?.id || ''}
          onChange={e => {
            setCurrentMainDepartment(
              mainDepartments.find(sd => sd.id === e.target.value)
            )
            navigate(
              generatePath(PATH_WORKFLOW.departments, {
                departmentId: e.target.value
              })
            )
          }}
          sx={{
            width: 230,
            color: 'white',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'white !important'
            },
            '.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white !important'
            }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: 'grey.800',
                color: 'grey.500'
              }
            }
          }}
        />
      </Stack>
      <Departments />
      {currentOverItem && currentOverItem.order?.id && (
        <MenuInfoView item={currentOverItem} />
      )}
    </Stack>
  )
})

export default MainMenu

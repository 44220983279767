import {
  OnAssignClickDetails,
  PermissionType,
  ConversationAssignEvent,
  ConversationControl,
  ConversationOnAssignClickEvent,
  DocumentPreviewEvent,
  DocumentPreviewDetails
} from '@madisoncres/title-general-package'
import {LinearProgress} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useEffect, useState, Suspense, useMemo} from 'react'

import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import UserMenuList from 'src/components/user/UserMenuList'
import {useMainStore} from 'src/context/Main'
import Item from 'src/entities/Item'
import {Control} from 'src/entities/PermissionData'
import config from 'src/config'
import loadable from '@loadable/component'

const ConversationList = loadable(() => import('cbChat/ConversationList'))

const QuestionList = observer(({viewId, item}: QuestionTabProps) => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {getPermissionTypeByRoles} = mainStore.permissionStore
  const {user} = mainStore.loginStore

  const additionalInfo = useMemo(
    () => ({fileNumber: item.order?.fileNumber, boardId: currentBoard?.id}),
    [item.order?.fileNumber, currentBoard?.id]
  )

  const permissions = useMemo(() => {
    const rolesId = item.statusAssignees
      .filter(a => a.userId === user?.id)
      .map(a => a.roleId) as number[]
    const assignPermissionType = getPermissionTypeByRoles(
      viewId,
      rolesId,
      undefined,
      Control.Assign
    )
    const seeAllPermissionType = getPermissionTypeByRoles(
      viewId,
      rolesId,
      undefined,
      Control.SeeAll
    )
    const filterPermissionType = getPermissionTypeByRoles(
      viewId,
      rolesId,
      undefined,
      Control.Filter
    )

    const permissions = {} as Record<ConversationControl, PermissionType>
    Object.keys(ConversationControl).forEach(key => {
      const enumKey = Number(key) as ConversationControl
      if (!isNaN(enumKey)) {
        permissions[enumKey] = PermissionType.Readonly
      }
    })

    permissions[ConversationControl.Filter] = filterPermissionType
    permissions[ConversationControl.SeeAll] = seeAllPermissionType
    permissions[ConversationControl.Assign] = assignPermissionType

    return permissions
  }, [viewId, user, item, getPermissionTypeByRoles])

  return (
    <ConversationList
      entityId={item.id}
      entityTypeId={config.itemEntityTypeId}
      conversationTypeId={config.questionConversationType}
      additionalInfo={additionalInfo}
      permissions={permissions}
    />
  )
})

interface QuestionTabProps {
  viewId: number
  item: Item
}

const QuestionTab = observer(({viewId, item}: QuestionTabProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const [currentAssignee, setCurrentAssignee] = useState<number>()

  const onAssigned = (userId?: number) => {
    ConversationAssignEvent.dispatch(
      userId
        ? {
            userId,
            entityId: item.id,
            entityTypeId: config.itemEntityTypeId,
            conversationTypeId: config.questionConversationType
          }
        : undefined
    )
    setAnchorEl(undefined)
  }

  useEffect(() => {
    const callback = (e: CustomEvent<DocumentPreviewDetails>) => {
      const details = e.detail
      if (details.blobUrl) {
        const file = encodeURIComponent(details.blobUrl)
        window.open(`/preview?file=${file}`, '_blank')
      }
    }
    DocumentPreviewEvent.subscribe(callback)

    return () => DocumentPreviewEvent.unsubscribe(callback)
  }, [])

  useEffect(() => {
    const callback = (e: CustomEvent<OnAssignClickDetails>) => {
      const details = e.detail
      if (
        details.entityId === item.id &&
        details.entityTypeId === config.itemEntityTypeId &&
        details.conversationTypeId === config.questionConversationType
      ) {
        setAnchorEl(details.anchor)
        setCurrentAssignee(details.currentAssigneeId)
      }
    }
    ConversationOnAssignClickEvent.subscribe(callback)

    return () => ConversationOnAssignClickEvent.unsubscribe(callback)
  }, [item.id])

  return (
    <>
      <CuiErrorBoundary>
        <Suspense fallback={<LinearProgress sx={{m: 'auto'}} />}>
          <QuestionList viewId={viewId} item={item} />
        </Suspense>
      </CuiErrorBoundary>
      {anchorEl && (
        <UserMenuList
          ignoreUsers={currentAssignee ? [currentAssignee] : undefined}
          anchorEl={anchorEl}
          onChoose={onAssigned}
        />
      )}
    </>
  )
})

export default QuestionTab

import {observer} from 'mobx-react-lite'
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {useMainStore} from 'src/context/Main'
import Iconify from 'src/components/iconify'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import {ChangeEvent} from 'react'

const NotificationsHeader = () => {
  const {unreadNotificationLength, markAllAsRead, setSearchQuery, searchQuery} =
    useMainStore().notificationStore

  return (
    <>
      <Stack direction="row" alignItems="center" py={2} px={2.5}>
        <Stack flexGrow={1}>
          <Typography variant="subtitle1">Notifications</Typography>
          {unreadNotificationLength > 0 && (
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
              You have {unreadNotificationLength} unread messages
            </Typography>
          )}
        </Stack>
        {unreadNotificationLength > 0 && (
          <Tooltip title="Mark all as read">
            <IconButton color="primary" onClick={markAllAsRead}>
              <Iconify icon="eva:done-all-fill" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack>
        <TextField
          fullWidth
          size="small"
          placeholder="Search"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearchQuery(e.target.value)
          }
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton size="small" onClick={() => setSearchQuery('')}>
                <ClearIcon />
              </IconButton>
            )
          }}
        />
      </Stack>
    </>
  )
}

export default observer(NotificationsHeader)

import {makeAutoObservable} from 'mobx'

export default class Condition {
  viewIds?: number[]

  statusIds?: number[]

  rolesToApply?: number[]

  viewGroupIds?: number[]

  boardIds?: number[]

  constructor(baseCondition: Condition) {
    this.viewIds = baseCondition.viewIds || []
    this.statusIds = baseCondition.statusIds || []
    this.rolesToApply = baseCondition.rolesToApply || []
    this.viewGroupIds = baseCondition.viewGroupIds || []
    this.boardIds = baseCondition.boardIds || []

    makeAutoObservable(this)
  }
}

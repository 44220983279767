import {Divider, Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {ViewType} from 'src/entities/View'
import Item from 'src/entities/Item'
import MenuOrderView from 'src/components/view/itemView/MenuOrderView'
import MenuPropertiesView from 'src/components/view/itemView/MenuPropertiesView'
import redirectWebsite from 'src/utils/redirectWebsite'
import config from 'src/config'

interface MenuInfoViewProps {
  item: Item
}

const MenuInfoView = observer(({item}: MenuInfoViewProps) => {
  const {currentBoard} = useMainStore().boardStore

  const menuSelectView = currentBoard?.itemMenuViews.find(
    v => v.viewType === ViewType.Select
  )

  const menuPropertiesView = currentBoard?.itemMenuViews.find(
    v => v.viewType === ViewType.Property
  )

  const properties =
    currentBoard?.properties && item.order?.id
      ? currentBoard?.properties[item.order.id]
      : undefined

  return (
    <Stack
      width={'90%'}
      spacing={1}
      sx={{
        m: 2,
        p: 1,
        border: 1,
        borderRadius: 3,
        color: theme => theme.palette.grey[400],
        borderColor: '#919EAB1F'
      }}
    >
      {' '}
      <Typography
        sx={{cursor: 'pointer'}}
        color={'#5BE584'}
        fontSize={14}
        fontWeight={700}
        pb={1}
        onClick={() =>
          redirectWebsite(`${config.linkToHP}${item.order?.fileNumber}`)
        }
      >
        {item.order?.fullFileNumber}
      </Typography>
      <MenuOrderView view={menuSelectView} currentOrder={item.order} />
      {properties && menuPropertiesView && (
        <>
          <Divider />
          <MenuPropertiesView
            view={menuPropertiesView}
            properties={properties}
          />
        </>
      )}
    </Stack>
  )
})

export default MenuInfoView

import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import Notification, {Flag} from 'src/entities/Notification'
import {MainStore} from 'src/store/MainStore'
import {isToday} from 'date-fns'
import {RoleId} from 'src/entities/PermissionData'
import {handleNewNotification} from 'src/components/listeners/ListenersApp'
import {useDebouncedCallback} from 'use-debounce'
import {generatePath} from 'react-router-dom'
import {PATH_WORKFLOW} from 'src/routes/paths'
import workspaceIcon from 'src/images/workspace.svg'

export default class NotificationStore {
  notifications: Notification[]

  isLoading: boolean

  searchQuery: string

  audio: HTMLAudioElement

  constructor(readonly owner: MainStore) {
    this.notifications = []
    this.isLoading = false
    this.searchQuery = ''
    this.audio = new Audio('/notifications-sound.mp3')
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      async isFirstLoggedin => {
        if (isFirstLoggedin) {
          this.getNotifications()
        }
      }
    )

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )
  }

  setNotification = (notifications: Notification[]) => {
    this.notifications = notifications
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setSearchQuery = (searchQuery: string) => {
    this.searchQuery = searchQuery
  }

  get filteredNotifications() {
    const searchQuery = this.searchQuery.toLowerCase()
    return searchQuery
      ? this.notifications.filter(
          n =>
            n.subject.toLowerCase().includes(searchQuery) ||
            n.body.toLowerCase().includes(searchQuery)
        )
      : this.notifications
  }

  get todayNotifications() {
    return this.filteredNotifications.filter(n => isToday(n.createdAt))
  }

  get olderNotifications() {
    return this.filteredNotifications.filter(n => !isToday(n.createdAt))
  }

  get unreadNotificationLength() {
    return this.notifications.filter(n => !n.isRead).length
  }

  getNotifications = async () => {
    this.setIsLoading(true)
    this.owner.loginStore
      .fetchWithUser(`${config.notificationUrl}/notifications`)
      .then(res => {
        return res.json()
      })
      .then((data: Notification[]) => {
        if (data) this.setNotification(data.map(d => new Notification(d)))
      })
      .finally(() => this.setIsLoading(false))
  }

  updateIsRead = (notification: Notification, isRead: boolean) => {
    notification.setIsRead(isRead)

    return this.owner.loginStore
      .fetchWithUser(
        `${config.notificationUrl}/notifications/${notification.id}/isRead`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(isRead)
        }
      )
      .then(res => res.json())
      .catch(e => {
        notification.setIsRead(!isRead)
        console.log(e)
      })
  }

  delete = (id: number) => {
    const index = this.notifications.findIndex(n => n.id === id)
    if (index !== -1) this.notifications.splice(index, 1)
    return index
  }

  deleteNotification = (notification: Notification) => {
    const index = this.delete(notification.id)
    return this.owner.loginStore
      .fetchWithUser(
        `${config.notificationUrl}/notifications/${notification.id}`,
        {
          method: 'DELETE'
        }
      )
      .then(res => res.json())
      .catch(e => {
        this.notifications.splice(index, 0, notification)
        console.log(e)
      })
  }

  updateFlagDB = useDebouncedCallback(
    (
      notification: Notification,
      prevFlag: Flag | undefined,
      newFlag: Flag | undefined
    ) => {
      return this.owner.loginStore
        .fetchWithUser(
          `${config.notificationUrl}/notifications/${notification.id}/flag`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newFlag)
          }
        )
        .then(res => res.json())
        .catch(e => {
          notification.setFlag(prevFlag)
          console.log(e)
        })
    },
    5000
  )

  updateFlag = (notification: Notification, newFlag: Flag) => {
    const prevFlag = notification.flag
    if (prevFlag === newFlag) notification.setFlag(undefined)
    else notification.setFlag(newFlag)
    this.updateFlagDB(notification, prevFlag, newFlag)
  }

  markAllAsRead = () => {
    const unread = this.notifications.filter(n => !n.isRead)
    if (unread.length) {
      unread.forEach(n => n.setIsRead(true))
      return this.owner.loginStore
        .fetchWithUser(`${config.notificationUrl}/notifications/read`, {
          method: 'POST'
        })
        .then(res => res.json())
        .catch(e => {
          unread.forEach(n => n.setIsRead(false))
          console.log(e)
        })
    }
  }

  showDesktopNotification = (notification: Notification) => {
    const options: NotificationOptions = {
      body: notification.body,
      icon: workspaceIcon,
      badge: workspaceIcon,
      requireInteraction: true,
      tag: notification.id.toString()
    }

    const url =
      notification.boardId && notification.itemId
        ? generatePath(PATH_WORKFLOW.items, {
            boardId: notification.boardId,
            itemId: notification.itemId
          })
        : undefined

    if (window.Notification.permission === 'granted') {
      this.getDesktopNotfication(notification, options, url)
    }

    //request permissions to show notification (in the first time.)
    else if (window.Notification.permission !== 'denied') {
      window.Notification.requestPermission()
        .then(permission => {
          if (permission === 'granted') {
            this.getDesktopNotfication(notification, options, url)
          }
        })
        .catch(error => {
          console.error('Error requesting notification permission:', error)
        })
    }
  }

  getDesktopNotfication = (
    notification: Notification,
    options: NotificationOptions,
    url?: string
  ) => {
    const desktopNotification = new window.Notification(
      notification.subject,
      options
    )

    desktopNotification.onclick = () => {
      this.onDesktopNotificationClick(desktopNotification, url)
    }
  }

  onDesktopNotificationClick = (
    desktopNotification: any,
    notificationUrl?: string
  ) => {
    if (notificationUrl) window.open(notificationUrl, '_blank')
    desktopNotification.close()
  }

  private isUserNotification = (notification: Notification) => {
    const userid = this.owner.loginStore.user?.id

    if (notification.userId === userid) return true

    const item = this.owner.itemStore.assignedItems.find(
      i => i.id === notification.itemId
    )

    return (
      (!notification.roleId &&
        ((!notification.itemId &&
          notification.fileNumber &&
          this.owner.itemStore.assignedItems.some(
            i => i.order?.fileNumber === notification.fileNumber
          )) ||
          item)) ||
      notification.roleId === RoleId.Manager ||
      (notification.roleId &&
        item?.getUserRoles(userid).includes(notification.roleId))
    )
  }

  listener = () => {
    this.owner.signalrStore.on(
      'AddNotification',
      (notification: Notification) => {
        if (
          this.isUserNotification(notification) &&
          !this.notifications.find(n => n.id === notification.id)
        ) {
          this.notifications.unshift(new Notification(notification))
          this.showDesktopNotification(notification)
          handleNewNotification()
          this.audio.play()
        }
      }
    )

    this.owner.signalrStore.on(
      'UpdateIsRead',
      (id: number, isRead: boolean) => {
        const n = this.notifications.find(n => n.id === id)
        if (n) n.setIsRead(isRead)
      }
    )

    this.owner.signalrStore.on('DeleteNotification', (id: number) => {
      this.delete(id)
    })

    this.owner.signalrStore.on('FlagNotification', (id: number, flag: Flag) => {
      const n = this.notifications.find(n => n.id === id)
      if (n) n.setFlag(flag)
    })

    this.owner.signalrStore.on('ReadAll', () => {
      this.notifications.forEach(n => n.setIsRead(true))
    })
    this.owner.signalrStore.on(
      'FixFnNotification',
      (fixNotifications: Notification[]) => {
        fixNotifications.forEach(n => {
          const notification = this.notifications.find(nt => nt.id === n.id)
          if (notification && n.fileNumber) {
            notification.setFileNumber(n.fileNumber, n.subject)
          }
        })
      }
    )
  }
}

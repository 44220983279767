import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config from 'src/config'
import Board from 'src/entities/Board'
import Department from 'src/entities/Department'
import MainDeparment from 'src/entities/MainDepartment'
import {MainStore} from 'src/store/MainStore'

export class DepartmentStore {
  mainDepartments: MainDeparment[] = []

  currentMainDepartment?: MainDeparment

  collapseDepartments: Record<number, boolean> = {}

  isLoading: boolean = false

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      async isFirstLoggedin => {
        if (isFirstLoggedin) this.getData()
      }
    )
  }

  get departments() {
    return this.mainDepartments.reduce(
      (accumulator: Department[], mainDepartment) => {
        return accumulator.concat(mainDepartment.departments)
      },
      []
    )
  }

  setMainDepartments = (value: MainDeparment[]) => {
    this.mainDepartments = value
  }

  setCurrentMainDepartment = (value: MainDeparment | undefined) => {
    if (this.currentMainDepartment?.id !== value?.id) {
      this.currentMainDepartment = value
      this.collapseDepartments = {}
      this.owner.boardStore.setCurrentBoard(undefined)
    }
  }

  setCollapseDepartments = (id: number, isCollapse: boolean) => {
    this.collapseDepartments[id] = isCollapse
  }

  private getData = async () => {
    this.isLoading = true
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/MainDepartments`)
      .then(res => {
        return res.json()
      })
      .then((data: MainDeparment[]) => {
        if (data) {
          runInAction(() => {
            this.mainDepartments = data.map(md => new MainDeparment(md))
            this.owner.boardStore.getBoardsDetails()
            this.getBoards()
            this.isLoading = false
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getBoards = async () => {
    this.departments.forEach(d =>
      this.owner.loginStore
        .fetchWithUser(`${config.apiUrl}/Departments/${d.id}/Boards`)
        .then(res => {
          return res.json()
        })
        .then((data: Board[]) => {
          if (data) {
            d.setAllBoards(data.map(b => new Board(b)))
            this.owner.mocBoardStore.setBoardsItemFileNumbers(
              data.map(b => b.id)
            )
          }
        })
    )
  }
}

import {
  Typography,
  Autocomplete,
  TextField,
  InputAdornment,
  Stack
} from '@mui/material'
import SearchIcon from 'src/images/search-textField.svg?react'
import PopperComponent from 'src/components/custom/PopperComponent'
import Board from 'src/entities/Board'

interface BoardsProps {
  currentBoardId: number
  availableBoards: Board[]
  onSelectedBoard: (boardId: number) => void
  onClose: () => void
}

const Boards = ({
  currentBoardId,
  availableBoards,
  onSelectedBoard,
  onClose
}: BoardsProps) => {
  return (
    <Stack py={1} px={3}>
      <Typography variant="h6">Choose Board</Typography>
      <Autocomplete
        open
        onClose={(_, reason) => {
          if (reason === 'escape') {
            onClose()
          }
        }}
        onChange={(_, newValue) => {
          if (newValue) {
            onSelectedBoard(newValue.id)
          }
        }}
        PopperComponent={PopperComponent}
        ListboxProps={{sx: {p: 0, '& .MuiAutocomplete-option': {px: 0}}}}
        slotProps={{paper: {sx: {boxShadow: 'none', borderRadius: 0}}}}
        options={availableBoards || []}
        getOptionLabel={option =>
          option.id === currentBoardId
            ? option.name + ' (This Board)'
            : option.name
        }
        renderInput={params => (
          <TextField
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            size="small"
            sx={{color: 'text.primary', pt: 1}}
            autoFocus
            placeholder="Search board"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </Stack>
  )
}

export default Boards

import {
  AutocompleteRenderOptionState,
  Checkbox,
  ListItem,
  Typography
} from '@mui/material'
import {ItemFileNumber} from 'src/entities/MocBoard'

const RenderOption = () => {
  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ItemFileNumber,
    {selected}: AutocompleteRenderOptionState
  ) => (
    <ListItem
      {...props}
      sx={{
        height: 35,
        '&[aria-selected="true"]:not(:hover)': {
          backgroundColor: 'transparent !important'
        }
      }}
      onClick={props.onClick}
      key={option.id}
    >
      <Checkbox checked={selected} />
      <Typography variant="body2">{option.fileNumber}</Typography>
    </ListItem>
  )
  renderOption.displayName = 'RenderOption'
  return renderOption
}

export default RenderOption

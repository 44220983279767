import {
  Avatar,
  Button,
  Card,
  CardContent,
  Stack,
  Typography
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {generatePath, useNavigate} from 'react-router-dom'
import Board from 'src/entities/Board'
import {PATH_WORKFLOW} from 'src/routes/paths'
import {paletteColors} from 'src/config'
import {useMainStore} from 'src/context/Main'

const BoardView = observer(({board}: {board: Board}) => {
  const navigate = useNavigate()
  const {isUserByBoard} = useMainStore().boardStore

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={1.5}
          alignItems="center"
        >
          <Avatar
            sx={{
              bgcolor: `${paletteColors[board.id % 6]}.lighter`,
              height: 45,
              width: 45
            }}
            variant="rounded"
          >
            {board.name[0].toUpperCase()}
          </Avatar>
          <Stack>
            <Typography variant="subtitle1">{board.name}</Typography>
            {!isUserByBoard(board.id) && <div>{board.itemsCountFormat}</div>}
          </Stack>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() =>
              navigate(generatePath(PATH_WORKFLOW.boards, {boardId: board.id}))
            }
          >
            Open
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
})

export default BoardView

import {Divider, IconButton, Stack, Typography} from '@mui/material'
import Column, {ColumnType, Direction} from 'src/entities/Column'
import View from 'src/entities/View'
import {observer} from 'mobx-react-lite'
import React, {useEffect, useState} from 'react'
import {Property} from 'src/entities/Order'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import config from 'src/config'
import redirectWebsite from 'src/utils/redirectWebsite'
import GooleMapsIcon from 'src/images/google_maps.svg?react'
import {useMainStore} from 'src/context/Main'

export interface PropertyViewProps {
  view: View
  properties: Property[]
}

const render = (column: Column, value: any) => {
  switch (column.type) {
    case ColumnType.Text:
    case ColumnType.BoldText:
      return (
        <>
          <Stack useFlexGap sx={{flexWrap: 'wrap'}}>
            <Typography sx={{fontSize: 10, fontWeight: 700, color: '#919EAB'}}>
              {column.name.toUpperCase()}
            </Typography>
            <Typography sx={{fontSize: 12, fontWeight: 400}}>
              {value || '----'}
            </Typography>
          </Stack>
        </>
      )
  }
}

const MenuPropertiesView = observer(({view, properties}: PropertyViewProps) => {
  const [propertyIndex, setPropertyIndex] = useState(0)
  const {currentOverItem} = useMainStore().itemStore

  useEffect(() => {
    setPropertyIndex(0)
  }, [properties])

  const currentProperty = properties[propertyIndex]
  const addressParts =
    currentProperty !== undefined &&
    currentProperty.address &&
    currentProperty.city &&
    currentProperty.state
      ? [
          currentProperty.address,
          currentProperty.city,
          currentProperty.state
        ].filter(Boolean)
      : undefined

  return (
    <Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        display={'flex'}
      >
        {properties.length > 1 && (
          <IconButton
            sx={{width: 12}}
            disabled={
              propertyIndex === 0 || properties[propertyIndex - 1] === undefined
            }
            onClick={() => setPropertyIndex(propertyIndex - 1)}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        {currentProperty &&
          view?.columns
            ?.filter(c => c.direction === Direction.Up)
            .map(c => (
              <React.Fragment key={c.id}>
                {render(c, currentProperty[c.dbColumn?.name as keyof Property])}
              </React.Fragment>
            ))}
        {properties.length > 1 && (
          <IconButton
            sx={{width: 12}}
            disabled={properties[propertyIndex + 1] === undefined}
            onClick={() => setPropertyIndex(propertyIndex + 1)}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Divider />
      <Stack direction="row" sx={{justifyContent: 'space-between'}}>
        <Stack m={1} spacing={1} direction="column" alignItems="right">
          {currentProperty &&
            view?.columns
              ?.filter(c => c.direction === Direction.Down)
              .map(c => (
                <React.Fragment key={c.id}>
                  {render(
                    c,
                    currentProperty[c.name.toLowerCase() as keyof Property]
                  )}
                </React.Fragment>
              ))}
        </Stack>
        {!currentOverItem && addressParts !== undefined && (
          <Stack justifyContent="end">
            <IconButton
              onClick={() => {
                redirectWebsite(
                  `${config.googleMapsLink}${addressParts.join(', ')}`
                )
              }}
            >
              <GooleMapsIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
})

export default MenuPropertiesView

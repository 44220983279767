import {ButtonProps, Box, Button, CircularProgress} from '@mui/material'

type CuiProgressButtonProps = ButtonProps & {
  loading?: boolean
  isFullWidth?: boolean
}

export default function CuiProgressButton({
  loading,
  isFullWidth,
  ...props
}: CuiProgressButtonProps) {
  return (
    <Box
      sx={{position: 'relative'}}
      m={isFullWidth ? 1 : 'inherit'}
      width={isFullWidth ? '100%' : 'inherit'}
    >
      <Button
        fullWidth={isFullWidth}
        disableElevation
        disabled={loading}
        {...props}
      />
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: props.color === 'error' ? 'error.main' : 'primary.main',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -1.5,
            marginLeft: -1.5
          }}
        />
      )}
    </Box>
  )
}
